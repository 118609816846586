import { createSelector } from "reselect";
import { formatValue, sliceVal, ymdhms, getNsName } from "../lib";
import i18n from "../i18n";

export const makeAlertListSelector = alertSelector =>
  createSelector(
    alertSelector,
    state => {
      const records = state.result || [];
      const result = records.map(r => ({
        ...r,
        alertId: formatValue(r, "id", sliceVal),
        department: formatValue(r, "ns", getNsName),
        startedAt: formatValue(r, "startedAt", ymdhms),
        lastAt: formatValue(r, "lastAt", ymdhms),
        actionAt: formatValue(r, "actionAt", ymdhms),
        actionBy_name: formatValue(r, "actionBy.name"),
      }));
      return { ...state, result };
    }
  );

export const makeAlertListDetailSelector = alertSelector =>
  createSelector(
    alertSelector,
    state => {
      const records = state.result || [];
      const result = records.map(r => ({
        ...r,
        startedAt: formatValue(r, "startedAt", ymdhms),
        lastAt: formatValue(r, "lastAt", ymdhms),
        state: formatValue(r, "state", i18n.AlertState),
      }));
      return { ...state, result };
    }
  );

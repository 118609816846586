import Core from "@36node/bus-core-sdk";
import Op from "@36node/bus-op-sdk";
import Chart from "@36node/bus-chart-sdk";
import Log from "@36node/bus-log-sdk";
import Auth from "@36node/auth-sdk";
import Pile from "@36node/bus-pile-sdk";
import Weather from "@36node/weather-sdk";
import {
  AUTH_BASE,
  OP_BASE,
  CORE_BASE,
  LOG_BASE,
  CHART_BASE,
  PILE_BASE,
  WEATHER_BASE,
} from "../config";

// TODO: 以后应该从 redux-session 中引入一个方法  getToken，避免外部需要知道 sessionStorage 的细节
const token = () => sessionStorage.getItem("token");

export const core = new Core({
  base: CORE_BASE,
  token,
});
export const op = new Op({
  base: OP_BASE,
  token,
});
export const chart = new Chart({
  base: CHART_BASE,
  token,
});

export const auth = new Auth({
  base: AUTH_BASE,
  token,
});

export const log = new Log({
  base: LOG_BASE,
  token,
});

export const pile = new Pile({
  base: PILE_BASE,
  token,
});

export const weather = new Weather({
  base: WEATHER_BASE,
  token,
});

import React, { PureComponent } from "react";
import { createSelector } from "reselect";
import { get, values } from "lodash";
import { Table, Form, Card, Col, Row, Button } from "antd";
import { put } from "redux-saga/effects";
import moment from "moment";
import styled from "styled-components";
import { connect } from "react-redux";

import { withSaga, tapOnLatest, makeApiSelector } from "@36node/redux";
import { pile, core } from "../../../actions/api";
import { withTable } from "../../../components/withTable";
import { PILE_LIST_CHARGING_ORDERS } from "../../../actions/types";
import { PileTypeI18N } from "../../../constants";
import { ymdhms, formatValue, getNsName, withKwh } from "../../../lib";
import { createForm } from "@36node/redux-antd";
import {
  Vehicle,
  Department,
  DateRange,
  StationSelect,
  PileId,
} from "../../../components/fields";
import { makeNsTreeSelector, makeLinesByNsSelector } from "../../../selectors";

/**
 * constants
 */
const pageKey = "chargingOrders.all";

const defaultStartAt_gt = moment()
  .subtract(1, "week")
  .startOf("day");
const defaultStartAt_lt = moment();

const columns = [
  {
    title: "订单编号",
    dataIndex: "id",
    key: "id",
    hidden: true,
  },
  {
    title: "VIN码",
    dataIndex: "vehicle.id",
    key: "vehicle",
    hidden: true,
  },
  {
    title: "自编号",
    dataIndex: "vehicleNo",
    key: "vehicleNo",
  },
  {
    title: "部门",
    dataIndex: "department",
    key: "department",
    hidden: true,
  },
  {
    title: "线路",
    dataIndex: "vehicleLine.name",
    key: "line",
    hidden: true,
  },
  {
    title: "充电站",
    dataIndex: "station.name",
    key: "station",
  },
  {
    title: "充电桩编号",
    dataIndex: "pile.id",
    key: "pile.id",
  },
  {
    title: "充电桩型号",
    dataIndex: "pile.equipmentModel",
    key: "pile.equipmentModel",
  },
  {
    title: "充电桩类型",
    dataIndex: "pile.type",
    key: "type",
  },
  {
    title: "充电桩厂家",
    dataIndex: "pile.manufacturerName",
    key: "manufacturerName",
  },
  {
    title: "充电开始时间",
    dataIndex: "startAt",
    key: "startAt",
    sorter: true,
  },
  {
    title: "充电结束时间",
    dataIndex: "endAt",
    key: "endAt",
    sorter: true,
    defaultSortOrder: "descend",
  },
  {
    title: "充电时长",
    dataIndex: "duration",
    key: "duration",
    sorter: true,
  },
  {
    title: "充电电量",
    dataIndex: "power",
    key: "power",
    sorter: true,
  },
];

/**
 * actions
 */

const listChargingOrders = pile.chargingOrder.makeListChargingOrder(pageKey, {
  query: {
    populate: ["pile", "station"],
    sort: "-endAt",
    filter: {
      startAt: {
        $gte: defaultStartAt_gt.toISOString(),
        $lte: defaultStartAt_lt.toISOString(),
      },
    },
  },
});
const listVehicles = core.vehicle.makeListVehicles("chargingOrders.vehicles");
const listStations = pile.station.makeListStation("chargingOrders.stations");

/**
 * selectors
 */
const makeListChargingOrders = selector =>
  createSelector(
    selector,
    listState => {
      const result = (listState.result || []).map(r => {
        const pile = r.pile || {};
        const vehicle = r.vehicle || {};
        const { startAt, endAt } = r;

        return {
          ...r,
          startAt: ymdhms(r.startAt),
          endAt: ymdhms(r.endAt),
          power: withKwh(r.power),
          department: formatValue(vehicle, "ns", getNsName),
          pile: {
            ...pile,
            type: PileTypeI18N[pile.type],
          },
          duration: moment.utc(moment(endAt).diff(startAt)).format("HH:mm:ss"),
        };
      });

      return {
        ...listState,
        result,
      };
    }
  );

const listNsSelector = makeApiSelector("namespaces.all");
const nsTreeSelector = makeNsTreeSelector(listNsSelector);
const listLinesSelector = makeApiSelector("lines");
const lineByNSSelector = makeLinesByNsSelector(listLinesSelector, (_, props) =>
  get(props, "formData.fields.ns.value")
);
const stationsSelector = makeApiSelector("chargingOrders.stations");

/**
 * styles
 */

const StyledFilterForm = styled(Form)`
  padding-right: 12px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

const formItemLayout = {
  labelCol: { xxl: 4, xl: 5, lg: 6, md: 8, sm: 10 },
};

/**
 * components
 */

@createForm(pageKey)
@connect((state, props) => ({
  nsTree: nsTreeSelector(state).result || [],
  lines: lineByNSSelector(state, props),
  stations: stationsSelector(state).result || [],
}))
class SearchForm extends PureComponent {
  handleSubmit = e => {
    e && e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const filter = { ...values };

        if (filter.startAt) {
          filter.startAt = {
            $gte: filter.startAt[0].toISOString(),
            $lte: filter.startAt[1].toISOString(),
          };
        }

        if (this.props.onFetch) {
          this.props.onFetch({ query: { filter } });
        }
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.handleSubmit();
  };

  render() {
    const { form, nsTree, lines, stations } = this.props;

    return (
      <Card style={{ marginBottom: 8 }}>
        <StyledFilterForm {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col span={8}>
              <Vehicle.Vin form={form} name="vehicle" />
            </Col>
            <Col span={8}>
              <Department name="ns" dataSource={nsTree} form={form} />
            </Col>

            <Col span={8}>
              <DateRange
                name="startAt"
                label="充电开始时间"
                form={form}
                placeholder={["开始时间", "结束时间"]}
                showTime={true}
                allowClear={false}
                initialValue={[defaultStartAt_gt, defaultStartAt_lt]}
              />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Vehicle.No form={form} name="vehicleNo" />
            </Col>

            <Col span={8}>
              <Vehicle.Line name="vehicleLine" dataSource={lines} form={form} />
            </Col>

            <Col span={8}>
              <StationSelect name="station" dataSource={stations} form={form} />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <PileId name="pile" form={form} />
            </Col>

            <Col span={8} offset={8} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                筛选
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                重置
              </Button>
            </Col>
          </Row>
        </StyledFilterForm>
      </Card>
    );
  }
}

@withSaga(
  tapOnLatest(PILE_LIST_CHARGING_ORDERS.SUCCESS, pageKey, function*(action) {
    const orders = values(get(action, "payload.entities.chargingOrders", {}));

    const vehicleIds = orders.map(o => o.vehicle);

    yield put(listVehicles({ query: { filter: { id: vehicleIds } } }));
    yield put(listStations({ query: { limit: 10000 } }));
  })
)
@withTable(pageKey, {
  SearchForm,
  title: "充电历史记录列表",
  columns,
  list: listChargingOrders,
  makeListSelector: makeListChargingOrders,
})
export default class History extends PureComponent {
  render() {
    return <Table {...this.props} />;
  }
}

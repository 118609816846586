/**
 * 用户新建或编辑modal
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Form, Row, Col, Button, Icon, Modal as AntdModal } from "antd";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { createForm } from "@36node/redux-antd";

import { core } from "../../../actions/api";
import Modal from "../../../components/modal";
import { Vehicle, Department, TimeSelect } from "../../../components/fields";
import {
  listLinesSelector,
  listProducersSelector,
  nsTreeSelector,
} from "../../layouts/main";
import { makeLinesByNsSelector } from "../../../selectors";
import { ymd } from "../../../lib";

const { confirm } = AntdModal;

const createVechile = core.vehicle.makeCreateVehicle("vehicle.create");
const updateVechile = core.vehicle.makeUpdateVehicle("vehicle.update");
const deleteVehicle = core.vehicle.makeDeleteVehicle("vehicle.delete");

const lineByNSSelector = makeLinesByNsSelector(listLinesSelector, (_, props) =>
  !isEmpty(get(props, "formData.fields.ns.value"))
    ? get(props, "formData.fields.ns.value")
    : get(props, "location.state.record.ns.id")
);

export function ArchiveAction({ update = false, vin = "", record = {} } = {}) {
  const location = useLocation();

  return update ? (
    <Link
      to={{
        pathname: `/g/archive/${vin}/update`,
        state: { background: location, record },
      }}
    >
      <Button type="primary">编辑</Button>
    </Link>
  ) : (
    <Link
      to={{
        pathname: `/g/archive/add`,
        state: { background: location },
      }}
    >
      <Icon type="plus" style={{ color: "#595959", marginRight: "5px" }}></Icon>
    </Link>
  );
}

@connect()
export class ArchiveDelete extends Component {
  state = {
    show: false,
  };

  handleDelete = () => {
    const { id } = this.props;

    this.props.dispatch(
      deleteVehicle({
        vehicleId: id,
      })
    );
  };

  showDeleteConfirm = () => {
    const { id } = this.props;
    confirm({
      title: "删除车辆档案",
      content: `确定要删除车辆 ${id} ？`,
      okText: "确定",
      okType: "danger",
      cancelText: "取消",
      onOk: () => {
        this.handleDelete();
      },
    });
  };

  render() {
    return (
      <Button
        style={{ marginLeft: 8 }}
        type="danger"
        onClick={this.showDeleteConfirm}
      >
        删除
      </Button>
    );
  }
}

@createForm("vehicle.create")
@connect((state, props) => ({
  nsTree: nsTreeSelector(state).result || [],
  lines: lineByNSSelector(state, props),
  producers: listProducersSelector(state).result || [],
  vin: get(props, "match.params.vin") || "",
  record: get(props, "location.state.record", {}),
}))
export default class ArchiveEditor extends Component {
  state = {
    update: false,
    loading: false,
  };

  componentDidMount() {
    const { vin } = this.props;
    vin &&
      this.setState({
        update: true,
      });
  }

  close = delay => {
    const { location, history } = this.props;
    const background = location.state && location.state.background;

    if (!background) return history.goBack();
    history.push(background);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    const { update } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (update) {
          this.props.dispatch(
            updateVechile({
              vehicleId: values.vin,
              body: {
                ...values,
                platedAt: ymd(values.platedAt),
                labels: values.labels.split(","),
              },
            })
          );
        } else {
          this.props.dispatch(
            createVechile({
              body: {
                ...values,
                id: values.vin,
                platedAt: ymd(values.platedAt),
                labels: values.labels.split(","),
              },
            })
          );
        }
        this.setState({
          loading: true,
        });
        setTimeout(() => {
          this.close();
        }, 2000);
      }
    });
  };

  handleCancel = () => {
    const { form } = this.props;
    form.resetFields();
    this.close();
  };

  render() {
    const { form, nsTree, lines, producers, record } = this.props;
    const { update, loading } = this.state;

    return (
      <Modal
        title={update ? "编辑" : "新建"}
        visible={true}
        onCancel={this.handleCancel}
        width={800}
      >
        <StyledFilterForm {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col span={12}>
              <Vehicle.Vin
                label="Vin码"
                form={form}
                initialValue={update ? record.id : ""}
                disabled={update ? true : false}
                rules={[{ required: true, message: "请输入车辆Vin码" }]}
              />
            </Col>
            <Col span={12}>
              <Vehicle.No
                form={form}
                initialValue={update ? record.no : ""}
                rules={[{ required: true, message: "请输入车辆自编号" }]}
              />
            </Col>
            <Col span={12}>
              <Vehicle.Plate
                form={form}
                initialValue={update ? record.plate : ""}
                rules={[{ required: true, message: "请输入车辆车牌号" }]}
              />
            </Col>
            <Col span={12}>
              <Department
                dataSource={nsTree}
                form={form}
                initialValue={get(record, "ns.id", undefined)}
                rules={[{ required: true, message: "请输入车辆部门" }]}
              />
            </Col>
            <Col span={12}>
              <Vehicle.Line
                dataSource={lines}
                form={form}
                initialValue={get(record, "line.name", undefined)}
                valueIsName={true}
                rules={[{ required: true, message: "请输入车辆线路" }]}
              />
            </Col>
            <Col span={12}>
              <Vehicle.Producer
                dataSource={producers}
                form={form}
                initialValue={update ? record.producer : undefined}
                rules={[{ required: true, message: "请输入生产厂商" }]}
              />
            </Col>
            <Col span={12}>
              <Vehicle.Model
                form={form}
                initialValue={update ? record.model : ""}
                rules={[{ required: true, message: "请输入车辆车型" }]}
              />
            </Col>
            <Col span={12}>
              <Vehicle.ModelBrief
                form={form}
                initialValue={update ? record.modelBrief : ""}
                rules={[{ required: true, message: "请输入车型简称" }]}
              />
            </Col>
            <Col span={12}>
              <Vehicle.LifeYear
                form={form}
                initialValue={update ? record.lifeYear : ""}
                rules={[{ required: true, message: "请输入车辆使用年限" }]}
              />
            </Col>
            <Col span={12}>
              <TimeSelect
                name="platedAt"
                label="上牌日期"
                form={form}
                initialValue={
                  update && record.platedAt ? moment(record.platedAt) : null
                }
                placeholder="请输入上牌日期"
                rules={[{ required: true, message: "请输入上牌日期" }]}
              />
            </Col>
            <Col span={12}>
              <Vehicle.Label
                form={form}
                initialValue={
                  update ? (record.labels === "--" ? "" : record.labels) : ""
                }
                placeholder="请输入车辆标签"
              />
            </Col>
            <Col span={12}>
              <Vehicle.RepairWorkshop
                form={form}
                initialValue={
                  update
                    ? record.repairWorkshop === "--"
                      ? ""
                      : record.repairWorkshop
                    : ""
                }
                placeholder="请输入车间"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                {update ? "更新" : "新建"}
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleCancel}>
                取消
              </Button>
            </Col>
          </Row>
        </StyledFilterForm>
      </Modal>
    );
  }
}

const formItemLayout = {
  labelCol: { xxl: 7, xl: 8, lg: 9, md: 10, sm: 11 },
};
const StyledFilterForm = styled(Form)`
  margin-top: 12px !important;
  padding: 0 30px 12px 30px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .pack-filter {
    display: flex;
    justify-content: center;

    .ant-btn {
      border: 0;
    }
  }
`;

/**
 * 公司数据权限，为配置的api 自动添加 ns filter
 */

import { cloneDeep, set, startsWith } from "lodash";
import { makeApiSelector } from "@36node/redux";

import * as types from "../../actions/types";
import { UserRoles } from "../../constants";
import { ROOT_NS } from "../../config";

const selectSession = makeApiSelector("session");

const NS_FILTER_API_TYPES = [
  types.CORE_VEHICLE_LIST_VEHICLES,
  types.CORE_VEHICLE_GET_SOH_STATISTICS,
  types.AUTH_NS_LIST_NAMESPACES,
  types.CHART_WARNING_LIST_WARNINGS,
  types.CORE_VEHICLE_GET_STATISTICS,
  types.CHART_POWER_GET_STATISTICS,
  types.CHART_DRIVER_POWER_STATS,
  types.LIST_DRIVER_POWERS,
  types.LIST_DRIVER_POWERS_AGGS,
  types.CHART_POWER_LIST_POWERS,
  types.CHART_EXCEPTION_LIST_STATISTICS,
  types.OP_EXCEPTION_LIST_EXCEPTIONS,
  types.CORE_LINE_LIST_LINES,
  types.AUTH_USER_LIST_USERS,
  types.CORE_PRODUCER_LIST_PRODUCERS,
  types.OP_ALERT_LIST_ALERTS,
  types.CORE_PARK_LIST_PARKS,
  types.CHART_ANALYSIS_LIST_DRILLDOWN,
  types.CHART_ANALYSIS_LIST_OVERALL,
  types.CHART_ANALYSIS_LIST_ALERTRATE,
  types.OP_TICKET_LIST_TICKETS,
  types.CHART_ALERT_HISTOGRAM,
  types.CORE_LIST_OPTIONAL_VEHICLES,
  types.PILE_LIST_CHARGING_ORDERS,
  types.CORE_BANCI_LIST_BANCIS,
].map(t => t.REQUEST);

const companytNsFilterMiddleware = store => next => action => {
  const { type } = action;

  const state = store.getState();
  const session = selectSession(state).result || {};

  const { roles = [] } = session;

  // 只处理有 COMPANY_MONITOR 权限的账户
  const roleNs = roles
    .filter(
      r => startsWith(r.ns, ROOT_NS) && r.name === UserRoles.COMPANY_MONITOR
    )
    .map(r => r.ns)[0];

  if (!roleNs) {
    return next(action);
  }

  if (NS_FILTER_API_TYPES.includes(type)) {
    const newPayload = cloneDeep(action.payload || {});

    const filterPath =
      type === types.AUTH_NS_LIST_NAMESPACES.REQUEST
        ? "query.filter.id"
        : "query.filter.ns";

    set(newPayload, filterPath, {
      $regex: new RegExp(roleNs, "g"),
    });

    const nextAction = {
      ...action,
      payload: newPayload,
    };

    return next(nextAction);
  }

  next(action);
};

export default companytNsFilterMiddleware;

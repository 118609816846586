/**
 * 充电弓数据
 */
import React, { PureComponent } from "react";
import { Form, Button, Row, Col, Table } from "antd";
import styled from "styled-components";
import { get } from "lodash";
import { createForm } from "@36node/redux-antd";
import { withRouter } from "react-router-dom";

import { withTable } from "../../components/withTable";
import { DateRange } from "../../components/fields";
import { log } from "../../actions/api";
import { RecordCommands } from "../../constants";
import { genVehilcleColumns } from "../../components/vehicle/columns";
import { makeRecordListSelector } from "../../selectors";

const PageKey = "vehicle.chargingBow";

const listRecords = log.vehicle.makeListRecords(PageKey, {
  query: {
    limit: 10,
    offset: 0,
    sort: "lastAt",
    filter: {
      _exist: "chargingBow",
      command: RecordCommands.REISSUE_REPORT,
    },
  },
});

@withRouter
@createForm(PageKey)
class SearchForm extends PureComponent {
  get vehicleId() {
    return get(this.props.match, "params.vehicleId");
  }

  componentDidMount() {
    this.props.onFetch({
      vehicleId: this.vehicleId,
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const filter = { ...values };

        if (filter.startedAt) {
          const { startedAt } = filter;
          filter.at_gt = startedAt[0] && startedAt[0].toISOString();
          filter.at_lt = startedAt[1] && startedAt[1].toISOString();
          delete filter.startedAt;
        }

        this.props.onFetch({
          vehicleId: this.vehicleId,
          query: { filter },
        });
      }
    });
  };

  render() {
    const { form } = this.props;
    return (
      <StyledFilterForm {...formItemLayout} onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={12}>
            <DateRange
              name="startedAt"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              form={form}
            />
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button
                type="primary"
                style={{ marginLeft: 48 }}
                htmlType="submit"
              >
                筛选
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledFilterForm>
    );
  }
}

const columns = genVehilcleColumns("", []).reduce((acc, cur) => {
  if (cur.dataIndex === "at") {
    acc.push(cur);
  }

  if (cur.key === "chargingBow") {
    acc.push(
      ...cur.children.map(c => ({
        ...c,
        dataIndex: c.dataIndex.replace("[9]", ".datas[9]"),
      }))
    );
  }

  return acc;
}, []);

@withTable(PageKey, {
  SearchForm,
  title: "充电弓数据",
  columns: columns, // 第一个参数可以提供车型过滤
  list: listRecords,
  makeListSelector: makeRecordListSelector,
  fetchOnMount: false,
})
class HistoryTable extends PureComponent {
  render() {
    return <Table scroll={{ x: "max-content" }} {...this.props} />;
  }
}

export default class ChargingBow extends PureComponent {
  render() {
    return (
      <Container>
        <HistoryTable />
      </Container>
    );
  }
}

const Container = styled.div`
  height: 100%;
  overflow: scroll;
  background-color: #fff;
`;
const formItemLayout = {
  labelCol: { xxl: 4, xl: 5, lg: 6, md: 8, sm: 10 },
  wrapperCol: { xxl: 20, xl: 19, lg: 18, md: 16, sm: 14 },
};
const StyledFilterForm = styled(Form)`
  margin-top: 12px !important;
  padding: 0px 30px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .pack-filter {
    display: flex;
    justify-content: center;

    .ant-btn {
      border: 0;
    }
  }
`;

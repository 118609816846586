import { createSelector } from "reselect";
import { formatValue, ymd, ymdhms, getNsName, withKm } from "../lib";

export const makeBanciSelector = banciSelector =>
  createSelector(
    banciSelector,
    state => {
      const records = state.result || [];
      const result = records.map(r => ({
        ...r,
        date: formatValue(r, "date", ymd),
        department: formatValue(r, "ns", getNsName),
        arriveAt: formatValue(r, "arriveAt", ymdhms),
        departAt: formatValue(r, "departAt", ymdhms),
        mileage: formatValue(r, "mileage", withKm),
      }));
      return { ...state, result };
    }
  );

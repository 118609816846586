import React from "react";
import { connect } from "react-redux";
import { Form, Spin, Table, Row, Col, Button } from "antd";
import { isEmpty, get, cloneDeep } from "lodash";
import styled from "styled-components";
import { makeApiSelector } from "@36node/redux";
import { createForm } from "@36node/redux-antd";
import { withSession } from "@36node/redux-session";

import { listStagesSelector } from "../layouts/main";
import Modal from "../../components/modal";
import Level from "../../components/level";
import { op } from "../../actions/api";
import { Ticket } from "../../components/fields";
import TicketEventBox from "./ticket-event";
import i18n from "../../i18n";
import {
  AlertState,
  AlertAction,
  TicketAction,
  TicketEvent,
} from "../../constants";
import { getNsName, stageCompare, getStageByName, ymdhm } from "../../lib";

const NO_NEED_TO_DEAL = TicketAction.NO_NEED_TO_DEAL;
const FEEDBACK_FALSE_REPORT = TicketAction.FEEDBACK_FALSE_REPORT;
const OkButtons = {
  ignore: ["忽略报警", "danger"],
  feedback: ["反馈误报", "danger"],
  createTicket: ["创建处置单", "primary"],
  updateTicket: ["提交", "primary"],
  closeTicket: ["关闭处置单", "danger"],
};
/**
 * Actions & Selectors
 */
const getAlert = op.alert.makeGetAlert("ticket.alert");
const updateAlert = op.alert.makeUpdateAlert("ticket.alert");
const createTicket = op.ticket.makeCreateTicket("ticket.create");
const createEvent = op.ticket.makeCreateEvent("ticket.event");
const getTicket = op.ticket.makeGetTicket("ticket.update");
const getAlertSelector = makeApiSelector("ticket.alert");
const getTicketSelector = makeApiSelector("ticket.update");

@withSession("session")
@createForm("ticket.editor")
@connect((state, props) => ({
  alert: get(props, "location.state.alert", {}),
  alertId: get(props, "location.state.alertId", ""),
  userId: get(props, "session.result.user.id"), // TODO: 下个版本 session 返回 userId
  ticketId: get(props, "match.params.ticketId"),
  stages: listStagesSelector(state).result.sort(stageCompare),
  alertInfo: getAlertSelector(state).result,
  ticket: getTicketSelector(state).result,
  loading: getTicketSelector(state).loading,
}))
export default class TicketEditor extends React.Component {
  state = {
    title: "报警处理",
    confirmLoading: false,
    actionRoll: true, // true表示action group展开
    action: "",
  };

  get title() {
    const { ticketId, ticket } = this.props;
    const { stage = {} } = ticket;
    if (!ticketId || isEmpty(stage)) {
      return "报警处置 ";
    }
    return `报警处置 (${get(stage, "name")}) `;
  }

  get subtitle() {
    const { alertId, alert, alertInfo } = this.props;
    const data = alertId ? alertInfo : alert;
    if (isEmpty(data)) {
      return "";
    }
    return `${get(data, "vehicleNo")}|${get(data, "vehiclePlate")}|${get(
      data,
      "vehicle"
    )}  ${getNsName(get(data, "ns"))}|${get(data, "vehicleLine.name")}`;
  }

  get okType() {
    const { ticketId } = this.props;
    const { action } = this.state;
    if (action === NO_NEED_TO_DEAL) return "ignore";
    if (action === FEEDBACK_FALSE_REPORT) return "feedback";
    if (!ticketId) return "createTicket";
    return "updateTicket";
  }

  componentDidMount() {
    const { ticketId, alertId, alert, dispatch } = this.props;
    if (ticketId) {
      dispatch(getTicket({ ticketId }));
      if (alertId) dispatch(getAlert({ alertId }));
      else dispatch(getAlert({ alertId: alert.id }));
      this.setState({ actionRoll: false });
    }
  }

  setActionContent = action => {
    this.props.form.setFieldsValue({
      content: i18n.TicketActionContent[action],
    });
  };

  handleClickAction = () => {
    const { ticket } = this.props;
    const { events = [] } = ticket;
    const action = get(events[events.length - 1], "action", "");
    this.setState({
      actionRoll: true,
      action,
    });
    this.setActionContent(action);
  };

  handleChangeAction = e => {
    if (
      e.target.value === "NO_NEED_TO_DEAL" ||
      e.target.value === "FEEDBACK_FALSE_REPORT"
    ) {
      const stage = getStageByName(this.props.stages, "已完成");
      this.props.form.setFieldsValue({
        stage,
      });
    } else {
      this.props.form.setFieldsValue({
        stage: get(this.props.ticket, "stage.id"),
      });
    }
    this.setState({ action: e.target.value });
    this.setActionContent(e.target.value);
  };

  close = delay => {
    const { location, history } = this.props;
    const background = location.state && location.state.background;

    if (!background) return history.goBack();
    history.push(background);
  };

  cancel = () => {
    this.close();
  };

  handlers = {
    // 忽略报警
    ignore: ({ alert, userId }) => {
      this.props.dispatch(
        updateAlert({
          alertId: alert.id,
          body: {
            state: AlertState.CLOSED,
            action: AlertAction.IGNORE,
            actionBy: userId,
          },
        })
      );
    },

    // 创建处置单
    createTicket: ({ alert, userId, action, content, stage }) => {
      this.props.dispatch(
        createTicket({
          body: {
            events: [
              {
                name: TicketEvent.CREATE,
                alerts: [alert.id],
                createdBy: userId,
                content,
                action: action,
                from: stage,
                to: stage,
              },
            ],
            stage,
            createdBy: userId,
            vehicle: alert.vehicle,
            vehicleNo: alert.vehicleNo,
            vehicleLine: get(alert, "vehicleLine.id"),
            vehicleModel: alert.vehicleModel,
            vehicleModelBrief: alert.vehicleModelBrief,
            vehiclePlate: alert.vehiclePlate,
            vehicleProducer: alert.vehicleProducer,
            vehicleLabels: alert.vehicleLabels,
            alerts: [alert.id],
            ns: get(alert, "ns.id"),
            keywords: [alert.id.slice(-8), alert.vehicle, alert.vehicleNo],
          },
        })
      );
    },

    // 更新处置单
    updateTicket: ({ userId, ticket, action, content, stage }) => {
      if (stage && stage !== get(ticket, "stage.id")) {
        return this.props.dispatch(
          createEvent({
            ticketId: ticket.id,
            body: {
              name: TicketEvent.STAGE,
              createdBy: userId,
              from: get(ticket, "stage.id"),
              to: stage,
              content,
              action,
            },
          })
        );
      }

      this.props.dispatch(
        createEvent({
          ticketId: ticket.id,
          body: {
            name: TicketEvent.COMMENT,
            content,
            createdBy: userId,
            action,
          },
        })
      );
    },

    // 关闭处置单
    closeTicket: ({ stages, ...rest }) => {
      const stage = getStageByName(stages, "已完成");
      this.handlers.updateTicket({ ...rest, stage });
    },

    // 关闭处置单
    feedback: ({ stages, ...rest }) => {
      const stage = getStageByName(stages, "已完成");
      this.handlers.updateTicket({ ...rest, stage });
    },
  };

  ok = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let data = cloneDeep(values);
        if (!data.action) {
          const { ticket } = this.props;
          const { events = [] } = ticket;
          data.action = get(events[events.length - 1], "action", "");
        }

        this.handlers[this.okType]({ ...this.props, ...data });
        this.setState({
          title: "报警处理中...",
          confirmLoading: true,
        });

        // hardcode 说明：由于处置单和报警服务没有同步，只能在前端强行等2s钟
        setTimeout(() => {
          this.setState({
            title: "报警处理成功",
            confirmLoading: false,
          });
          this.close();
        }, 2000);
      }
    });
  };

  renderAlertInfo = () => {
    const { alert, alertId, alertInfo } = this.props;
    const data = alertId ? alertInfo : alert;
    return (
      <Container>
        <div className="title">报警信息</div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={!isEmpty(data) ? [data] : []}
          pagination={false}
        />
      </Container>
    );
  };

  renderEventBox = () => {
    const { ticket } = this.props;
    const { events = [] } = ticket;
    return (
      <Container>
        <div className="title">报警处理历史记录</div>
        {events.map((evt, index) => {
          if (index === events.length - 1)
            return <TicketEventBox border={0} {...evt} key={evt.id} />;
          else return <TicketEventBox border={1} {...evt} key={evt.id} />;
        })}
      </Container>
    );
  };

  renderForm = () => {
    const { ticketId, ticket, stages, form } = this.props;
    const { events = [] } = ticket;
    const { title, actionRoll, action, confirmLoading } = this.state;
    const actionRules = [{ required: true, message: "请输入处置方式!" }];
    const contentRules = [{ required: true, message: "请输入处理意见!" }];
    const stageRules = [{ required: true, message: "请输入处置状态!" }];
    const [okText, okButtonType] = OkButtons[this.okType];
    const actionValue = get(events[events.length - 1], "action", "");

    return (
      <Container style={{ borderTop: ticketId ? "1px solid #ebedf0" : "" }}>
        <div className="title">{title}</div>
        <StyledFilterForm {...formItemLayout} onSubmit={this.ok}>
          {ticketId && !actionRoll ? (
            <Form.Item label="处置方式">
              <span>{i18n.TicketAction[actionValue]}</span>
              <Button type="link" onClick={this.handleClickAction}>
                修改
              </Button>
            </Form.Item>
          ) : (
            <Ticket.Action
              rules={actionRules}
              initialValue={ticketId ? action : ""}
              onChange={this.handleChangeAction}
              select={action}
              form={form}
            />
          )}
          <Ticket.Content rules={contentRules} form={form} />
          <Ticket.Stage
            rules={stageRules}
            dataSource={stages}
            initialValue={
              ticketId
                ? get(ticket, "stage.id") || getStageByName(stages, "待维修")
                : ""
            }
            style={{ width: 300 }}
            form={form}
          />
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button onClick={this.cancel}>取消</Button>
              <Button
                loading={confirmLoading}
                style={{ marginLeft: 8 }}
                type={okButtonType}
                htmlType="submit"
              >
                {okText}
              </Button>
            </Col>
          </Row>
        </StyledFilterForm>
      </Container>
    );
  };

  render() {
    const { ticketId, ticket, stages, loading } = this.props;

    return (
      <Modal
        title={
          <StyledTitle>
            {this.title}
            <small>{this.subtitle}</small>
          </StyledTitle>
        }
        visible={true}
        onCancel={this.cancel}
        confirmLoading={this.state.confirmLoading}
        width={800}
      >
        <Spin spinning={loading}>
          {this.renderAlertInfo()}
          {ticketId && this.renderEventBox()}
          {(!ticketId ||
            get(ticket, "stage.id") !== getStageByName(stages, "已完成")) &&
            this.renderForm()}
        </Spin>
      </Modal>
    );
  }
}

const StyledTitle = styled.h3`
  color: rgba(255, 255, 255, 1);
  margin: auto;
  small {
    line-height: 17px;
    font-size: 12px;
    pre {
      margin: 1em 0px;
      overflow: auto;
    }
  }
`;

const StyledFilterForm = styled(Form)`
  padding: 0 24px 0 24px !important;

  .ant-form-item {
    display: flex;
    margin-bottom: 12px;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

const Container = styled.div`
  padding: 12px;
  .title {
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 8px;
  }
`;

const formItemLayout = {
  labelCol: { xxl: 4, xl: 5, lg: 6, md: 8, sm: 10 },
};

const columns = [
  {
    title: "报警编号",
    dataIndex: "id",
    key: "id",
    render: val => val.slice(-8),
  },
  {
    title: "报警等级",
    dataIndex: "level",
    key: "level",
    render: val => <Level value={val} />,
  },
  {
    title: "报警码",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "报警名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "开始时间",
    dataIndex: "startedAt",
    key: "startedAt",
    render: ymdhm,
  },
  {
    title: "次数",
    dataIndex: "count",
    key: "count",
  },
  {
    title: "最近更新时间",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: ymdhm,
  },
];

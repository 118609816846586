import { Modal } from "antd";
import styled from "styled-components";

export default styled(Modal).attrs({ footer: null })`
  .ant-modal-header {
    border-radius: 3px 3px 0px 0px;
    background-color: rgba(45, 60, 80, 1);
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-title {
    color: #fff;
  }
  .ant-modal-close {
    color: #fff;
  }
`;

/**
 * redux 中间件 根据label 过滤api
 */

import { cloneDeep, set, startsWith, isEmpty, get } from "lodash";
import {
  CORE_VEHICLE_LIST_VEHICLES,
  OP_ALERT_LIST_ALERTS,
  OP_TICKET_LIST_TICKETS,
  OP_EXCEPTION_LIST_EXCEPTIONS,
  CORE_VEHICLE_GET_STATISTICS,
} from "../../actions/types";
import { UserRoles } from "../../constants";
import { ROOT_NS, PRODUCERS_NAMESPACES } from "../../config";
import { makeApiSelector } from "@36node/redux";

const FILTER_PROEUCER_API = {
  [CORE_VEHICLE_LIST_VEHICLES.REQUEST]: "producer",
  [CORE_VEHICLE_GET_STATISTICS.REQUEST]: "producer",
  [OP_ALERT_LIST_ALERTS.REQUEST]: "vehicleProducer",
  [OP_TICKET_LIST_TICKETS.REQUEST]: "vehicleProducer",
  [OP_EXCEPTION_LIST_EXCEPTIONS.REQUEST]: "vehicleProducer",
};

const FilterTypes = Object.keys(FILTER_PROEUCER_API);

const selectSession = makeApiSelector("session");

/**
 * 根据厂商的userns 判断厂商的名称，并在api action 中注入 producer的query条件
 * 1. 从redux 拿到session，并找出 userNs 和 roles中的ns
 * 2. 如果 是 PRODUCER_MONITOR 的 role 则进行过滤, 否则直接返回
 * 3. 根据环境变量 PRODUCERS_NAMESPACES 中配置的 userNs 和 producerName的对应关系，找到当前userNs对应的producer name
 * 4. 在api action中注入query 条件
 */
const producerFilterMiddleware = store => next => action => {
  const { type } = action;

  const state = store.getState();

  const session = selectSession(state).result || {};

  if (FilterTypes.includes(type)) {
    const { roles = [] } = session;

    const userNs = get(session, "user.ns");

    if (isEmpty(userNs)) {
      return next(action);
    }

    const ns = roles.filter(
      r => startsWith(r.ns, ROOT_NS) && r.name === UserRoles.PRODUCER_MONITOR
    );

    if (isEmpty(ns)) {
      return next(action);
    }

    const found = PRODUCERS_NAMESPACES.find(n => n.ns === userNs[0]);

    if (!found) {
      return next(action);
    }

    const newPayload = cloneDeep(action.payload || {});
    set(newPayload, ["query", "filter", FILTER_PROEUCER_API[type]], found.name);

    const nextAction = {
      ...action,
      payload: newPayload,
    };

    return next(nextAction);
  }

  next(action);
};

export default producerFilterMiddleware;

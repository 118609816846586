import { get, isNil } from "lodash";
import { createSelector } from "reselect";
import i18n from "../i18n";
import {
  formatValue,
  sliceVal,
  getNsName,
  getNsPath,
  withOhm,
  withKpa,
  withCentigrade,
  withPercent,
  ymdhms,
  ymd,
  withAirMode,
  withSecond,
  withKmh,
  withVoltage,
} from "../lib";

const totalType = {
  BATTERY: {
    max: "最高温度",
    min: "最低温度",
    avg: "平均温度",
  },
  INSULATION: {
    max: "最高阻抗",
    min: "最低阻抗",
    avg: "平均阻抗",
  },
  TYRE: {
    max: "最高",
    min: "最低",
    avg: "平均",
  },
};

const getExceptionAnalysisTotal = (totalData, type) => {
  let data = {
    date: [],
    value: [],
  };
  let countData = {
    max: [],
    min: [],
    avg: [],
  };

  totalData.forEach(d => {
    data.date.push(d.name);
    countData.max.push(d.value.max);
    countData.min.push(d.value.min);
    countData.avg.push(Number(d.value.avg).toFixed(0));
  });

  let typeData = totalType[type];
  Object.keys(typeData).forEach(v =>
    data.value.push({ name: typeData[v], value: countData[v] })
  );
  return data;
};

const getExceptionAnalysisByModel = (modelData, type) => {
  let data = {
    model: [],
  };
  let typeData = [[], []];

  let typeKey = null,
    selectKey = null;
  if (type === "BATTERY") {
    typeKey = i18n.BatteryExceptionType;
    selectKey = ["TEMP_BETWEEN_45_55", "TEMP_OVER_55"];
  } else if (type === "INSULATION") {
    typeKey = i18n.InsulationExceptionType;
    selectKey = ["RESISTANCE_BELOW_500", "RESISTANCE_BETWEEN_500_100000"];
  } else if (type === "TYRE") {
    typeKey = i18n.TyreExceptionType;
    selectKey = ["PRESSURE_BELOW_900", "PRESSURE_OVER_1100"];
  }

  modelData.forEach(d => {
    data.model.push(d.name);
    selectKey.forEach((val, index) => {
      let tmp = d.value.find(a => a.key === val) || null;
      if (tmp) typeData[index].push(tmp.doc_count);
      else typeData[index].push(0);
    });
  });

  data.value = [
    { name: typeKey[selectKey[0]], value: typeData[0] },
    { name: typeKey[selectKey[1]], value: typeData[1] },
  ];

  return data;
};

const getExceptionAnalysisByNS = (nsData, namespaces, type, first = true) => {
  let typeKey = null,
    selectKey = null;
  if (type === "BATTERY") {
    typeKey = i18n.BatteryExceptionType;
    if (first) selectKey = ["TEMP_BETWEEN_45_55", "TEMP_OVER_55"];
    else selectKey = ["SOC_BELOW_20_PERCENT", "SOC_BETWEEN_20_30_PERCENT"];
  } else if (type === "INSULATION") {
    typeKey = i18n.InsulationExceptionType;
    if (first)
      selectKey = ["RESISTANCE_BELOW_500", "RESISTANCE_BETWEEN_500_100000"];
    else selectKey = ["INSULATE_NORMAL"];
  } else if (type === "TYRE") {
    typeKey = i18n.TyreExceptionType;
    if (first) selectKey = ["PRESSURE_BELOW_900", "PRESSURE_OVER_1100"];
    else selectKey = ["TEMP_OVER_40"];
  }

  let data = [];
  namespaces.forEach(ns => {
    data.push({
      name: ns.title,
      key: ns.key,
      value: selectKey.map(v => 0),
    });
  });

  nsData.forEach(d => {
    if (data.find(v => d.name.startsWith(v.key))) {
      let ns = data.find(v => d.name.startsWith(v.key));
      selectKey.forEach((val, index) => {
        let tmp = d.value.find(a => a.key === val) || null;
        if (tmp) ns.value[index] += tmp.doc_count;
      });
    }
  });

  let result = {
    ns: [],
    value: selectKey.map(key => ({
      name: typeKey[key],
      value: [],
    })),
  };
  data.forEach(d => {
    result.ns.push(d.name);
    d.value.forEach((v, index) => {
      result.value[index].value.push(v);
    });
  });
  return result;
};

export const makeExceptionAnalysisSelector = (selector, nsTreeSelector, type) =>
  createSelector(
    selector,
    nsTreeSelector,
    (state, nsState) => {
      const result = state.result || [];
      const nsResult = nsState.result || [];
      const exceptionStatistics = result.find(
        a => a.name === "exceptionStatistics"
      ) || {
        value: [],
      };
      const exceptionStatisticsByModel = result.find(
        a => a.name === "exceptionStatisticsByModel"
      ) || {
        value: [],
      };
      const exceptionStatisticsByNS = result.find(
        a => a.name === "exceptionStatisticsByNS"
      ) || {
        value: [],
      };
      return {
        total: getExceptionAnalysisTotal(exceptionStatistics.value, type),
        modelData: getExceptionAnalysisByModel(
          exceptionStatisticsByModel.value,
          type
        ),
        nsData: getExceptionAnalysisByNS(
          exceptionStatisticsByNS.value,
          nsResult,
          type
        ),
        otherNSData: getExceptionAnalysisByNS(
          exceptionStatisticsByNS.value,
          nsResult,
          type,
          false
        ),
      };
    }
  );

function formatMinVoltageBattery(r) {
  const sysNo = get(r, "data.minVoltageSubSysNo");
  const singNo = get(r, "data.minVoltageSingNo");

  if (isNil(sysNo) || isNil(singNo)) {
    return "--";
  }

  return `${sysNo}-${singNo}`;
}

export const makeExceptionListSelector = exceptionSelector =>
  createSelector(
    exceptionSelector,
    state => {
      const records = state.result || [];

      const result = records.map(r => ({
        ...r,
        id: formatValue(r, "id", sliceVal),
        battery_code: formatValue(r, "code", i18n.BatteryExceptionType),
        insulation_code: formatValue(r, "code", i18n.InsulationExceptionType),
        tyre_code: formatValue(r, "code", i18n.TyreExceptionType),
        air_code: formatValue(r, "code", i18n.AirExceptionType),
        driving_code: formatValue(r, "code", i18n.DrivingExceptionType),
        dcdc_code: formatValue(r, "code", i18n.DCDCExceptionType),
        department: formatValue(r, "ns", getNsName),
        data_soc: formatValue(r, "data.soc", withPercent),
        data_maxNtc: formatValue(r, "data.maxNtc", withCentigrade),
        data_min_voltage_battery: formatMinVoltageBattery(r),
        data_min_voltage: formatValue(r, "data.minVoltage", withVoltage),
        data_resistance: formatValue(r, "data.resistance", withOhm),
        data_tyre: formatValue(r, "data.tyre", i18n.TiresType),
        data_tp: formatValue(r, "data.tp", withKpa),
        data_tt: formatValue(r, "data.tt", withCentigrade),
        data_insideTemp: formatValue(r, "data.insideTemp", withCentigrade),
        data_outsideTemp: formatValue(r, "data.outsideTemp", withCentigrade),
        data_airTemp: formatValue(r, "data.airTemp", withCentigrade),
        data_airMode: formatValue(r, "data.airMode", withAirMode),
        data_startAt: formatValue(r, "data.startAt", ymdhms),
        data_endAt: formatValue(r, "data.endAt", ymdhms),
        data_minSpeed: formatValue(r, "data.minSpeed", withKmh),
        data_maxSpeed: formatValue(r, "data.maxSpeed", withKmh),
        data_duration: formatValue(r, "data.duration", withSecond),
        data_percent: formatValue(r, "data.percent", val => val + "%"),
        data_dcov: formatValue(r, "data.dcov", withVoltage),
        data_batteryVoltage: formatValue(r, "data.batteryVoltage", withVoltage),
        startedAt: formatValue(r, "startedAt", ymdhms),
        lastAt: formatValue(r, "lastAt", ymdhms),
      }));

      return { ...state, result };
    }
  );

export const makeTerminalExceptionListSelector = exceptionSelector =>
  createSelector(
    exceptionSelector,
    state => {
      const records = state.result || [];
      const result = records.map(r => ({
        ...r,
        startedAt: formatValue(r, "startedAt", ymd),
        code: formatValue(r, "code", i18n.TerminalExceptionType),
      }));
      return { ...state, result };
    }
  );

export const makeHistoryExceptionListSelector = exceptionSelector =>
  createSelector(
    exceptionSelector,
    state => {
      const records = state.result || [];
      const result = records.map(r => ({
        ...r,
        startedAt: formatValue(r, "startedAt", ymd),
        code: formatValue(r, "code", i18n.TerminalExceptionType),
        line: formatValue(r, "vehicleLine", getNsPath),
        department: formatValue(r, "ns", getNsName),
      }));
      return { ...state, result };
    }
  );

const getTerminalDataExceptionSubtype = code => {
  if (code.startsWith("AIR")) {
    return i18n.TerminalDataExceptionType.AIR_EXCEPTION;
  }
  if (code.startsWith("MILEAGE")) {
    return i18n.TerminalDataExceptionType.MILEAGE_EXCEPTION;
  }
  if (code.startsWith("TYRE")) {
    return i18n.TerminalDataExceptionType.TYRE_EXCEPTION;
  }
  if (code.startsWith("LOCATION")) {
    return i18n.TerminalDataExceptionType.LOCATION_EXCEPTION;
  }

  return i18n.TerminalDataExceptionType.BATTERY_EXCEPTION;
};

const getTerminalDataExceptionDoc = code => {
  if (code.startsWith("AIR")) {
    return i18n.TerminalDataAirExceptionType[code];
  }
  if (code.startsWith("MILEAGE")) {
    return i18n.TerminalDataMileageExceptionType[code];
  }
  if (code.startsWith("TYRE")) {
    return i18n.TerminalDataTyreExceptionType[code];
  }
  if (code.startsWith("LOCATION")) {
    return i18n.TerminalDataLocationExceptionType[code];
  }
  return i18n.TerminalDataBatteryExceptionType[code];
};

export const makeTerminalDataExceptionListSelector = exceptionSelector =>
  createSelector(
    exceptionSelector,
    state => {
      const records = state.result || [];
      const result = records.map(r => ({
        ...r,
        shortId: formatValue(r, "id", sliceVal),
        startedAt: formatValue(r, "startedAt", ymdhms),
        lastAt: formatValue(r, "lastAt", ymdhms),
        subType: formatValue(r, "code", getTerminalDataExceptionSubtype),
        doc: formatValue(r, "code", getTerminalDataExceptionDoc),
        department: formatValue(r, "ns", getNsName),
      }));
      return { ...state, result };
    }
  );

/**
 * 充电桩监控
 */

import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { makeApiSelector, withSaga, tapOnLatest } from "@36node/redux";
import styled from "styled-components";
import { sumBy, get } from "lodash";
import { put } from "redux-saga/effects";
import { createSelector } from "reselect";
import { Spin, Table, Form, Row, Col, Card, Button } from "antd";
import moment from "moment";

import { pile } from "../../actions/api";
import { stationSchema } from "../../schemas";
import {
  getPileStatusUpdateAt,
  ymdhms,
  getPileStatusCount,
  withKw,
} from "../../lib";
import {
  PileStatus,
  PileStatusI18N,
  PileLockStatusI18N,
  PileParkStatusI18N,
  PileTypeI18N,
} from "../../constants";
import IconFont from "../../components/iconfont";
import { withTable } from "../../components/withTable";
import { PILE_GET_STATION } from "../../actions/types";
import {
  PileId,
  PileStatus as PileStatusSelect,
  PileModel,
} from "../../components/fields";
import { createForm } from "@36node/redux-antd";
import { Link } from "react-router-dom";

/**
 * actions
 */
const getStation = pile.station.makeGetStation("stations.get");
const listPiles = pile.pile.makeListPiles("station.piles", {
  query: {
    sort: "_id",
  },
});

/**
 * seletors
 */
const selectStation = makeApiSelector("stations.get", {
  schema: stationSchema,
});

const makeListPiles = selector =>
  createSelector(
    selector,
    listState => {
      const result = (listState.result || []).map(r => {
        return {
          ...r,
          status: PileStatusI18N[r.status],
          parkStatus: PileParkStatusI18N[r.parkStatus],
          lockStatus: PileLockStatusI18N[r.lockStatus],
          type: PileTypeI18N[r.type],
          current: get(r, "connectors[0].current")
            ? get(r, "connectors[0].current") + "A"
            : "--",
          power: r.power ? withKw(r.power) : "--",
          lastChargeAt: r.lastChargeAt && ymdhms(r.lastChargeAt),
        };
      });

      return {
        ...listState,
        result,
      };
    }
  );

/**
 * styled
 */

const Container = styled.div`
  width: 100%;
`;

const InfoArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 6px 0 24px 0;
  font-size: 15px;
`;

const StatArea = styled.div`
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

const VerticalDivider = styled.div`
  height: 50px;
  width: 1px;
  border: 1px solid #cecece;
`;

const StyledFilterForm = styled(Form)`
  padding-right: 12px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

const formItemLayout = {
  labelCol: { xxl: 4, xl: 5, lg: 6, md: 8, sm: 10 },
};

/**
 * constants
 */

const BaseColumns = [
  {
    title: "充电桩名称",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "充电桩状态",
    dataIndex: "status",
    key: "status",
    render: val => {
      if (val === PileStatusI18N[PileStatus.FAULT]) {
        return <span style={{ color: "red" }}>{val}</span>;
      }

      return val;
    },
  },
  {
    title: "车位状态",
    dataIndex: "parkStatus",
    key: "parkStatus",
  },
  {
    title: "地锁状态",
    dataIndex: "lockStatus",
    key: "lockStatus",
  },
  {
    title: "充电桩厂家",
    dataIndex: "manufacturerName",
    key: "manufacturerName",
  },
  {
    title: "充电桩型号",
    dataIndex: "equipmentModel",
    key: "equipmentModel",
  },
  {
    title: "充电桩类型",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "额定电流",
    dataIndex: "current",
    key: "current",
  },
  {
    title: "额定功率",
    dataIndex: "power",
    key: "power",
  },
  {
    title: "最近充电时间",
    dataIndex: "lastChargeAt",
    key: "lastChargeAt",
    render: val => (val ? moment(val).fromNow() : "从未使用"),
    sorter: true,
  },
];

const TableColumns = [
  {
    title: "充电桩编号",
    key: "_id",
    sorter: true,
    render: record => (
      <Link
        to={{
          pathname: `/pile/${record.id}/history`,
          state: {
            subPage: true,
            headerTitle: "充电桩监控详情",
          },
        }}
      >
        {record.id}
      </Link>
    ),
    defaultSortOrder: "ascend",
  },
  ...BaseColumns,
];

const ExportColumns = [
  {
    title: "充电桩编号",
    key: "id",
    dataIndex: "id",
  },
  ...BaseColumns,
];

/**
 * components
 */

@createForm("station.piles")
class SearchForm extends PureComponent {
  handleSubmit = e => {
    e && e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const filter = values;

        if (this.props.onFetch) {
          this.props.onFetch({ query: { filter } });
        }
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.handleSubmit();
  };

  render() {
    const { form } = this.props;
    return (
      <Card style={{ marginBottom: 8 }}>
        <StyledFilterForm {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col span={8}>
              <PileId form={form} />
            </Col>
            <Col span={8}>
              <PileStatusSelect form={form} />
            </Col>
            <Col span={8}>
              <PileModel form={form} />
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                筛选
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                重置
              </Button>
            </Col>
          </Row>
        </StyledFilterForm>
      </Card>
    );
  }
}

@withTable("station.piles", {
  SearchForm,
  title: "监控详情",
  columns: TableColumns,
  fetchOnMount: false,
  list: listPiles,
  makeListSelector: makeListPiles,
  customExportColumns: ExportColumns,
})
class PilesTable extends PureComponent {
  render() {
    return <Table {...this.props} />;
  }
}

@withSaga(
  tapOnLatest(PILE_GET_STATION.SUCCESS, "stations.get", function*(action) {
    const stationId = get(action, "payload.result");

    if (stationId) {
      yield put(
        listPiles({
          query: {
            filter: {
              station: stationId,
            },
          },
        })
      );
    }
  })
)
@connect(state => {
  const loading = selectStation(state).loading;
  const station = selectStation(state).result || {};
  return {
    loading,
    station,
  };
})
export default class PileMonitor extends PureComponent {
  get stationId() {
    return this.props.match.params.stationId;
  }

  componentDidMount() {
    this.props.dispatch(getStation({ stationId: this.stationId }));
  }

  get statusConfig() {
    const { station } = this.props;

    const { statistics = [] } = station;

    return [
      {
        title: "充电中",
        color: "#3f91f7",
        value: getPileStatusCount(statistics, PileStatus.TAKEN_CHARGING),
        divider: true,
      },
      {
        title: "占用中",
        color: "#f3cc49",
        value:
          getPileStatusCount(statistics, PileStatus.TAKEN_APPOINTMENT) +
          getPileStatusCount(statistics, PileStatus.TAKEN_NOT_CHARGING),
        divider: true,
      },
      {
        title: "空闲中",
        color: "#61be67",
        value: getPileStatusCount(statistics, PileStatus.FREE),
        divider: true,
      },
      {
        title: "故障中",
        color: "#de5667",
        value: getPileStatusCount(statistics, PileStatus.FAULT),
        divider: true,
      },
      {
        title: "离线中",
        color: "#cecece",
        value: getPileStatusCount(statistics, PileStatus.OFFLINE),
      },
    ];
  }

  render() {
    const { station, loading } = this.props;

    const { name, address, statistics = [] } = station;

    const totalPiles = sumBy(statistics, "count");
    const updatedAt = getPileStatusUpdateAt(statistics);

    return (
      <Spin spinning={loading}>
        <Container>
          <InfoArea>
            <div>
              {[name, address, `充电桩总数: ${totalPiles}`].join(" | ")}
            </div>
            <div>更新时间: {ymdhms(updatedAt)}</div>
          </InfoArea>
          <StatArea>
            {this.statusConfig.map(config => {
              return (
                <Fragment key={config.title}>
                  <div style={{ padding: "24px 48px", fontSize: "16px" }}>
                    <div>
                      <IconFont
                        type="icon-chargingpile"
                        style={{ color: config.color, fontSize: "18px" }}
                      />
                      <span>{config.title}</span>
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      {config.value}
                    </div>
                  </div>

                  {config.divider && <VerticalDivider />}
                </Fragment>
              );
            })}
          </StatArea>
          <PilesTable />
        </Container>
      </Spin>
    );
  }
}

import React, { PureComponent } from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";

import Teld from "./teld";
import Sgcc from "./sgcc";

const { TabPane } = Tabs;

@withRouter
export default class History extends PureComponent {
  onChange = key => {
    const { history } = this.props;

    history.push(`/pile/history/${key}`);
  };

  render() {
    return (
      <div>
        <StyledTabs
          type="card"
          defaultActiveKey="teld"
          onChange={this.onChange}
        >
          <TabPane tab="特来电" key="teld" />
          <TabPane tab="国家电网" key="sgcc" />
        </StyledTabs>
        <Switch>
          <Route path="/pile/history/teld" component={Teld} />
          <Route path="/pile/history/sgcc" component={Sgcc} />
          <Redirect from="/pile/history" to="/pile/history/teld" />
        </Switch>
      </div>
    );
  }
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    margin-bottom: 0;
  }
`;

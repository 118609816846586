import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { range } from "lodash";

/**
 * 里程日历
 */
export default class MileageCalendar extends PureComponent {
  static propTypes = {
    // 二位数组，data[m][d] 表示 m 月份 m 日的数据
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  };

  renderHeader = () => {
    return (
      <Row>
        {range(0, 32).map(d => {
          return <div key={d}>{d > 0 ? d : ""}</div>;
        })}
      </Row>
    );
  };

  determineColor(value) {
    if (value !== "") {
      if (value >= 200) {
        return "#7fc064";
      }

      if (value >= 50) {
        return "#648fde";
      }

      if (value > 0) {
        return "#c54e4f";
      }
    }

    return "#cccccc";
  }

  renderData = () => {
    const { data = [] } = this.props;
    return range(0, 12).map(m => {
      const month = data[m] || [];

      return (
        <Row key={m}>
          <div>{m + 1}</div>
          {range(0, 31).map(d => (
            <div
              className="data"
              style={{ background: this.determineColor(month[d]) }}
              key={d}
            >
              {month[d]}
            </div>
          ))}
        </Row>
      );
    });
  };

  render() {
    return (
      <Container>
        {this.renderHeader()}
        {this.renderData()}
      </Container>
    );
  }
}

const Row = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  border-left: #ccc 1px solid;
  div {
    max-width: 3.125%;
    min-width: 3.125%;
    height: 100%;
    flex-basis: 3.125%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: #ccc 1px solid;
    border-bottom: #ccc 1px solid;
    border-right: #ccc 1px solid;
    overflow: hidden;
  }

  .data {
    color: #fff;
    border-bottom: #fff 1px solid;
    border-right: #fff 1px solid;
  }
`;

const Container = styled.div`
  width: 100%;

  div:last-of-type:not(.data) {
    .data {
      border-bottom: 0;
    }

    border-bottom: #ccc 1px solid;
  }
`;

import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { Card } from "antd";
import { makeApiSelector } from "@36node/redux";

import { chart } from "../../actions/api";
import { ROOT_NS } from "../../config";
import {
  makeListChargeorderStatSelector,
  makeNsTreeSelector,
} from "../../selectors";
import { listNsSelector } from "../layouts/main";
import withTitle from "../../components/withTitle";
import TotalChargeChart from "../../components/charts/pile/total-charge-pie";
import IntervalChargeChart from "../../components/charts/pile/interval-charge-bar";

const listStatistics = chart.chargeorder.makeDriverPowersStat(
  "chargingOrders.stat",
  {
    query: {
      filter: {
        ns: { $regex: new RegExp(ROOT_NS) },
      },
    },
  }
);

const listStatisticsSelector = makeListChargeorderStatSelector(
  makeApiSelector("chargingOrders.stat"),
  makeNsTreeSelector(listNsSelector)
);

@withTitle("充电习惯分析")
@connect(state => ({
  stat: listStatisticsSelector(state).result || {},
}))
export default class Habit extends PureComponent {
  componentDidMount() {
    // 统计api默认为近30天
    this.props.dispatch(listStatistics());
  }

  renderTotalChargeChart = () => {
    const { stat } = this.props;
    return (
      <Card
        title={<div>近30天充电量排名-按营运公司</div>}
        style={{ marginBottom: 24 }}
      >
        <TotalChargeChart data={stat.total} />
      </Card>
    );
  };

  renderIntervalChargeChart = () => {
    const { stat } = this.props;
    return (
      <Card title={<div>近30天各时段充电量占比-按营运公司</div>}>
        <IntervalChargeChart data={stat.interval} />
      </Card>
    );
  };

  render() {
    return (
      <Fragment>
        {this.renderTotalChargeChart()}
        {this.renderIntervalChargeChart()}
      </Fragment>
    );
  }
}

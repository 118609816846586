import React, { Fragment } from "react";
import {
  Form,
  Radio,
  Select,
  TreeSelect,
  DatePicker,
  Input,
  TimePicker,
} from "antd";

import i18n from "../../i18n";
import {
  TerminalStatus,
  RecordCommands,
  PileStatusI18N,
} from "../../constants";

const { RangePicker, MonthPicker } = DatePicker;

export const Record = {
  /**
   * 报文命令解析
   */
  Command: ({
    form,
    initialValue,
    rules,
    name = "command",
    label = "报文类型",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.values(RecordCommands).map(t => {
              return (
                <Select.Option value={t} key={t}>
                  {i18n.Command[t]}
                </Select.Option>
              );
            })}
          </Select>
        )}
      </Form.Item>
    );
  },
};

/**
 * 用户部门选择
 */
export function Department({
  form,
  initialValue,
  rules,
  name = "ns",
  label = "部门",
  placeholder = "请选择部门",
  dataSource = [], // tree array of namespace object
  ...rest
}) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <TreeSelect
          placeholder={placeholder}
          treeData={dataSource}
          allowClear
          {...rest}
        />
      )}
    </Form.Item>
  );
}

export function DriverName({
  form,
  initialValue,
  rules,
  name = "driverName_like",
  label = "驾驶员姓名",
  placeholder = "驾驶员姓名",
  ...rest
}) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <Input placeholder={placeholder} allowClear {...rest} />
      )}
    </Form.Item>
  );
}

export function DriverNo({
  form,
  initialValue,
  rules,
  name = "driverNo",
  label = "驾驶员工号",
  placeholder = "驾驶员工号",
  ...rest
}) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <Input placeholder={placeholder} allowClear {...rest} />
      )}
    </Form.Item>
  );
}

export function PileId({
  form,
  initialValue,
  rules,
  name = "id",
  label = "充电桩编号",
  placeholder = "充电桩编号",
  ...rest
}) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <Input placeholder={placeholder} allowClear {...rest} />
      )}
    </Form.Item>
  );
}

export function PileStatus({
  form,
  initialValue,
  rules,
  name = "status",
  label = "充电桩状态",
  placeholder = "充电桩状态",
  ...rest
}) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <Select placeholder="请选择" allowClear {...rest}>
          {Object.keys(PileStatusI18N).map(status => {
            return (
              <Select.Option key={status} value={status}>
                {PileStatusI18N[status]}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </Form.Item>
  );
}

export function PileModel({
  form,
  initialValue,
  rules,
  name = "equipmentModel",
  label = "充电桩型号",
  placeholder = "充电桩型号",
  ...rest
}) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <Input placeholder={placeholder} allowClear {...rest} />
      )}
    </Form.Item>
  );
}

export function StationSelect({
  form,
  initialValue,
  rules,
  name = "station",
  label = "充电站",
  placeholder = "请选择充电站",
  dataSource = [], // array of producer object
  ...rest
}) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <Select placeholder={placeholder} allowClear {...rest}>
          {dataSource.map(it => (
            <Select.Option value={it.id} key={it.id}>
              {it.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
}

export const Alert = {
  State: ({
    form,
    initialValue,
    rules,
    name = "state",
    label = "报警状态",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.entries(i18n.AlertState).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
  Level: ({
    form,
    initialValue,
    rules,
    name = "level",
    label = "报警等级",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.entries(i18n.AlertLevel).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
  StartedAt: ({
    form,
    initialValue,
    rules,
    name = "startedAt",
    label = "开始日期",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <RangePicker
            placeholder={["起始日期", "结束日期"]}
            style={{ width: "100%" }}
            {...rest}
          />
        )}
      </Form.Item>
    );
  },
  Type: ({
    form,
    initialValue,
    rules,
    name = "type",
    label = "报警类型",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.entries(i18n.AlertType).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
  Code: ({
    form,
    initialValue,
    rules,
    name = "code",
    label = "报警码",
    placeholder = "请输入报警码",
    ...rest
  }) => {
    const { getFieldDecorator } = form;
    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  Name: ({
    form,
    initialValue,
    rules,
    name = "name",
    label = "报警名称",
    placeholder = "请输入报警名称",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
};

export const Vehicle = {
  Producer: ({
    form,
    initialValue,
    rules,
    name = "producer",
    label = "厂商",
    placeholder = "请选择厂商",
    dataSource = [], // array of producer object
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder={placeholder} allowClear {...rest}>
            {dataSource.map(it => (
              <Select.Option value={it.name} key={it.name}>
                {it.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
  Model: ({
    form,
    initialValue,
    rules,
    name = "model",
    label = "车型",
    placeholder = "请输入车型",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  ModelBrief: ({
    form,
    initialValue,
    rules,
    name = "modelBrief",
    label = "车型简称",
    placeholder = "请输入车型简称",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  Plate: ({
    form,
    initialValue,
    rules,
    name = "plate",
    label = "车牌号",
    placeholder = "请输入车辆车牌号",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  Label: ({
    form,
    initialValue,
    rules,
    name = "labels",
    label = "标签",
    placeholder = "请输入车辆标签",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  RepairWorkshop: ({
    form,
    initialValue,
    rules,
    name = "repairWorkshop",
    label = "车间",
    placeholder = "请输入车间",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  No: ({
    form,
    initialValue,
    rules,
    name = "no",
    label = "自编号",
    placeholder = "请输入车辆自编号",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  Vin: ({
    form,
    initialValue,
    rules,
    name = "vin",
    label = "车辆VIN码",
    placeholder = "请输入车辆VIN码",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  Line: ({
    form,
    initialValue,
    rules,
    name = "line",
    label = "线路",
    placeholder = "请选择线路",
    dataSource = [], // array of producer object
    valueIsName = false,
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder={placeholder} allowClear {...rest}>
            {dataSource.map(it => (
              <Select.Option
                value={!valueIsName ? it.id : it.name}
                key={it.name}
              >
                {it.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
  DataType: ({
    form,
    initialValue,
    rules,
    name = "dataType",
    label = "数据项",
    placeholder,
    dataSource = [], // array of producer object
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <TreeSelect
            showSearch
            searchPlaceholder="搜索数据项"
            treeNodeFilterProp="title"
            placeholder={placeholder}
            treeData={dataSource}
            {...rest}
          />
        )}
      </Form.Item>
    );
  },
  LifeYear: ({
    form,
    initialValue,
    rules,
    name = "lifeYear",
    label = "使用年限(年)",
    placeholder = "请输入车辆使用年限",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input placeholder={placeholder} {...rest} allowClear />
        )}
      </Form.Item>
    );
  },
  Terminal: ({
    form,
    initialValue,
    rules,
    name = "code",
    label = "异常类型",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.entries(i18n.TerminalExceptionType).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
};

export const Ticket = {
  Action: ({
    form,
    initialValue,
    rules,
    name = "action",
    label = "处置方式",
    select,
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Radio.Group {...rest}>
            {Object.entries(i18n.TicketAction).map(([key, value]) => (
              <Radio value={key} key={key}>
                {value}
              </Radio>
            ))}
          </Radio.Group>
        )}
        <div>{select ? i18n.TicketActionTip[select] : ""}</div>
      </Form.Item>
    );
  },
  Content: ({
    form,
    initialValue,
    rules,
    name = "content",
    label = "处置说明",
    placeholder = "请输入处置说明",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Input.TextArea placeholder={placeholder} {...rest} />
        )}
      </Form.Item>
    );
  },
  Stage: ({
    form,
    initialValue,
    rules,
    name = "stage",
    label = "处置状态",
    placeholder = "请选择状态",
    dataSource = [], // array of producer object
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder={placeholder} {...rest}>
            {dataSource.map(it => (
              <Select.Option value={it.id} key={it.id}>
                {it.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
};

export const Terminal = {
  TerminalStatus: ({
    form,
    initialValue,
    rules,
    name = "terminalStatus",
    label = "终端状态",
    placeholder = "请选择终端状态",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder={placeholder} allowClear {...rest}>
            {Object.keys(TerminalStatus).map(s => (
              <Select.Option key={s} value={s}>
                {i18n.TerminalStatus[s]}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
  IsLocation: ({
    form,
    initialValue,
    rules,
    name = "isLocation",
    label = "GPS定位",
    placeholder = "请选择",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder={placeholder} allowClear {...rest}>
            <Select.Option value="true">是</Select.Option>
            <Select.Option value="false">否</Select.Option>
          </Select>
        )}
      </Form.Item>
    );
  },
  VehicleStatus: ({
    form,
    initialValue,
    rules,
    name = "vehicleStatus",
    label = "车辆状态",
    placeholder = "请选择",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder={placeholder} allowClear {...rest}>
            <Select.Option value="ON">行驶</Select.Option>
            <Select.Option value="OFF">停止</Select.Option>
          </Select>
        )}
      </Form.Item>
    );
  },
  TerminalDataException: ({
    form,
    initialValue,
    rules,
    name = "subType",
    label = "异常类型",
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.entries(i18n.TerminalDataExceptionType).map(
              ([key, value]) => (
                <Select.Option value={key} key={key}>
                  {value}
                </Select.Option>
              )
            )}
          </Select>
        )}
      </Form.Item>
    );
  },
  TerminalDataExceptionDoc: ({
    form,
    initialValue,
    rules,
    name = "code",
    label = "异常内容",
    dataSource,
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.entries(dataSource ? dataSource : {}).map(
              ([key, value]) => (
                <Select.Option value={key} key={key}>
                  {value}
                </Select.Option>
              )
            )}
          </Select>
        )}
      </Form.Item>
    );
  },
};

export const Battery = {
  Type: ({
    form,
    initialValue,
    rules,
    name = "type",
    label = "预警类型",
    ...rest
  }) => {
    const { getFieldDecorator } = form;
    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.entries(i18n.BetteryWarningType).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
};

export const Exception = {
  Code: ({
    form,
    initialValue,
    rules,
    name = "code",
    label = "异常名称",
    options,
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, { initialValue, rules })(
          <Select placeholder="请选择" allowClear {...rest}>
            {Object.entries(options).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  },
};

export const TimeSelect = ({
  form,
  initialValue,
  rules,
  name = "time",
  label = "时间",
  placeholder = "请输入时间",
  ...rest
}) => {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <DatePicker
          style={{ width: "100%" }}
          placeholder={placeholder}
          {...rest}
        />
      )}
    </Form.Item>
  );
};

export const MonthSelect = ({
  form,
  initialValue,
  rules,
  name = "date",
  label = "月份",
  placeholder = "请选择月份",
  allowClear = true,
  ...rest
}) => {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <MonthPicker
          allowClear={allowClear}
          placeholder={placeholder}
          style={{ width: "100%" }}
          {...rest}
        />
      )}
    </Form.Item>
  );
};

export const DateRange = ({
  form,
  initialValue,
  rules,
  name = "date",
  label = "日期",
  placeholder = ["起始日期", "结束日期"],
  showTime = false,
  allowClear = true,
  ...rest
}) => {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <RangePicker
          allowClear={allowClear}
          showTime={showTime}
          style={{ width: "100%" }}
          placeholder={placeholder}
          {...rest}
        />
      )}
    </Form.Item>
  );
};

export const DateDetailRange = ({
  form,
  initialValue = [null, null],
  rules,
  allowClear = true,
  ...rest
}) => {
  const { getFieldDecorator } = form;

  return (
    <Fragment>
      <Form.Item label="选择时间">
        {getFieldDecorator("startDate", {
          initialValue: initialValue[0],
        })(<DatePicker style={{ width: 130 }} />)}
      </Form.Item>
      <Form.Item label="">
        {getFieldDecorator("startTime", {
          initialValue: initialValue[0],
        })(<TimePicker format="HH:mm" style={{ width: 100 }} />)}
      </Form.Item>
      <span style={{ lineHeight: "40px", marginRight: "16px" }}>至</span>
      <Form.Item label="">
        {getFieldDecorator("endDate", {
          initialValue: initialValue[1],
        })(<DatePicker style={{ width: 130 }} />)}
      </Form.Item>
      <Form.Item label="">
        {getFieldDecorator("endTime", {
          initialValue: initialValue[1],
        })(<TimePicker format="HH:mm" style={{ width: 100 }} />)}
      </Form.Item>
    </Fragment>
  );
};

export const Keyword = ({
  form,
  initialValue,
  rules,
  name = "q",
  label = "车辆",
  placeholder = "请输入 vin码/自编号/车牌号",
  ...rest
}) => {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue, rules })(
        <Input placeholder={placeholder} {...rest} allowClear />
      )}
    </Form.Item>
  );
};

export const ElectricityPrice = ({
  form,
  initialValue,
  name = "price",
  label = "电价",
  ...rest
}) => {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, {
        initialValue,
        rules: [
          {
            required: false,
            pattern: /^([.0-9])+$/gi,
            message: "请输入数字或小数点",
          },
        ],
      })(<Input placeholder="请输入电价" suffix="元/kWh" />)}
    </Form.Item>
  );
};

import { createSelector } from "reselect";
import { sumBy, uniq, take, sortBy, concat, cloneDeep } from "lodash";

import i18n from "../i18n";

const getValueOrDefault = (record, key, defaultValue) => {
  if (record.data.find(r => r.name === key)) {
    return record.data.find(r => r.name === key).value;
  } else {
    return defaultValue;
  }
};

const getSiblingRecords = (parentGroupName, records) => {
  if (parentGroupName === null) {
    return records;
  }
  return records.filter(record => record.name === parentGroupName);
};

const hasSiblingRecords = (parentGroupName, records) => {
  if (parentGroupName === null) {
    return true;
  }
  const siblingRecords = records.filter(
    record => record.name === parentGroupName
  );
  return siblingRecords.length > 0;
};

const getSubGroupName = (record, depth) => {
  return take(record.name.split("/"), depth).join("/");
};

const hasSubRecords = (parentGroupName, records, depth) => {
  if (parentGroupName === null) {
    return true;
  }
  const subRecords = records
    .filter(record => getSubGroupName(record, depth - 1) === parentGroupName)
    .filter(
      record => getSubGroupName(record, depth).split("/").length === depth
    );
  return subRecords.length > 0;
};

const getSubRecords = (parentGroupName, records, depth) => {
  if (parentGroupName === null) {
    return records;
  }
  const subRecords = records
    .filter(record => getSubGroupName(record, depth - 1) === parentGroupName)
    .filter(
      record => getSubGroupName(record, depth).split("/").length === depth
    );
  return subRecords;
};

const getSubGroups = (parentGroupName, records, depth, recordTotal) => {
  if (
    !hasSubRecords(parentGroupName, records, depth) &&
    !hasSiblingRecords(parentGroupName, records)
  ) {
    return [];
  }
  let subGroups = uniq(
    getSubRecords(parentGroupName, records, depth).map(record =>
      getSubGroupName(record, depth)
    )
  )
    .filter(subGroupName => hasSubRecords(subGroupName, records, depth + 1))
    .map(subGroupName => {
      const item = {
        id: subGroupName,
        group: subGroupName,
        name: subGroupName,
      };
      return item;
    });
  subGroups = subGroups.map(subGroup => {
    const children = getSubGroups(
      subGroup.name,
      records,
      depth + 1,
      recordTotal
    );
    const total = recordTotal(children);
    subGroup = { ...total, ...subGroup };
    subGroup.children = children;
    return subGroup;
  });
  const siblingRecords = uniq(
    getSubRecords(parentGroupName, records, depth).map(record =>
      getSubGroupName(record, depth)
    )
  )
    .filter(subGroupName => hasSiblingRecords(subGroupName, records))
    .map(subGroupName => getSiblingRecords(subGroupName, records))
    .reduce((a, b) => a.concat(b), []);
  if (parentGroupName !== null) {
    return subGroups.concat(siblingRecords);
  } else if (subGroups.length === 0) {
    return siblingRecords;
  } else {
    return subGroups;
  }
};
const buildTreeGroups = (records, recordTotal) => {
  return getSubGroups(null, records, 1, recordTotal);
};
const dataMap = (data, { type, groupKey, mapRecord, recordTotal, linear }) => {
  const result = (data || []).map(row => mapRecord(row, type, groupKey));

  if (groupKey === "company" && !linear) {
    return buildTreeGroups(result, recordTotal);
  } else {
    const item = recordTotal(result, mapRecord);
    return concat(result, item);
  }
};
const mapWarningRecord = (record, type, groupKey) => {
  const item = {};
  item.id = record.group;
  item.group = record.group;
  item.name = record.name;
  if (groupKey === "line" && record.data.find(r => r.name === "部门")) {
    item.company = getValueOrDefault(record, "部门", 0);
  }
  if (
    groupKey !== "type" &&
    record.data.find(r => r.name === "新能源车辆总数")
  ) {
    item.vehicleCount = getValueOrDefault(record, "新能源车辆总数", 0);
  } else {
    item.vehicleCount = "";
  }
  if (type === "alert") {
    item.level3AlarmIgnored = getValueOrDefault(record, "三级报警/已忽略", 0);
    item.level3AlarmDoing = getValueOrDefault(record, "三级报警/处理中", 0);
    item.level3AlarmDone = getValueOrDefault(record, "三级报警/已完成", 0);
    item.level3AlarmTotal =
      item.level3AlarmIgnored + item.level3AlarmDoing + item.level3AlarmDone;
    item.level2AlarmTotal = getValueOrDefault(record, "二级报警", 0);
    item.level1AlarmTotal = getValueOrDefault(record, "一级报警", 0);

    const byModelBrief = getValueOrDefault(record, "车型");

    if (byModelBrief) {
      item.children = byModelBrief.map(model => {
        return {
          name: model.modelBrief,
          id: record.group + model.modelBrief,
          group: model.modelBrief,
          level3AlarmTotal: model.data[3] || 0,
          level2AlarmTotal: model.data[2] || 0,
          level1AlarmTotal: model.data[1] || 0,
          vehicleCount: model.vehicleCount,
        };
      });
    }
  } else if (type === "battery") {
    item.socLt30 = getValueOrDefault(record, "SOC_BETWEEN_20_30_PERCENT", 0);
    item.socLt20 = getValueOrDefault(record, "SOC_BELOW_20_PERCENT", 0);
    item.tmpIn45_50 = getValueOrDefault(record, "TEMP_BETWEEN_45_55", 0);
    item.tmpGt50 = getValueOrDefault(record, "TEMP_OVER_55", 0);
  } else if (type === "insulation") {
    item.between500_1000 = getValueOrDefault(
      record,
      "RESISTANCE_BETWEEN_500_100000",
      0
    );
    item.below500 = getValueOrDefault(record, "RESISTANCE_BELOW_500", 0);
    item.normal = getValueOrDefault(record, "INSULATE_NORMAL", 0);
  } else if (type === "tyre") {
    item.lowTP = getValueOrDefault(record, "PRESSURE_BELOW_900", 0);
    item.overTP = getValueOrDefault(record, "PRESSURE_OVER_1100", 0);
    item.overTT = getValueOrDefault(record, "TEMP_OVER_40", 0);
  } else if (type === "air") {
    item.shouldOpen = getValueOrDefault(record, "AIR_SHOULD_OPEN", 0);
    item.shouldNotOpen = getValueOrDefault(record, "AIR_SHOULD_NOT_OPEN", 0);
    item.shouldNotOpenSpecialCase = getValueOrDefault(
      record,
      "AIR_SHOULD_NOT_OPEN_SPECIALCASE",
      0
    );
  } else if (type === "driving") {
    item.acceleration = getValueOrDefault(record, "DRIVING_ACCELERATION", 0);
    item.brakes = getValueOrDefault(record, "DRIVING_BRAKES", 0);
    item.overSpeed = getValueOrDefault(record, "DRIVING_OVER_SPEED", 0);
  }

  item.calculatedRecord = false;

  return item;
};

const recordWarningTotal = (records, type, groupKey) => {
  const item = {};
  item.id = "合计";
  item.group = "合计";
  item.name = "合计";
  if (groupKey !== "type") {
    item.vehicleCount = sumBy(records, "vehicleCount");
  } else {
    item.vehicleCount = "";
  }

  if (groupKey === "line") {
    item.name = "";
    item.company = "合计";
  }
  if (type === "alert") {
    item.level3AlarmIgnored = sumBy(records, "level3AlarmIgnored");
    item.level3AlarmDoing = sumBy(records, "level3AlarmDoing");
    item.level3AlarmDone = sumBy(records, "level3AlarmDone");
    item.level3AlarmTotal = sumBy(records, "level3AlarmTotal");
    item.level2AlarmTotal = sumBy(records, "level2AlarmTotal");
    item.level1AlarmTotal = sumBy(records, "level1AlarmTotal");
  } else if (type === "battery") {
    item.socLt30 = sumBy(records, "socLt30");
    item.socLt20 = sumBy(records, "socLt20");
    item.tmpIn45_50 = sumBy(records, "tmpIn45_50");
    item.tmpGt50 = sumBy(records, "tmpGt50");
  } else if (type === "insulation") {
    item.between500_1000 = sumBy(records, "between500_1000");
    item.below500 = sumBy(records, "below500");
    item.normal = sumBy(records, "normal");
  } else if (type === "tyre") {
    item.lowTP = sumBy(records, "lowTP");
    item.overTP = sumBy(records, "overTP");
    item.overTT = sumBy(records, "overTT");
  } else if (type === "air") {
    item.shouldOpen = sumBy(records, "shouldOpen");
    item.shouldNotOpen = sumBy(records, "shouldNotOpen");
    item.shouldNotOpenSpecialCase = sumBy(records, "shouldNotOpenSpecialCase");
  } else if (type === "driving") {
    item.acceleration = sumBy(records, "acceleration");
    item.brakes = sumBy(records, "brakes");
    item.overSpeed = sumBy(records, "overSpeed");
  }

  item.calculatedRecord = true;

  return item;
};

export const makeWarningRecordSelector = (
  type,
  groupKey
) => listWarningsSelector =>
  createSelector(
    listWarningsSelector,
    state => {
      const { result = [] } = state;

      const data = dataMap(sortBy(result, ["group"]), {
        type,
        groupKey,
        mapRecord: mapWarningRecord,
        recordTotal: records => recordWarningTotal(records, type, groupKey),
      });

      return { ...state, result: data, total: data.length - 1 };
    }
  );

// 通过producer厂商数据获取全部车型简称
export const makeModelBriefSelector = producerSelector =>
  createSelector(
    producerSelector,
    state => {
      const producers = state.result || [];
      let result = uniq(
        producers.reduce((x, y) => x.concat(y.modelBriefs), [])
      );
      result.sort((a, b) => a.localeCompare(b));
      return result;
    }
  );

export const makeAlertReportByModelBriefSelector = modelBriefSelector => alertSelector =>
  createSelector(
    modelBriefSelector,
    alertSelector,
    (mbState, alertState) => {
      const modelBriefs = mbState || [];
      const alerts = alertState.result || [];
      let temp = cloneDeep(alerts);
      // 按报警类型排序
      const alertTypes = Object.keys(i18n.AlertType);
      const alertDetails = i18n.AlertDetails.map(detail => detail.code);

      // 忽略 未知警报
      temp = temp.filter(v => alertTypes.indexOf(v.key) !== -1);
      temp.sort(
        (a, b) => alertTypes.indexOf(a.key) - alertTypes.indexOf(b.key)
      );

      let result = [],
        total = { id: "合计", key: "合计", name: "合计", level: "--" };

      // 当前withTable导出的为当前显示的数据，因此需要将车型的报警数置为0
      // 而makeXlsxSelector目前无效 20200307
      modelBriefs.forEach(mb => (total[mb] = 0));
      temp.forEach((cg, tIndex) => {
        cg.value.sort(
          (a, b) => alertDetails.indexOf(a.key) - alertDetails.indexOf(b.key)
        );
        cg.value.forEach((mbg, nIndex) => {
          const alert = i18n.AlertDetails.find(a => a.code === mbg.key) || {
            name: "--",
            level: 1,
          };
          let value = {
            id: mbg.key,
            name: alert.name,
            level: alert.level,
          };
          modelBriefs.forEach(mb => (value[mb] = 0));
          mbg.value.forEach(v => {
            value[v.name] = v.value;
            total[v.name] += v.value;
          });
          if (nIndex === cg.value.length - 1 && tIndex < temp.length - 1)
            value.edge = true;
          result.push(value);
        });
      });
      result.push(total);
      return { ...alertState, result, total: result.length - 1 };
    }
  );

import React, { PureComponent } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { Form, Button, Row, Col, Table, Layout, Menu } from "antd";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { createForm } from "@36node/redux-antd";

import { withTable } from "../../components/withTable";
import Level from "../../components/level";
import { DateRange, Alert, Vehicle } from "../../components/fields";
import { op } from "../../actions/api";
import { ROOT_NS } from "../../config";
import {
  makeAlertListDetailSelector,
  makeTerminalExceptionListSelector,
} from "../../selectors";

const { Header, Content } = Layout;

const listAlerts = op.alert.makeListAlerts("alert.detail", {
  query: {
    limit: 10,
    offset: 0,
    sort: "-lastAt",
  },
});
const listExceptionsByTerminal = op.exception.makeListExceptions(
  "vehicle.terminal",
  {
    query: {
      limit: 10,
      offset: 0,
      sort: "-lastAt",
      filter: {
        ns: { $regex: new RegExp(ROOT_NS) },
        type: "TERMINAL",
      },
    },
  }
);

const defaultMenuKey = "Alert";
const menu = [
  {
    title: "报警记录",
    key: "Alert",
  },
  {
    title: "终端异常",
    key: "Terminal",
  },
];

const alertColumns = [
  {
    title: "开始时间",
    dataIndex: "startedAt",
    key: "startedAt",
  },
  {
    title: "结束时间",
    dataIndex: "lastAt",
    key: "lastAt",
  },
  {
    title: "报警码",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "报警名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "等级",
    dataIndex: "level",
    key: "level",
    render: val => <Level value={val} />,
  },
  {
    title: "次数",
    dataIndex: "count",
    key: "count",
  },
  {
    title: "状态",
    dataIndex: "state",
    key: "state",
  },
];

const terminalColumns = [
  {
    title: "异常日期",
    dataIndex: "startedAt",
    key: "startedAt",
  },
  {
    title: "异常类型",
    dataIndex: "code",
    key: "code",
  },
];

@withRouter
@createForm("vehilce.alert")
class AlertSearchForm extends PureComponent {
  get vehicleId() {
    return get(this.props.match, "params.vehicleId");
  }

  componentDidMount() {
    this.props.onFetch({ query: { filter: { vehicle: this.vehicleId } } });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const filter = { ...values };

        if (filter.startedAt) {
          const { startedAt } = filter;
          filter.startedAt_gt = startedAt[0] && startedAt[0].toISOString();
          filter.startedAt_lt = startedAt[1] && startedAt[1].toISOString();
          delete filter.startedAt;
        }

        this.props.onFetch({
          query: { filter: { vehicle: this.vehicleId, ...filter } },
        });
      }
    });
  };

  render() {
    const { form } = this.props;
    return (
      <StyledFilterForm {...formItemLayout} onSubmit={this.handleSubmit}>
        <Row gutter={48} type="flex">
          <Col span={6} key="level">
            <Alert.Level form={form} />
          </Col>
          <Col span={6} key="state">
            <Alert.State form={form} />
          </Col>
          <Col span={8} key="startedAt">
            <DateRange name="startedAt" form={form} />
          </Col>
          <Col span={4} key="submit">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                筛选
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledFilterForm>
    );
  }
}

@connect()
@withTable("alert.detail", {
  SearchForm: AlertSearchForm,
  title: "报警查询结果",
  columns: alertColumns,
  list: listAlerts,
  makeListSelector: makeAlertListDetailSelector,
  fetchOnMount: false,
})
class AlertTable extends PureComponent {
  render() {
    return <Table {...this.props} />;
  }
}

@withRouter
@createForm("vehilce.terminal")
class TerminalSearchForm extends PureComponent {
  get vehicleId() {
    return get(this.props.match, "params.vehicleId");
  }

  componentDidMount() {
    this.props.onFetch({ query: { filter: { vehicle: this.vehicleId } } });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const filter = { ...values };
        delete filter.code;

        if (filter.startedAt) {
          const { startedAt } = filter;
          filter.startedAt_gt = startedAt[0] && startedAt[0].toISOString();
          filter.startedAt_lt = startedAt[1] && startedAt[1].toISOString();
          delete filter.startedAt;
        }
        this.props.onFetch({
          query: { filter: { vehicle: this.vehicleId, ...filter } },
        });
      }
    });
  };

  render() {
    const { form } = this.props;
    return (
      <StyledFilterForm {...formItemLayout} onSubmit={this.handleSubmit}>
        <Row gutter={24} type="flex">
          <Col span={8} key="code">
            <Vehicle.Terminal form={form} />
          </Col>
          <Col span={8} key="startedAt">
            <DateRange name="startedAt" label="异常日期" form={form} />
          </Col>
          <Col span={8} key="submit">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 24 }}
              >
                筛选
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledFilterForm>
    );
  }
}

@connect()
@withTable("vehicle.terminal", {
  SearchForm: TerminalSearchForm,
  title: "终端异常查询结果",
  columns: terminalColumns,
  list: listExceptionsByTerminal,
  makeListSelector: makeTerminalExceptionListSelector,
  fetchOnMount: false,
})
class TerminalTable extends PureComponent {
  render() {
    return <Table {...this.props} />;
  }
}

export default class VehicleAlert extends PureComponent {
  state = {
    selectedKeys: defaultMenuKey,
  };
  handleClick = e => {
    this.setState({
      selectedKeys: e.key,
    });
  };
  render() {
    const { selectedKeys } = this.state;
    return (
      <Layout style={{ height: "100%" }}>
        <Header style={{ background: "#fff", padding: "0 20px" }}>
          <Menu
            theme="light"
            mode="horizontal"
            selectedKeys={this.state.selectedKeys}
            onClick={this.handleClick}
          >
            {menu.map(item => (
              <Menu.Item key={item.key}>{item.title}</Menu.Item>
            ))}
          </Menu>
        </Header>
        <Content style={{ background: "#fff" }}>
          {selectedKeys === defaultMenuKey ? <AlertTable /> : <TerminalTable />}
        </Content>
      </Layout>
    );
  }
}

const formItemLayout = {
  labelCol: { xl: 8, lg: 12, sm: 18, xs: 24 },
  wrapperCol: { xl: 8, lg: 12, sm: 18, xs: 24 },
};

const StyledFilterForm = styled(Form)`
  margin-top: 12px !important;
  padding: 0px 30px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .pack-filter {
    display: flex;
    justify-content: center;

    .ant-btn {
      border: 0;
    }
  }
`;

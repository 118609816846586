import { createSelector } from "reselect";

import { getNsName } from "../lib";
import i18n from "../i18n";

const getUserLocalRoles = user =>
  user.roles
    .filter(r => user.ns.find(ns => ns.id === r.ns))
    .map(r => i18n.UserRoles[r.name])
    .join(", ") || "--";

export const makeUserListSelector = userListSelector =>
  createSelector(
    userListSelector,
    listState => {
      const result = listState.result || [];
      return {
        ...listState,
        result: result.map(r => ({
          ...r,
          department: getNsName(r.ns) || "--",
          role: getUserLocalRoles(r),
        })),
      };
    }
  );

import { fork, all, takeEvery, takeLatest } from "redux-saga/effects";
import { watchApi, watchCron, isFailure } from "@36node/redux";
import { watchXlsx } from "@36node/redux-xlsx";
import { makeSessionWatcher } from "@36node/redux-session";

import { auth } from "../actions/api";
import { LOGIN, REFRESH, LOGOUT } from "../actions/types";
import watchAlertVoice from "./alert-audio";
import watchAlertNotification from "./alert-notification";
// import watchAlertTicket from "./alert-ticket";

const refresh = auth.session.makeRefresh("session");

const watchSession = makeSessionWatcher({
  refresh,
  loginSuccessTypes: [LOGIN.SUCCESS, REFRESH.SUCCESS],
  logoutSuccessTypes: [LOGOUT.SUCCESS],
});

const watchSessionSuccess = takeLatest(
  [LOGIN.SUCCESS, REFRESH.SUCCESS],
  function(action) {
    localStorage.setItem(
      "session_result",
      JSON.stringify(action.payload.result)
    );
    sessionStorage.setItem("SESSION", JSON.stringify(action.payload.result));
  }
);

const watchLogoutSuccess = takeLatest([LOGOUT.SUCCESS], function() {
  localStorage.removeItem("session_result");
  sessionStorage.removeItem("SESSION");
});

function onApiFailure(action) {
  console.error(action.error);
}

export function setDiaptch(dispatch) {}

export default function* root() {
  yield all([
    fork(watchApi),
    fork(watchCron),
    fork(watchXlsx),
    fork(watchSession),
    fork(watchAlertVoice),
    fork(watchAlertNotification),
    takeEvery(isFailure, onApiFailure),
    watchSessionSuccess,
    watchLogoutSuccess,
    // fork(watchAlertTicket),
  ]);
}

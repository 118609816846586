import React from "react";
import Loadable from "react-loadable";
import { Spin } from "antd";

const Loading = () => (
  <div
    style={{
      height: "100vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Spin spinning={true} size="large" />
  </div>
);

export default function MyLoadable({ loader }) {
  return Loadable({
    loader,
    loading: () => <Loading />,
  });
}

import React from "react";
import ReactDOM from "react-dom";
import { configureStore } from "@36node/redux";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

import "./index.css";
import App from "./app";
import rootSaga from "./sagas";
import reducer from "./reducers";
import labelsFilterMiddleware from "./reducers/middlewares/labels-filter";
import producerFilterMiddleware from "./reducers/middlewares/producer-filter";
import companytNsFilterMiddleware from "./reducers/middlewares/comapny-ns-filter";
import repairWorkshopMiddleware from "./reducers/middlewares/repair-workshop-filter";

moment.locale("zh-cn");

const store = configureStore(
  reducer,
  labelsFilterMiddleware,
  producerFilterMiddleware,
  companytNsFilterMiddleware,
  repairWorkshopMiddleware
);
store.runSaga(rootSaga);

ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById("root")
);

// 暴露全局dispatch, 为了在一些不在 Redux Provider 内的 组件使用，比如全局的报警提示
export const dispatch = store.dispatch;

import React, { PureComponent } from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";

import Summary from "./summary";

const { TabPane } = Tabs;

@withRouter
export default class extends PureComponent {
  onChange = key => {
    const { history } = this.props;

    history.push(`/pile/history/${key}`);
  };

  render() {
    return (
      <div>
        <StyledTabs
          type="card"
          defaultActiveKey="summary"
          onChange={this.onChange}
        >
          <TabPane tab="充电数据汇总" key="summary" />
        </StyledTabs>
        <Switch>
          <Route path="/pile/summary/summary" component={Summary} />
          <Redirect from="/pile/summary" to="/pile/summary/summary" />
        </Switch>
      </div>
    );
  }
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    margin-bottom: 0;
  }
`;

import React from "react";
import { Layout, Icon, Tooltip, Button } from "antd";
import { connect } from "react-redux";
import { makeApiSelector } from "@36node/redux";
import { get } from "lodash";
import styled from "styled-components";

import Logo from "../../components/logo";
import Nav from "../../components/nav";
import menu from "./menu";
import { auth, core, op } from "../../actions/api";
import { ROOT_NS, LIST_MAX } from "../../config";
import { makeNsTreeSelector, privilegesSelector } from "../../selectors";
import { withSession } from "@36node/redux-session";

const { Sider } = Layout;

/**
 * actions
 */

const logout = auth.session.makeLogout("session");

export const listNs = auth.namespace.makeListNamespaces("namespaces.all", {
  query: {
    limit: LIST_MAX,
    sort: "key",
    filter: {
      id: { $regex: new RegExp(ROOT_NS) }, // 这里hardcode 根路径上没有车和线路
    },
  },
});
export const listLines = core.line.makeListLines("lines", {
  query: {
    limit: LIST_MAX,
    sort: "name",
    filter: {
      ns: { $regex: new RegExp(ROOT_NS) },
    },
  },
});
export const listUsers = auth.user.makeListUsers("users.all", {
  query: {
    limit: LIST_MAX,
    filter: {
      ns: { $regex: new RegExp(ROOT_NS) },
    },
  },
});
export const listProducers = core.producer.makeListProducers("producers", {
  query: {
    filter: {
      ns: { $regex: new RegExp(ROOT_NS) },
    },
  },
});
export const listStages = op.ticket.makeListStages("stages");

export const listNsSelector = makeApiSelector("namespaces.all");
export const nsTreeSelector = makeNsTreeSelector(listNsSelector);
export const listLinesSelector = makeApiSelector("lines");
export const listProducersSelector = makeApiSelector("producers");
export const listStagesSelector = makeApiSelector("stages");

const ExpanedSiderTrigger = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  .ant-btn {
    border: 0;
  }
`;

const StyledSider = styled(Sider)`
  .ant-layout-sider-trigger {
    height: ${props => (props.collapsed ? 72 : 48)}px;
  }

  .ant-btn {
    border: 0;
  }
`;

@connect(state => {
  const privileges = privilegesSelector(state) || [];

  return {
    privileges,
  };
})
@withSession("session")
export default class extends React.Component {
  state = {
    collapsed: true,
  };

  componentDidMount() {
    // main layout 加载的时候 预加载以下数据
    this.props.dispatch(listNs());
    this.props.dispatch(listLines());
    this.props.dispatch(listUsers());
    this.props.dispatch(listProducers());
    this.props.dispatch(listStages());
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  logout = () => {
    this.props.dispatch(
      logout({
        sessionId: get(this.props.session, "result.id"),
      })
    );
  };

  renderSiderTrigger = () => {
    return (
      <ExpanedSiderTrigger
        onClick={e => {
          e.stopPropagation();
        }}
        direction={this.state.collapsed ? "column" : "row"}
      >
        <Tooltip title="登出">
          <Button ghost onClick={this.logout}>
            <Icon type="poweroff" />
          </Button>
        </Tooltip>
        <Button
          ghost
          onClick={() => {
            this.onCollapse(!this.state.collapsed);
          }}
        >
          <Icon type={this.state.collapsed ? "right" : "left"} />
        </Button>
      </ExpanedSiderTrigger>
    );
  };

  render() {
    return (
      <Layout style={{ height: "100vh", overflow: "hidden" }}>
        <StyledSider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          trigger={this.renderSiderTrigger()}
        >
          <Logo collapsed={this.state.collapsed} />
          <Nav
            privileges={this.props.privileges}
            collapsed={this.state.collapsed}
            menu={menu}
          />
        </StyledSider>
        {this.props.children}
      </Layout>
    );
  }
}

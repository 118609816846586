import { createSelector } from "reselect";
import {
  has,
  forIn,
  values,
  cloneDeep,
  take,
  uniq,
  get,
  isEmpty,
} from "lodash";
import moment from "moment";

const getAlertByNs = (alertData, nsData) => {
  const Level3AlertNsCount = alertData.find(
    a => a.name === "Level3AlertNsCount"
  ) || { value: [] };
  let alertNsCount = {};
  Level3AlertNsCount.value.forEach((v, index) => {
    const nsFound = nsData.find(ns => v.ns.indexOf(ns.value) !== -1) || {
      title: "",
    };
    if (nsFound.title) {
      if (!has(alertNsCount, nsFound.title)) {
        alertNsCount[nsFound.title] = {
          count: v.count,
          name: nsFound.title,
          ns: nsFound.key,
        };
      } else {
        alertNsCount[nsFound.title].count += v.count;
      }
    }
  });
  return values(alertNsCount);
};

const completeAlertRateData = data => {
  // 获取数据的月份
  let months = uniq(
    data.reduce((acc, cur) => {
      acc = acc.concat(get(cur, "data", []).map(d => d.date));
      return acc;
    }, [])
  );
  months.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());

  const result = data.map(total => {
    let temp = cloneDeep(total.data);
    months.forEach((date, index) => {
      if (!total.data.find(a => a.date === date))
        temp.splice(index, 0, { date });
    });
    return { ...total, data: temp };
  });

  return result;
};

export const makeAlertAnalysisSelector = (
  alertSelector,
  alertRateSelector,
  nsTreeSelector
) =>
  createSelector(
    alertSelector,
    alertRateSelector,
    nsTreeSelector,
    (alertState, alertRateState, nsTreeState) => {
      const alertResult = alertState.result || [];
      const alertRateResult = alertRateState.result || [];
      const nsTreeResult = nsTreeState.result || [];

      const alertNsCount = getAlertByNs(alertResult, nsTreeResult);

      const Level3AlertRateNs = alertRateResult.find(
        a => a.name === "Level3AlertRateNs"
      ) || { value: [] };
      let alertRateNs = {};
      Level3AlertRateNs.value.forEach(v => {
        const nsFound = nsTreeResult.find(
          ns => v.ns.indexOf(ns.value) !== -1
        ) || {
          title: "",
        };
        if (nsFound.title) {
          if (!has(alertRateNs, nsFound.title)) {
            alertRateNs[nsFound.title] = {
              data: {},
              name: nsFound.title,
            };
          }

          v.data.forEach(value => {
            if (!has(alertRateNs[nsFound.title].data, value.date)) {
              alertRateNs[nsFound.title].data[value.date] = {};
              Object.assign(alertRateNs[nsFound.title].data[value.date], value);
            } else {
              alertRateNs[nsFound.title].data[value.date].alertCount +=
                value.alertCount;
              alertRateNs[nsFound.title].data[value.date].mileage +=
                value.mileage;
              alertRateNs[nsFound.title].data[value.date].alertRate =
                (alertRateNs[nsFound.title].data[value.date].alertCount *
                  10000) /
                alertRateNs[nsFound.title].data[value.date].mileage;
            }
          });
        }
      });
      forIn(alertRateNs, function(value, key) {
        value.data = values(value.data);
      });
      // 月报警率按车型分布 取 最多的10个车型
      // 按总报警数排序
      const level3AlertRateModelBrief = alertRateResult.find(
        a => a.name === "Level3AlertRateModelBrief"
      ) || { value: [] };
      let modelBriefValue = cloneDeep(level3AlertRateModelBrief.value);
      modelBriefValue.sort(
        (a, b) =>
          b.data.reduce((x, y) => x + parseInt(y.alertCount), 0) -
          a.data.reduce((x, y) => x + parseInt(y.alertCount), 0)
      );

      // 对于 月报警率按公司分布 和 月报警率按车型分布 可能存在月份数据为空
      // 例如 2019-11-01 的数据有的存在，有的不存在，生成的图表错位
      const modelBriefResult = completeAlertRateData(take(modelBriefValue, 10));
      const alertRateNsResult = completeAlertRateData(values(alertRateNs));

      return [
        ...alertResult,
        ...alertRateResult,
        { name: "AlertRateNs", value: alertRateNsResult },
        { name: "AlertNsCount", value: alertNsCount },
        { name: "AlertRateModelBrief", value: modelBriefResult },
      ];
    }
  );

export const makeAlertDrillDownSelector = drillDownSelector =>
  createSelector(
    drillDownSelector,
    drillDownState => {
      const drillDownData = drillDownState.result || [];
      let result = [];
      if (!isEmpty(drillDownData)) {
        let temp = cloneDeep(drillDownData);
        temp.sort((a, b) => a.count - b.cout);
        const total = temp.reduce((x, y) => x + y.count, 0);
        result = temp.map((v, index) => ({
          ...v,
          name: v.key,
          rank: index + 1,
          percent: (Number(v.count) * 100) / total,
        }));
      }
      return { ...drillDownState, result };
    }
  );

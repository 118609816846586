import { createSelector } from "reselect";
import moment from "moment";
import { get, set, isEmpty, take, has, values, assign, reverse } from "lodash";

import { getAbsValue } from "../lib";
import i18n from "../i18n";

export const makeScreenHealthyRateSelector = selector =>
  createSelector(
    selector,
    state => {
      const result = state.result || [];
      const healthyRate = result.find(a => a.name === "VehicleHealthyRate") || {
        value: 0,
      };
      return {
        VehicleHealthyRate: healthyRate.value * 100,
      };
    }
  );

export const makeScreenAlertRateByDaySelector = selector =>
  createSelector(
    selector,
    state => {
      const alertRate = state.result || [];
      const alertRateByDay = alertRate.find(
        a => a.name === "Level3AlertRateByDay"
      ) || { value: [] };
      const result = alertRateByDay.value.map(v => {
        if (v.alertRate) {
          const percent = v.alertRate * 100;
          if (percent >= 0.001) return percent.toFixed(3);
        }
        return 0;
      });
      return take(result, 40);
    }
  );

export const makeScreenAlertByModelSelector = selector =>
  createSelector(
    selector,
    state => {
      const alertData = state.result || [];
      let result = alertData
        .filter(data => data && data.name !== "合计")
        .map(data => ({
          name: data.name,
          level1: data.level1AlarmTotal,
          level2: data.level2AlarmTotal,
          level3: data.level3AlarmTotal,
          total:
            data.level1AlarmTotal +
            data.level2AlarmTotal +
            data.level3AlarmTotal,
        }));
      return {
        ...state,
        result: result.sort((a, b) => b.total - a.total),
      };
    }
  );

const sumValues = (obj1 = {}, obj2 = {}, filed) => {
  return Object.keys(obj1).reduce((acc, cur) => {
    set(
      acc,
      [cur, filed],
      get(obj1, [cur, filed], 0) + get(obj2, [cur, filed], 0)
    );
    return acc;
  }, {});
};

/**
 * 递归计算ns下能耗/公里总和
 * @param {*} ns
 */
const sumNsFiled = (data, ns = {}, filed = "consumption") => {
  let ret = {};
  const statOfNs = data[ns.key] || {};

  ret = sumValues(statOfNs, {}, filed);

  if (ns.children) {
    for (const c of ns.children) {
      ret = sumValues(ret, sumNsFiled(data, c, filed), filed);
    }
  }

  return ret;
};

export const makeScreenPowerByNSSelector = (selector, nstreeSelector) =>
  createSelector(
    selector,
    nstreeSelector,
    (state, nsState) => {
      const powerData = state.result || {};
      const nsTree = nsState.result || [];
      const powerDataByNS = powerData.powerStatisticsByNs || {};
      if (nsTree.length && !isEmpty(powerDataByNS)) {
        const rootNs = nsTree[0] || {};

        // 取上个月的数据
        let key = moment()
          .month(moment().month() - 1)
          .startOf("month")
          .format("YYYY-MM-DD");

        let data =
          rootNs.children &&
          rootNs.children.reduce((acc, cur) => {
            const d = sumNsFiled(powerDataByNS, cur);
            const m = sumNsFiled(powerDataByNS, cur, "mileage");

            acc[cur.title] = {
              consumption: d[key].consumption,
              mileage: m[key].mileage,
            };
            return acc;
          }, {});

        return Object.keys(data).map(k => ({
          key: k,
          avgEnergyPer100:
            data[k].consumption &&
            data[k].mileage &&
            ((data[k].consumption * 100) / data[k].mileage).toFixed(0),
        }));
      }
      return [];
    }
  );

export const makeScreenPowerStatSelector = (selector, nstreeSelector) =>
  createSelector(
    selector,
    nstreeSelector,
    (state, nsState) => {
      const statData = state.result || {};
      const nsTree = nsState.result || [];
      let totalResult = {};
      let driverResult = [];
      if (nsTree.length && !isEmpty(statData)) {
        const rootNs = nsTree[0] || {};
        const totalData = statData.total || [];

        totalData.forEach(v => {
          const nsFound = rootNs.children.find(
            ns => v.ns.indexOf(ns.value) !== -1
          ) || {
            title: "",
          };
          if (nsFound.title) {
            if (!has(totalResult, nsFound.title)) {
              totalResult[nsFound.title] = {
                totalMileage: getAbsValue(v.mileage && v.mileage.value),
                totalEnergy: getAbsValue(v.consumption && v.consumption.value),
                key: nsFound.title,
              };
            } else {
              totalResult[nsFound.title].totalMileage += getAbsValue(
                v.mileage && v.mileage.value
              );
              totalResult[nsFound.title].totalEnergy += getAbsValue(
                v.consumption && v.consumption.value
              );
            }
          }
        });
        driverResult = statData.driver.map(v => ({
          ...v,
          ns: (
            rootNs.children.find(ns => v.ns.indexOf(ns.value) !== -1) || {
              title: "",
            }
          ).title,
          value: (v.powerPercent * 100).toFixed(2),
        }));
      }
      return {
        total: values(totalResult),
        driver: driverResult,
      };
    }
  );

/**
 * 漕宝路 监控相关selector
 */
export const makeAlertCountByModelSelector = alertSelector =>
  createSelector(
    alertSelector,
    alertState => {
      const alertResult = alertState.result || [];
      const alertCountByModel = alertResult.find(
        a => a.name === "Level3AlertModelBriefAnalysis"
      ) || { value: [] };
      let result = [];
      alertCountByModel.value.forEach(v => {
        result.push({
          model: v.modelBrief,
          faults:
            v.vehicleCount > 0 ? (v.alertCount * 100) / v.vehicleCount : 0,
        });
      });
      result.sort((a, b) => b.faults - a.faults);
      return take(result, 9);
    }
  );

const TempDiffTypes = {
  lessThan4: "温差 0~4℃",
  between4And8: "温差 4~8℃",
  moreThan8: "温差大于 8℃",
};
/**
 * 漕宝路 空调相关selector
 */
export const makeScreenAirChartSelector = (
  airSelector,
  nstreeSelector,
  weatherSelector
) =>
  createSelector(
    airSelector,
    nstreeSelector,
    weatherSelector,
    (airState, nsState, weatherState) => {
      const airResult = airState.result || [];
      const airDataByCode = airResult.find(a => a.name === "ByCode") || {
        value: [],
      };
      const airDataByCodeRet = { total: 0 };
      airDataByCode.value.forEach(v => {
        Object.keys(v).forEach(r => {
          airDataByCodeRet.total += v[r];
        });
        assign(airDataByCodeRet, v);
      });

      let airDataByNsRet = [];
      const airDataByNsTmp = {};
      const airDataByNs = airResult.find(a => a.name === "byNs") || {
        value: [],
      };
      const nsTree = nsState.result || [];
      if (nsTree.length && !isEmpty(airDataByNs.value)) {
        airDataByNs.value.forEach(v => {
          const nsFound = nsTree.find(ns => v.ns.indexOf(ns.value) !== -1) || {
            title: "",
          };
          if (nsFound.title && v.value && v.value.length) {
            const data = v.value[0];
            if (!has(airDataByNsTmp, nsFound.title)) {
              airDataByNsTmp[nsFound.title] = {
                AIR_SHOULD_OPEN: data.AIR_SHOULD_OPEN || 0,
                AIR_SHOULD_NOT_OPEN: data.AIR_SHOULD_NOT_OPEN || 0,
                AIR_SHOULD_NOT_OPEN_SPECIALCASE:
                  data.AIR_SHOULD_NOT_OPEN_SPECIALCASE || 0,
              };
            } else {
              airDataByNsTmp[nsFound.title].AIR_SHOULD_OPEN +=
                data.AIR_SHOULD_OPEN || 0;
              airDataByNsTmp[nsFound.title].AIR_SHOULD_NOT_OPEN +=
                data.AIR_SHOULD_NOT_OPEN || 0;
              airDataByNsTmp[nsFound.title].AIR_SHOULD_NOT_OPEN_SPECIALCASE +=
                data.AIR_SHOULD_NOT_OPEN_SPECIALCASE || 0;
            }
          }
        });
        airDataByNsRet = Object.keys(airDataByNsTmp).map(k => {
          return {
            name: k,
            data: Object.keys(i18n.AirExceptionType).map(ck => ({
              type: i18n.AirExceptionType[ck],
              value: airDataByNsTmp[k][ck] || 0,
            })),
          };
        });
      }

      const tempDiff = airResult.find(a => a.name === "TempDiff") || {
        value: {},
      };
      const tempDiffRet = Object.keys(TempDiffTypes).map(k => ({
        name: TempDiffTypes[k],
        value: tempDiff.value[k] || 0,
      }));

      // 统计过去24小时车辆
      const weatherResult = weatherState.result || [];
      const openStat = airResult.find(a => a.name === "openStat") || {
        value: [],
      };
      let openStatRet = [],
        weatherRet = [];
      if (weatherResult.length && openStat.value.length) {
        openStatRet = openStat.value.map(v => ({
          date: moment(v.key).format("YYYY-MM-DD"),
          hour: moment(v.key).hour(),
          count: v.count,
        }));
        weatherRet = weatherResult.map(w => w.temperature || 0);
      }

      return {
        ...airState,
        result: {
          airDataByCode: airDataByCodeRet,
          airDataByNs: airDataByNsRet,
          tempDiff: tempDiffRet,
          openStat: openStatRet,
          weatherLast24Hours: reverse(weatherRet),
        },
      };
    }
  );

/**
 * 回访控制slider
 */

import React, { PureComponent } from "react";
import { Slider } from "antd";
import styled from "styled-components";

const Box = styled.div`
  .slider-alert-marker {
    z-index: 10;
    width: 10px;
    height: 10px;
    border-color: #de5667;

    transform: translateY(-2px);
  }

  .slider-alert-marker:hover {
    z-index: 10;
    transform: translate(-2px, -3px);
    width: 15px;
    height: 15px;
    border-color: #de5667;
    box-shadow: 0 0 10px #de5667;
  }

  .slider-alert-label {
    color: white;
    background-color: #de5667;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
  }

  .ant-slider-handle {
    z-index: 20;
  }
`;

export default class TrailSlider extends PureComponent {
  nodes = [];

  renderAlerts = () => {
    this.nodes.forEach(node => node.remove());
    this.nodes = [];

    const { alerts = [], max = 100 } = this.props;
    alerts.forEach(alert => {
      const startPos = Math.floor((alert.start / max) * 100);
      const lastPos = Math.floor((alert.last / max) * 100);

      const marker = document.createElement("span");
      marker.className = "ant-slider-dot slider-alert-marker";
      marker.style.cssText = `left: ${startPos}%;`;
      this.nodes.push(marker);

      const stepArea = this.sliderRef.getElementsByClassName(
        "ant-slider-step"
      )[0];
      stepArea.appendChild(marker);

      // 添加label
      const markArea = this.sliderRef.getElementsByClassName(
        "ant-slider-mark"
      )[0];
      const label = document.createElement("span");
      label.className = "ant-slider-mark-text slider-alert-label";
      label.style.cssText = `transform: translateX(-50%); left: ${startPos}%;`;
      label.innerText = alert.name;
      markArea.appendChild(label);
      this.nodes.push(label);

      // 添加track
      const track = document.createElement("div");
      track.className = "ant-slider-track slider-alert-track";
      track.style.cssText = `left: ${startPos}%; right: auto; width: ${lastPos -
        startPos}%;background-color: #de5667;`;
      this.sliderRef.appendChild(track);
      this.nodes.push(track);
    });
  };

  render() {
    return (
      <Box>
        <Slider
          {...this.props}
          ref={ref => {
            if (ref !== null && ref.rcSlider) {
              this.sliderRef = ref.rcSlider.sliderRef;
              this.renderAlerts();
            }
          }}
        />
      </Box>
    );
  }
}

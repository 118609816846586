import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import styled from "styled-components";
import { isEmpty, get } from "lodash";
import { makeApiSelector } from "@36node/redux";

import { getNsName } from "../../lib";
import { core } from "../../actions/api";
import Modal from "../../components/modal";
import Nav from "../../components/nav";
import Detail from "./detail";
import Alert from "./alert";
import History from "./history";
import Replay from "./replay";
import Trail from "./trail";
import Mileage from "./mileage";
import Soh from "./soh";
import { Privileges } from "../../config/privileges";
import { privilegesSelector } from "../../selectors";
import {
  MENU_VEHICLE_DETAILS,
  MENU_VEHICLE_HISTORY,
  MENU_VEHICLE_REPLAY,
  MENU_VEHICLE_ALERTS,
  MENU_VEHICLE_TRAIL,
  MENU_VEHICLE_MILEAGE,
  MENU_VEHICLE_SOH,
} from "../../config";
import ChargingBow from "./charging-bow";
import { VehicleModelsDataMap } from "../../components/vehicle/columns";

const { Sider, Content } = Layout;

const getVehicle = core.vehicle.makeGetVehicle("vehicle.detail");
const getVehicleSelector = makeApiSelector("vehicle.detail");

const menu = [
  {
    title: "数据明细",
    path: "/details",
    icon: "popup-record",
    color: "#fff",
    key: "details",
    privilege: Privileges.VEHICLE.DETAILS,
    switch: MENU_VEHICLE_DETAILS,
  },
  {
    title: "历史数据",
    path: `/history`,
    icon: "popup-history",
    color: "#fff",
    key: "history",
    privilege: Privileges.VEHICLE.HISTORY,
    switch: MENU_VEHICLE_HISTORY,
  },
  {
    title: "充电弓",
    path: "/charging-bow",
    icon: "popup-gong",
    color: "#fff",
    key: "chargingBow",
    privilege: Privileges.VEHICLE.HISTORY,
    switch: MENU_VEHICLE_HISTORY,
  },
  {
    title: "数据回放",
    path: "/replay",
    icon: "popup-trend",
    color: "#fff",
    key: "replay",
    privilege: Privileges.VEHICLE.REPLAY,
    switch: MENU_VEHICLE_REPLAY,
  },
  {
    title: "报警记录",
    path: `/alerts`,
    icon: "popup-alarm",
    color: "#fff",
    key: "alerts",
    privilege: Privileges.VEHICLE.ALERTS,
    switch: MENU_VEHICLE_ALERTS,
  },
  {
    title: "SOH数据",
    path: "/soh",
    icon: "sub-battery",
    color: "#fff",
    key: "soh",
    privilege: Privileges.VEHICLE.SOH,
    switch: MENU_VEHICLE_SOH,
  },
  {
    title: "轨迹回放",
    path: "/trail",
    icon: "popup-playback",
    color: "#fff",
    key: "trail",
    privilege: Privileges.VEHICLE.TRAIL,
    switch: MENU_VEHICLE_TRAIL,
  },
  {
    title: "里程日历",
    path: "/mileage",
    icon: "popup-calendar",
    color: "#fff",
    key: "mileage",
    privilege: Privileges.VEHICLE.MILEAGE,
    switch: MENU_VEHICLE_MILEAGE,
  },
];

@connect(state => ({
  vehicle: getVehicleSelector(state).result || {},
  privileges: privilegesSelector(state) || [],
}))
class Vehicle extends PureComponent {
  componentDidMount() {
    if (this.vehicleId) {
      this.props.dispatch(getVehicle({ vehicleId: this.vehicleId }));
    }
  }

  get vehicleId() {
    return get(this.props.match, "params.vehicleId");
  }
  get title() {
    const { vehicle } = this.props;
    if (isEmpty(vehicle)) {
      return "";
    }
    return `${vehicle.no} | ${vehicle.plate} | ${vehicle.id}`;
  }
  get subtitle() {
    const { vehicle } = this.props;
    if (isEmpty(vehicle)) {
      return "";
    }
    return `${getNsName(get(vehicle, "ns"))} | ${get(vehicle, "line.name")}`;
  }
  handleCancel = () => {
    const { location, history } = this.props;
    const background = location.state && location.state.background;
    const goBack = location.state && location.state.goBack;

    if (goBack) return history.goBack();

    if (!background) return history.push("/");
    history.push(background);
  };

  render() {
    const { location, match, vehicle, full = false } = this.props;
    const background = location.state && location.state.background;

    const { modelBrief } = vehicle;

    // 只有特定的车型显示充电弓
    const datas = VehicleModelsDataMap[modelBrief] || [];
    let finalMenu = [...menu];
    if (!datas.includes("chargingBow")) {
      finalMenu = finalMenu.filter(m => m.key !== "chargingBow");
    }

    return (
      <Modal
        title={
          <StyledTitle>
            {this.title}
            <small> {this.subtitle} </small>
          </StyledTitle>
        }
        visible={true}
        width={full ? "100%" : 1200}
        style={
          full ? { height: "100vh", position: "static" } : { height: "600px" }
        }
        onCancel={this.handleCancel}
      >
        <Layout>
          <Sider collapsed={true}>
            <Nav
              privileges={this.props.privileges}
              collapsed={true}
              menu={finalMenu}
              prepath={match.url}
            />
          </Sider>
          <Content style={{ height: full ? "100vh" : "670px" }}>
            <Switch>
              <Route path={`${match.path}/details`} component={Detail} />
              <Route path={`${match.path}/history`} component={History} />
              <Route path={`${match.path}/replay`} component={Replay} />
              <Route path={`${match.path}/alerts`} component={Alert} />
              <Route path={`${match.path}/soh`} component={Soh} />
              <Route path={`${match.path}/trail`} component={Trail} />
              <Route path={`${match.path}/mileage`} component={Mileage} />
              <Route
                path={`${match.path}/charging-bow`}
                component={ChargingBow}
              />
              <Redirect
                to={{
                  pathname: `${match.url}/details`,
                  state: { background },
                }}
              />
            </Switch>
          </Content>
        </Layout>
      </Modal>
    );
  }
}

class VehicleSingle extends PureComponent {
  render() {
    return <Vehicle full={true} {...this.props} />;
  }
}

const StyledTitle = styled.h3`
  color: rgba(255, 255, 255, 1);
  small {
    line-height: 17px;
    font-size: 12px;
  }
`;

export default Vehicle;
export { VehicleSingle };

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styled from "styled-components";
import { Row, Col, Form, DatePicker, Select, Icon, Spin } from "antd";
import { get, findIndex } from "lodash";
import moment from "moment";
import { put, select } from "redux-saga/effects";
import {
  makeApiSelector,
  withSaga,
  tapOn,
  tapCronTick,
  makeAssign,
  makeAssignSelector,
  makeToggle,
  makeToggleSelector,
} from "@36node/redux";
import { createSelector } from "reselect";

import { log, op } from "../../actions/api";
import { LOG_VEHICLE_LIST_RECORDS } from "../../actions/types";
import { makeRecordListSelector } from "../../selectors/vehicle";
import TrailMap, { MapState } from "../../components/trail-map";
import Specs from "../../components/specs";
import VehicleDashBoard from "./vehicle-dash-board";
import { getColumn } from "../../components/vehicle/columns";
import TrailSlider from "../../components/trail-slider";

const Option = Select.Option;

const DefaultSpeed = 16;
const DefaultInterval = 250;

const LOCATION_LIMIT = {
  // 中国经度范围：73°33′E至135°05′E；纬度范围：3°51′N至53°33′N
  // 纬度
  lat: {
    max: 60,
    min: 0,
  },
  // 经度
  lng: {
    max: 145,
    min: 65,
  },
};

const columns = [
  {
    title: "",
    key: "other",
    children: [getColumn("at"), getColumn("locString")],
  },
  {
    title: "整车",
    key: "basic",
    children: [
      getColumn("overall.mileage"),
      getColumn("overall.resistance"),
      getColumn("overall.aptv"),
      getColumn("overall.brake"),
    ],
  },
  {
    title: "电池",
    key: "battery",
    children: [
      getColumn("customExt.instantPower"),
      getColumn("overall.current"),
      getColumn("extreme.maxNtc"),
      getColumn("overall.voltage"),
    ],
  },
  {
    title: "空调",
    key: "ac",
    hidden: true,
    children: [
      getColumn("customExt.airMode"),
      getColumn("customExt.insideTemp"),
    ],
  },
];

const listRecords = log.vehicle.makeListRecords("vehicle.trailRecords", {
  vehicleId: "",
  query: {
    limit: 10000,
    sort: "at",
    select: [
      "at",
      "recordedAt",
      "body.location",
      "body.overall",
      "body.extreme",
      "body.customExt",
      "body.motor",
      "geoLocation",
    ],
    filter: {
      _order: "asc",
      at_gt: moment()
        .startOf("day")
        .toISOString(),
      at_lt: moment()
        .endOf("day")
        .toISOString(),
      command: "REALTIME_REPORT",
    },
  },
});

const listAlerts = op.alert.makeListAlerts("vehicle.trail.listAlerts", {
  query: {
    select: ["at", "name"],
  },
});

const trailAssign = makeAssign("vehicle.trailRecords");
const trailToggle = makeToggle("vehicle.trailRecords");

const listRecordsSelector = createSelector(
  makeRecordListSelector(makeApiSelector("vehicle.trailRecords")),
  listState => {
    const { result = [] } = listState;

    return {
      ...listState,
      result: result.filter(r => r.location && r.location.state === 0),
    };
  }
);

const listAlertsSelector = makeApiSelector("vehicle.trail.listAlerts");

const trailAssignSelector = makeAssignSelector("vehicle.trailRecords", {
  step: 0,
  speed: DefaultSpeed,
  running: MapState.Unknown,
});
const trailToggleSelector = makeToggleSelector("vehicle.trailRecords");

const changeAssign = ({ data, step, speed, running }) => {
  return trailAssign({
    step: typeof step === "undefined" ? data.step : step,
    speed: typeof speed === "undefined" ? data.speed : speed,
    running: typeof running === "undefined" ? data.running : running,
  });
};

@connect(state => {
  const { result: records = [] } = listRecordsSelector(state);

  return {
    loading:
      listRecordsSelector(state).loading ||
      listAlertsSelector(state).loading ||
      false,
    records,
    alerts: listAlertsSelector(state).result || [],
    data: trailAssignSelector(state),
    select: trailToggleSelector(state) || false,
  };
})
export class TrailControl extends PureComponent {
  onDateChange = time => {
    if (time) {
      const { vehicleId, data } = this.props;
      this.props.dispatch(
        listRecords({
          vehicleId,
          query: {
            filter: {
              at_gt: time.startOf("day").toISOString(),
              at_lt: time.endOf("day").toISOString(),
            },
          },
        })
      );

      this.props.dispatch(
        listAlerts({
          query: {
            filter: {
              vehicle: vehicleId,
              startedAt_gt: time.startOf("day").toISOString(),
              startedAt_lt: time.endOf("day").toISOString(),
            },
          },
        })
      );
      this.props.dispatch(
        changeAssign({ data, step: 0, running: MapState.Unknown })
      );
    }
  };

  onSpeedChange = val => {
    const { data } = this.props;
    this.props.dispatch(changeAssign({ data, speed: val }));
  };

  startCron = () => {
    const { data, records } = this.props;

    if (data.step >= records.length - 1) {
      this.props.dispatch(
        changeAssign({ data, step: 0, running: MapState.Run })
      );
    } else this.props.dispatch(changeAssign({ data, running: MapState.Run }));

    this.props.dispatch(trailToggle());
  };

  stopCron = () => {
    const { data } = this.props;
    this.props.dispatch(changeAssign({ data, running: MapState.Stop }));
    this.props.dispatch(trailToggle());
  };

  onSliderJump = val => {
    const { data } = this.props;
    this.props.dispatch(changeAssign({ data, step: val }));
  };

  render() {
    const { loading, records, data, select, alerts = [] } = this.props;

    // 确定alerts的postion

    const alertsPos = alerts.map(alert => {
      const start =
        findIndex(records, r => moment(r.at).isAfter(alert.startedAt)) - 1;
      const last =
        findIndex(records, r => moment(r.at).isAfter(alert.lastAt)) - 1;

      return {
        start,
        last,
        name: alert.name,
      };
    });

    return (
      <div className="map-control">
        <Spin spinning={loading}>
          <div className="control-top">
            <Form>
              <Row>
                <Col span={12}>
                  <Form.Item {...formItemLayout} label="选择日期">
                    <DatePicker
                      onChange={this.onDateChange}
                      defaultValue={moment()}
                      disabled={select}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item {...formItemLayout} label="回放速度">
                    <Select
                      onChange={this.onSpeedChange}
                      defaultValue={data.speed}
                      disabled={select}
                    >
                      <Option value={1}>1X</Option>
                      <Option value={2}>2X</Option>
                      <Option value={4}>4X</Option>
                      <Option value={8}>8X</Option>
                      <Option value={16}>16X</Option>
                      <Option value={32}>32X</Option>
                      <Option value={64}>64X</Option>
                      <Option value={128}>128X</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="control-bottom">
            <Row>
              <Col span={3} style={{ textAlign: "center" }}>
                {data.running === MapState.Run ? (
                  <Icon
                    type="pause-circle"
                    theme="filled"
                    className="control-btn"
                    onClick={this.stopCron}
                  />
                ) : (
                  <Icon
                    type="play-circle"
                    theme="filled"
                    className="control-btn"
                    onClick={this.startCron}
                  />
                )}
              </Col>
              <Col span={21}>
                <TrailSlider
                  min={0}
                  max={records.length}
                  value={data.step}
                  tooltipVisible={true}
                  onChange={this.onSliderJump}
                  alerts={alertsPos}
                  tipFormatter={val => {
                    const record = records[val];
                    if (record) return moment(record.at).format("HH:mm:ss");
                  }}
                />
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

@withSaga(
  tapOn(LOG_VEHICLE_LIST_RECORDS.SUCCESS, "vehicle.trailRecords", function*() {
    const data = yield select(trailAssignSelector);
    yield put(changeAssign({ data, running: MapState.Stop }));
  }),
  tapCronTick("vehicle.trailRecords", function*() {})
)
@connect(state => ({
  records: listRecordsSelector(state).result || [],
  data: trailAssignSelector(state),
}))
@withRouter
export default class Trail extends PureComponent {
  timer = null;
  componentDidMount() {
    const { match } = this.props;
    this.props.dispatch(
      listRecords({
        vehicleId: get(match, "params.vehicleId"),
      })
    );

    this.props.dispatch(
      listAlerts({
        query: {
          filter: {
            vehicle: get(match, "params.vehicleId"),
            startedAt_gt: moment()
              .startOf("day")
              .toISOString(),
            startedAt_lt: moment()
              .endOf("day")
              .toISOString(),
          },
        },
      })
    );

    this.runMap();
  }

  componentWillUnmount() {
    const { data } = this.props;

    this.props.dispatch(
      changeAssign({ data, step: 0, running: MapState.Unknown })
    );
    if (this.timer) clearInterval(this.timer);
  }

  runMap = () => {
    this.timer = setInterval(() => {
      const { records, data } = this.props;
      if (data.running !== MapState.Run) return;
      let run = false;

      if (data.step < records.length - 1) {
        let nextStep = data.step + data.speed;
        if (data.step + data.speed > records.length - 1) {
          nextStep = records.length - 1;
        }
        const next = records[nextStep];
        if (next) {
          this.props.dispatch(changeAssign({ data, step: nextStep }));
          run = true;
        }
      }

      if (!run) {
        this.props.dispatch(changeAssign({ data, running: MapState.Stop }));
        this.props.dispatch(trailToggle());
      }
    }, DefaultInterval);
  };

  getPositions = () => {
    const { records } = this.props;
    return records
      .filter(
        d =>
          Boolean(d.geoLocation) &&
          d.geoLocation.lng > LOCATION_LIMIT.lng.min &&
          d.geoLocation.lng < LOCATION_LIMIT.lng.max &&
          d.geoLocation.lat > LOCATION_LIMIT.lat.min &&
          d.geoLocation.lat < LOCATION_LIMIT.lat.max
      )
      .map(d => [d.geoLocation.lng, d.geoLocation.lat]);
  };

  render() {
    const { records, data, match } = this.props;
    const val = records[data.step] || {};

    return (
      <Container>
        <MapBox>
          <TrailMap
            positions={this.getPositions()}
            curPos={data.step}
            running={data.running}
          />
          <TrailControl vehicleId={get(match, "params.vehicleId")} />
        </MapBox>
        <InfoBox>
          <VehicleDashBoard record={val} />
          <Specs columns={columns} data={val} span={12} />
        </InfoBox>
      </Container>
    );
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: white;
`;

const MapBox = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  .map-control {
    position: absolute;
    width: 92%;
    padding: 20px;
    bottom: 24px;
    left: 4%;
    background: white;
    box-shadow: 3px 3px 10px #cacaca, -3px -3px 10px #cacaca;

    .control-btn {
      color: #1da57a;
      font-size: 38px;
      cursor: pointer;
    }
  }
`;

const InfoBox = styled.div`
  width: 380px;
  height: 100%;
  overflow: hidden;
  padding: 12px;
`;

import React from "react";

import { takeEvery, select, call } from "redux-saga/effects";
import { isEmpty } from "lodash";
import { OP_ALERT_LIST_ALERTS } from "../actions/types";
import { notification, Button } from "antd";
import IconFont from "../components/iconfont";
import { ymdhms } from "../lib";
import { makeApiSelector, makeAssign } from "@36node/redux";
import AlertAudioActions from "../actions/alert-audio";
import { dispatch } from "../index";
import { ALERT_NOTIFICATION_DURATION_S } from "../config";

let notified; // 已通知的报警

const alertsSelectors = makeApiSelector("alerts.level3");
const selectedVehicleAssign = makeAssign("monitor.selectedVehicle", "");
const alertTableVisible = makeAssign("alerts.tableVisible");
const alertTableExpandKey = makeAssign("alerts.tableExpandKey");

function notifyAlert(alert) {
  notification.warn({
    key: alert.id,
    className: "alert-level3-warn",
    placement: "bottomRight",
    duration: ALERT_NOTIFICATION_DURATION_S,
    message: "发生了新的三级报警",
    icon: <IconFont type="icon-alarm" />,
    description: `车辆${alert.vehicleNo}于${ymdhms(alert.lastAt)}发生了${
      alert.name
    }报警，请及时处置！`,
    btn: (
      <Button
        onClick={() => {
          dispatch(AlertAudioActions.mute());
          dispatch(selectedVehicleAssign(alert.vehicle));
          dispatch(alertTableVisible(true));
          dispatch(alertTableExpandKey(alert.id));
          notification.close(alert.id);
        }}
        type="default"
        size="small"
      >
        立即处置
      </Button>
    ),
  });
}

function* onListAlertSuccess(action) {
  const { key } = action;

  if (key !== "alerts.level3") return;

  const { result: alerts = [] } = yield select(alertsSelectors);

  // 首次加载, 默认弹出一个最新的报警
  if (notified === undefined) {
    if (!isEmpty(alerts)) {
      yield call(notifyAlert, alerts[0]);
    }

    notified = alerts.map(a => a.id);
  } else {
    // 非首次加载的时候，判断有没有出现最新的报警，如果有则弹出
    const newAlerts = alerts.filter(a => !notified.includes(a.id));

    if (!isEmpty(newAlerts)) {
      // 发出报警
      for (const alert of newAlerts) {
        yield call(notifyAlert, alert);
        notified.push(alert.id);
      }
    }
  }
}

/**
 * 监听报警变化，如果出现新报警则弹出报警
 */
export default function* watchAlertNotification() {
  yield takeEvery(OP_ALERT_LIST_ALERTS.SUCCESS, onListAlertSuccess);
}

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom";
import { Modal, Table, Progress, Spin } from "antd";
import { get } from "lodash";
import { makeApiSelector } from "@36node/redux";

import { ROOT_NS } from "../../config";
import i18n from "../../i18n";
import { chart } from "../../actions/api";
import { makeAlertDrillDownSelector } from "../../selectors";

const listAlertDrillDown = chart.analysis.makeListAlertDrillDown(
  "analysis.alert.drillDown",
  {
    query: {
      filter: {
        ns: { $regex: new RegExp(ROOT_NS) },
      },
    },
  }
);

const drillDownSelector = makeAlertDrillDownSelector(
  makeApiSelector("analysis.alert.drillDown")
);

export function AlertAnalysisAction({ group = "", type = "" }) {
  const location = useLocation();

  return (
    <Link
      to={{
        pathname: `/g/alert/${group}`,
        state: { background: location, type },
      }}
    >
      {type}
    </Link>
  );
}

const getColumns = group => {
  const startCol = {
    title: "排名",
    dataIndex: "rank",
    key: "rank",
    width: 80,
  };

  const endCol = {
    title: "占比",
    dataIndex: "count",
    key: "count",
    render: (val, record) => {
      return (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <span style={{ width: 60 }}>{`${record.percent.toFixed(2)}%`}</span>
          <span style={{ marginLeft: 16, width: 60 }}>
            <Progress
              percent={record.percent}
              showInfo={false}
              strokeWidth={15}
            />
          </span>
          <span style={{ marginLeft: 16 }}>{val}</span>
        </div>
      );
    },
  };

  let midCol = {
    title: "车型名称",
    dataIndex: "name",
    key: "name",
    width: 200,
  };

  if (group === "vehicleModelBrief" || group === "level") {
    // 显示的是报警内容
    midCol.title = "报警名称";
  }

  return [startCol, midCol, endCol];
};

const getTitle = val => `近30天${val}三级报警分布`;
const getOtherTitle = val => `近30天${val}分布`;

@withRouter
@connect(state => ({
  drillDownData: drillDownSelector(state).result || [],
  loading: drillDownSelector(state).loading || false,
}))
export default class extends PureComponent {
  get drillDownGroup() {
    return get(this.props.match, "params.group");
  }

  get drillDownType() {
    return get(this.props.location, "state.type");
  }

  get drillDownTitle() {
    if (this.drillDownGroup === "ns")
      return getTitle(get(this.props.location, "state.name"));
    else if (this.drillDownGroup === "level")
      return getOtherTitle(i18n.AlertLevel[this.drillDownType]);

    return getTitle(this.drillDownType);
  }

  componentDidMount() {
    if (this.drillDownGroup && this.drillDownType) {
      this.props.dispatch(
        listAlertDrillDown({
          query: {
            filter: {
              group: this.drillDownGroup,
              type: this.drillDownType,
            },
          },
        })
      );
    }
  }

  handleCancel = () => {
    const { location, history } = this.props;
    const background = location.state && location.state.background;
    const goBack = location.state && location.state.goBack;

    if (goBack) return history.goBack();

    if (!background) return history.push("/");
    history.push(background);
  };

  render() {
    const { loading, drillDownData } = this.props;
    return (
      <Modal
        title={this.drillDownTitle}
        width={600}
        visible={true}
        onCancel={this.handleCancel}
        footer={null}
      >
        {loading && <Spin />}
        {!loading && (
          <Table
            dataSource={drillDownData}
            columns={getColumns(this.drillDownGroup)}
            pagination={false}
            scroll={{ y: 500 }}
          />
        )}
      </Modal>
    );
  }
}

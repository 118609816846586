import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { get } from "lodash";
import { makeApiSelector } from "@36node/redux";
import { Spin, Empty, Tabs } from "antd";

import { core } from "../../actions/api";
import { warningSchema } from "../../schemas";

const TabPane = Tabs.TabPane;

const Box = styled.div`
  padding: 12px;
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
    }
  }
`;

/**
 * actions
 */
const listSohs = core.warning.makeListWarnings("vehicle.soh", {
  query: {
    filter: {
      type: "容量",
    },
    select: ["abnormalValues", "vehicleMileage", "warningAt"],
  },
});

/**
 * selectors
 */

const getVehicleSelector = makeApiSelector("vehicle.detail");
const sohSelector = makeApiSelector("vehicle.soh", {
  schema: [warningSchema],
});

@withRouter
@connect(state => {
  return {
    vehicle: getVehicleSelector(state).result || {},
    loading: sohSelector(state).loading,
    sohs: sohSelector(state).result || [],
  };
})
export default class SohDetail extends React.PureComponent {
  get vehicleId() {
    return get(this.props.match, "params.vehicleId");
  }

  componentDidMount() {
    this.props.dispatch(
      listSohs({
        query: {
          filter: {
            vehicle: this.vehicleId,
          },
        },
      })
    );
  }

  /**
   * render year chart
   */
  renderYearChart() {
    const { vehicle = {}, sohs } = this.props;

    const { platedAt } = vehicle;

    const tData = sohs.map(s => {
      return [
        moment(s.warningAt).diff(moment(platedAt), "month") / 12,
        (s.abnormalValues * 100).toFixed(2),
      ];
    });

    const option = {
      xAxis: {
        name: "出厂年限",
        interval: 1,
      },
      yAxis: {
        name: "SOH值(%)",
        min: 75,
        max: 100,
      },
      tooltip: {
        formatter: obj => {
          const { value } = obj;
          const d = parseFloat(value[0]);
          const year = parseInt(d, 10);
          return `出厂年限: ${year}年${parseInt(
            (d - year) * 12,
            10
          )}个月<br/>SOH值：${value[1]}%`;
        },
      },
      series: [
        {
          symbolSize: 15,
          data: tData,
          type: "scatter",
        },
      ],
    };

    return <ReactEcharts option={option} style={{ height: 500 }} />;
  }

  /**
   * render mileage chart
   */
  renderMileageChart() {
    const { sohs } = this.props;

    const tData = sohs.map(s => {
      return [
        Number(s.vehicleMileage || 0) / 10000,
        (s.abnormalValues * 100).toFixed(2),
      ];
    });

    const option = {
      xAxis: {
        name: "万公里",
      },
      yAxis: {
        name: "SOH值(%)",
        min: 75,
        max: 100,
      },
      tooltip: {
        formatter: obj => {
          const { value } = obj;
          return `里程: ${parseFloat(value[0])}万公里<br/>SOH值：${value[1]}%`;
        },
      },
      series: [
        {
          symbolSize: 15,
          data: tData,
          type: "scatter",
        },
      ],
    };

    return <ReactEcharts option={option} style={{ height: 500 }} />;
  }

  render() {
    const { sohs, loading } = this.props;

    return (
      <Box>
        <Spin spinning={loading}>
          {sohs.length === 0 && (
            <Empty
              style={{ paddingTop: 160 }}
              description="该车辆暂无有效的SOH数据"
            />
          )}

          {sohs.length > 0 && (
            <Tabs defaultActiveKey="year">
              <TabPane tab="按年限" key="year">
                {this.renderYearChart()}
              </TabPane>
              <TabPane tab="按里程" key="mileage">
                {this.renderMileageChart()}
              </TabPane>
            </Tabs>
          )}
        </Spin>
      </Box>
    );
  }
}

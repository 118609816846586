import { without } from "lodash";
import { UserRoles } from "../constants";

/**
 * privilege config
 */

export const Privileges = {
  OVERVIEW: "OVERVIEW", // 预览
  MONITOR: "MONITOR", // 实时监控
  PILE: {
    MONITOR: "PILE.MONITOR", // 实时充电监控
    HISTORY: "PILE.HISTORY", // 充电历史
    HABIT: "PILE.HABIT", // 充电习惯
    SUMMARY: "PILE.SUMMARY", // 充电统计
  },
  OP: {
    TICKET: "OP.TICKET", // 工单处置
    ALERT: "OP.ALERT", // 报警历史
    REPORT: "OP.REPORT", // 常规报表
    ANALYSIS: "OP.ANALYSIS", // 数据分析
  }, // 报警处置
  EXCEPTION: {
    BATTERY: "EXCEPTION.BATTERY", // 电池异常
    INSULATION: "EXCEPTION.INSULATION", // 绝缘异常
    TYRE: "EXCEPTION.TYRE", // 轮胎异常
    AIR: "EXCEPTION.AIR", // 空调异常
    DCDC: "EXCEPTION.DCDC", // DCDC异常
  },
  BATTERY: {
    QSOH: "BATTERY.QSOH", // soh
    SOH: "BATTERY.SOH", // soh
    WARNING: "BATTERY.WARNING", // 电池预警
  },
  CHARGING: "CHARGING", // 充电管理
  SAFE: "SAFE", // 主动安全
  ENERGY: "ENERGY", // 能耗管理
  VEHICLE: {
    DETAILS: "VEHICLE.DETAILS", // 数据明细
    HISTORY: "VEHICLE.HISTORY", // 历史数据
    REPLAY: "VEHICLE.REPLAY", // 数据回放
    ALERTS: "VEHICLE.ALERTS", // 报警记录
    MAINTENANCE: "VEHICLE.MAINTENANCE", // 维保记录
    SOH: "VEHICLE.SOH", // SOH数据
    TRAIL: "VEHICLE.TRAIL", // 轨迹回放
    MILEAGE: "VEHICLE.MILEAGE", // 里程日历
  }, // 单车详情
  SETTINGS: {
    ARCHIVE: "SETTINGS.ARCHIVE", // 车辆档案
    BANCI_ARCHIVE_UPDATE: "BANCI_ARCHIVE_UPDATE", // 路单车辆档案更新
    TERMINAL: "SETTINGS.TERMINAL", // 终端管理
    BANCI: "SETTINGS.BANCI", // 路单查询
    USER: "SETTINGS.USER", // 用户管理
    APP: "SETTINGS.APP", // APP设置
    PILE: "SETTINGS.PILE", // 充电设置
  },
};

export const flattenPrivilege = (privileges = {}) => {
  const keys = Object.keys(privileges);
  const ret = [];

  for (const key of keys) {
    if (typeof privileges[key] === "string") {
      ret.push(privileges[key]);
    } else {
      ret.push(...flattenPrivilege(privileges[key]));
    }
  }

  return ret;
};

export const AllPrivileges = flattenPrivilege(Privileges);

/**
 * 角色权限
 */
export const RolePrivilege = {
  [UserRoles.ADMIN]: AllPrivileges, // 管理员
  [UserRoles.CATL_MONITOR]: without(AllPrivileges, Privileges.SETTINGS.USER), // CALT 监控人员
  // 整车厂监控人员
  [UserRoles.PRODUCER_MONITOR]: without(
    AllPrivileges,
    Privileges.OP.REPORT, // 不开放的 privilege
    Privileges.OP.ANALYSIS,
    Privileges.VEHICLE.SOH,
    Privileges.VEHICLE.MAINTENANCE,
    Privileges.BATTERY.QSOH,
    Privileges.BATTERY.SOH,
    Privileges.BATTERY.WARNING,
    Privileges.CHARGING,
    Privileges.SAFE,
    Privileges.ENERGY,
    Privileges.SETTINGS.USER,
    Privileges.OVERVIEW
  ),
  // 车队监控人员
  [UserRoles.FLEET_MONITOR]: without(
    AllPrivileges,
    Privileges.OP.ANALYSIS,
    Privileges.VEHICLE.SOH,
    Privileges.VEHICLE.MAINTENANCE,
    Privileges.BATTERY.QSOH,
    Privileges.BATTERY.SOH,
    Privileges.BATTERY.WARNING,
    Privileges.CHARGING,
    Privileges.SETTINGS.USER,
    Privileges.OVERVIEW
  ),
  // 运营公司监控人员
  [UserRoles.COMPANY_MONITOR]: without(
    AllPrivileges,
    Privileges.OP.REPORT,
    Privileges.OP.ANALYSIS,
    Privileges.VEHICLE.SOH,
    Privileges.VEHICLE.MAINTENANCE,
    Privileges.BATTERY.QSOH,
    Privileges.BATTERY.SOH,
    Privileges.BATTERY.WARNING,
    Privileges.CHARGING,
    Privileges.SETTINGS.USER,
    Privileges.OVERVIEW
  ),
  // 车间监控人员
  [UserRoles.REPAIR_OP]: without(
    AllPrivileges,
    Privileges.OP.REPORT, // 不开放的 privilege
    Privileges.OP.ANALYSIS,
    Privileges.VEHICLE.SOH,
    Privileges.VEHICLE.MAINTENANCE,
    Privileges.BATTERY.QSOH,
    Privileges.BATTERY.SOH,
    Privileges.BATTERY.WARNING,
    Privileges.CHARGING,
    Privileges.PILE.HABIT,
    Privileges.PILE.HISTORY,
    Privileges.PILE.SUMMARY,
    Privileges.PILE.MONITOR,
    Privileges.SAFE,
    Privileges.ENERGY,
    Privileges.SETTINGS.USER,
    Privileges.OVERVIEW
  ),
  // 机务保障中心监控人员
  [UserRoles.MAINTENANCE_MONITOR]: without(
    AllPrivileges,
    Privileges.VEHICLE.MAINTENANCE
  ),
  // 终端运维
  [UserRoles.TERMINAL_OP]: without(
    AllPrivileges,
    Privileges.OP.TICKET,
    Privileges.OP.REPORT,
    Privileges.OP.ANALYSIS,
    Privileges.EXCEPTION.BATTERY,
    Privileges.EXCEPTION.TYRE,
    Privileges.EXCEPTION.INSULATION,
    Privileges.EXCEPTION.DCDC,
    Privileges.VEHICLE.SOH,
    Privileges.VEHICLE.MAINTENANCE,
    Privileges.BATTERY.QSOH,
    Privileges.BATTERY.SOH,
    Privileges.BATTERY.WARNING,
    Privileges.CHARGING,
    Privileges.SAFE,
    Privileges.ENERGY,
    Privileges.SETTINGS.USER,
    Privileges.PILE.HABIT,
    Privileges.PILE.MONITOR,
    Privileges.PILE.HISTORY
  ),
};

import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { makeApiSelector } from "@36node/redux";
import ReactEcharts from "echarts-for-react";
import { Card, Input, Button, Table, Form } from "antd";
import { max, sumBy } from "lodash";
import { createForm } from "@36node/redux-antd";

import { pile } from "../../actions/api";
import { stationSchema } from "../../schemas";
import { getPileStatusCount, getPileStatusUpdateAt, ymdhms } from "../../lib";
import { PileStatus } from "../../constants";
import withTitle from "../../components/withTitle";
import { withTable } from "../../components/withTable";
import { NavLink, Link } from "react-router-dom";

/**
 * actions
 */
const listStationStats = pile.station.makeListStation("stations.stats", {
  query: {
    limit: 1000,
    offset: 0,
    populate: "statistics",
  },
});
const listStations = pile.station.makeListStation("stations.all");

/**
 * selectors
 */

const selectStationStats = makeApiSelector("stations.stats", {
  schema: [stationSchema],
});

@createForm("stations.all")
class SearchForm extends PureComponent {
  static defaultProps = {
    onFetch: () => {},
  };

  handleSubmit = e => {
    e && e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { name } = values;

        const filter = {};
        if (name) {
          filter.name = { $regex: new RegExp(name) };
        }

        if (this.props.onFetch) {
          this.props.onFetch({ query: { filter } });
        }
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form
        layout="inline"
        style={{ marginBottom: 12 }}
        onSubmit={this.handleSubmit}
      >
        <Form.Item label={"充电站名称"}>
          {getFieldDecorator("name", {})(
            <Input placeholder="请输入充电站名称" allowClear />
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const columns = [
  {
    title: "充电站",
    dataIndex: "name",
    key: "name",
    render: (val, record) => {
      return (
        <Link
          to={{
            pathname: `/pile/monitor/piles/${record.id}`,
            state: {
              subPage: true,
              headerTitle: "充电桩监控",
            },
          }}
        >
          {val}
        </Link>
      );
    },
  },
  {
    title: "充电桩总数",
    dataIndex: "statistics",
    key: "totalPiles",
    render: (val = []) => {
      return sumBy(val, "count");
    },
  },
  {
    title: "充电中",
    dataIndex: "statistics",
    key: "charging",
    render: (val = []) => {
      return getPileStatusCount(val, PileStatus.TAKEN_CHARGING);
    },
  },
  {
    title: "占用(未充电)",
    dataIndex: "statistics",
    key: "notCharging",
    render: (val = []) => {
      return getPileStatusCount(val, PileStatus.TAKEN_NOT_CHARGING);
    },
  },
  {
    title: "占用(预约锁定)",
    dataIndex: "statistics",
    key: "appointment",
    render: (val = []) => {
      return getPileStatusCount(val, PileStatus.TAKEN_APPOINTMENT);
    },
  },
  {
    title: "空闲",
    dataIndex: "statistics",
    key: "free",
    render: (val = []) => {
      return getPileStatusCount(val, PileStatus.FREE);
    },
  },
  {
    title: "离网",
    dataIndex: "statistics",
    key: "offline",
    render: (val = []) => {
      return getPileStatusCount(val, PileStatus.OFFLINE);
    },
  },
  {
    title: <span style={{ color: "red" }}>故障中</span>,
    dataIndex: "statistics",
    key: "fault",
    render: (val = []) => {
      return (
        <span style={{ color: "red" }}>
          {getPileStatusCount(val, PileStatus.FAULT)}
        </span>
      );
    },
  },
  {
    title: "操作",
    key: "operator",
    render: record => {
      return <NavLink to={`/pile/monitor/piles/${record.id}`}>查看</NavLink>;
    },
  },
];

@withTable("stations.all", {
  SearchForm,
  searchFormInner: true,
  title: "充电站统计",
  columns,
  list: listStations,
  exporter: false,
})
class StationsTable extends PureComponent {
  render() {
    return <Table {...this.props} />;
  }
}

@withTitle("充电站实时监控")
@connect(state => {
  const chartLoading = selectStationStats(state).loading;
  const stats = selectStationStats(state).result;

  return {
    chartLoading,
    stats,
  };
})
export default class Monitor extends PureComponent {
  componentDidMount() {
    this.props.dispatch(listStationStats());
  }

  renderMonitorChart = () => {
    const { stats, chartLoading } = this.props;

    const updatedAt = max(stats.map(s => getPileStatusUpdateAt(s.statistics)));

    return (
      <Card
        title={
          <div>
            <span>充电站实时监控</span>
            {updatedAt && (
              <span style={{ marginLeft: 12, color: "#ccc", fontSize: 14 }}>
                更新时间: {ymdhms(updatedAt)}
              </span>
            )}
          </div>
        }
        style={{ marginBottom: 24 }}
      >
        <ReactEcharts
          showLoading={chartLoading}
          style={{ height: 250 }}
          option={{
            color: ["#59b6ef"],
            legend: {
              show: true,
            },
            grid: {
              height: 200,
              left: "3%",
              right: "6%",
              bottom: "5%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: stats.map(s => s.name),
              axisTick: {
                alignWithLabel: true,
              },
            },
            yAxis: {
              type: "value",
              name: "充电桩车辆数",
              min: 0,
              minInterval: 5,
              splitLine: {
                show: false,
              },
            },
            tooltip: {
              show: true,
            },
            series: [
              {
                name: "充电中车辆数",
                type: "bar",
                barWidth: 60,
                data: stats.map(s =>
                  getPileStatusCount(s.statistics, PileStatus.TAKEN_CHARGING)
                ),
              },
            ],
          }}
        />
      </Card>
    );
  };

  render() {
    return (
      <Fragment>
        {this.renderMonitorChart()}
        <StationsTable />
      </Fragment>
    );
  }
}

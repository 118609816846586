import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { put } from "redux-saga/effects";
import styled from "styled-components";
import { Table, Form, Card, Col, Row, Button, Select } from "antd";
import { withSaga, tapOnLatest, makeApiSelector } from "@36node/redux";
import { createForm } from "@36node/redux-antd";

import { pile } from "../../../actions/api";
import {
  makeSgccXlsxSelector,
  makeSgccRecordsSelector,
} from "../../../selectors";
import { withTable } from "../../../components/withTable";
import { PILE_SGCC_CREATE_RECORD } from "../../../actions/types";
import i18n from "../../../i18n";
import { Vehicle, DateRange, StationSelect } from "../../../components/fields";
import { ROOT_NS } from "../../../config";

const { Option } = Select;

/**
 * constants
 */
const pageKey = "chargingSgcc.all";

const columns = [
  {
    title: "订单编号",
    dataIndex: "billCode",
    key: "billCode",
  },
  {
    title: "自编号",
    dataIndex: "vehicleNo",
    key: "vehicleNo",
  },
  {
    title: "部门",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "充电站",
    dataIndex: "station",
    key: "station",
  },
  {
    title: "充电开始时间",
    dataIndex: "startAt",
    key: "startAt",
    sorter: true,
  },
  {
    title: "充电结束时间",
    dataIndex: "endAt",
    key: "endAt",
    sorter: true,
    defaultSortOrder: "descend",
  },
  {
    title: "充电电量",
    dataIndex: "power",
    key: "power",
  },
  {
    title: "开始SOC",
    dataIndex: "startSOC",
    key: "startSOC",
  },
  {
    title: "结束SOC",
    dataIndex: "endSOC",
    key: "endSOC",
  },
  {
    title: "SOC电量",
    dataIndex: "SOCpower",
    key: "SOCpower",
  },
  {
    title: "差值",
    dataIndex: "delta",
    key: "delta",
  },
  {
    title: "月份",
    dataIndex: "month",
    key: "month",
  },
  {
    title: "充电时段",
    dataIndex: "period",
    key: "period",
  },
  {
    title: "金额",
    dataIndex: "price",
    key: "price",
  },
];

/**
 * actions
 */

const listSgccRecords = pile.sgcc.makeListSgccRecords(pageKey, {
  query: {
    sort: "-endAt",
  },
});
const createSgccRecord = pile.sgcc.makeCreateSgccRecord(
  "sgcc.create",
  {},
  {
    parallel: true,
    dumb: true,
  }
);
const listStations = pile.sgcc.makeListSgccFields("sgcc.fields.station", {
  query: {
    name: "station",
  },
});
const listDepartments = pile.sgcc.makeListSgccFields("sgcc.fields.department", {
  query: {
    name: "department",
  },
});
const getPrice = pile.price.makeGetPrice("sgcc.price.get", {
  query: {
    filter: {
      rootNs: ROOT_NS,
    },
  },
});

/**
 * selectors
 */
const listStationsSelector = makeApiSelector("sgcc.fields.station");
const listDepartmentsSelector = makeApiSelector("sgcc.fields.department");
const makeXlsxSelector = makeSgccXlsxSelector(
  makeApiSelector("sgcc.price.get")
);

/**
 * styles
 */

const StyledFilterForm = styled(Form)`
  padding-right: 12px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

const formItemLayout = {
  labelCol: { xxl: 6, xl: 6, lg: 6, md: 8, sm: 10 },
};

/**
 * components
 */

@createForm(pageKey)
@connect((state, props) => ({
  stations: listStationsSelector(state).result || [],
  departments: listDepartmentsSelector(state).result || [],
}))
class SearchForm extends PureComponent {
  componentWillMount() {
    this.props.dispatch(listStations());
    this.props.dispatch(listDepartments());
    this.props.dispatch(getPrice());
  }

  handleSubmit = e => {
    e && e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const filter = { ...values };

        if (filter.startAt) {
          filter.startAt = {
            $gte: filter.startAt[0].toISOString(),
            $lte: filter.startAt[1].toISOString(),
          };
        }

        if (this.props.onFetch) {
          this.props.onFetch({ query: { filter } });
        }
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.handleSubmit();
  };

  render() {
    const { form, departments, stations } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Card style={{ marginBottom: 8 }}>
        <StyledFilterForm {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col span={8}>
              <StationSelect
                name="station"
                dataSource={stations.map(x => ({
                  id: x,
                  name: x,
                }))}
                form={form}
              />
            </Col>
            <Col span={8}>
              <Form.Item label="部门">
                {getFieldDecorator("department")(
                  <Select placeholder="请选择">
                    {departments.map(x => (
                      <Option value={x} key={x}>
                        {x}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="充电时段">
                {getFieldDecorator("period")(
                  <Select placeholder="请选择">
                    {Object.keys(i18n.ChargePeriod).map(key => (
                      <Option value={key} key={key}>
                        {i18n.ChargePeriod[key]}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Vehicle.No form={form} name="vehicleNo" />
            </Col>
            <Col span={8}>
              <DateRange
                name="startAt"
                label="充电开始时间"
                form={form}
                placeholder={["开始时间", "结束时间"]}
                showTime={true}
                allowClear={false}
                format="YYYY-MM-DD HH:mm"
              />
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                筛选
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                重置
              </Button>
            </Col>
          </Row>
        </StyledFilterForm>
      </Card>
    );
  }
}

@withSaga(
  tapOnLatest(PILE_SGCC_CREATE_RECORD.SUCCESS, "sgcc.create", function*(
    action
  ) {
    yield put(listSgccRecords());
    yield put(listStations());
    yield put(listDepartments());
  })
)
@withTable(pageKey, {
  SearchForm,
  title: "充电历史记录列表",
  columns,
  list: listSgccRecords,
  importer: true,
  makeListSelector: makeSgccRecordsSelector,
  makeXlsxSelector,
  create: createSgccRecord,
})
export default class History extends PureComponent {
  render() {
    return <Table {...this.props} />;
  }
}

/**
 * 报警铃声
 */

import React from "react";
import { takeEvery, all, call } from "redux-saga/effects";
import {
  ALERT_AUDIO_PLAY,
  OP_ALERT_LIST_ALERTS,
  ALERT_AUDIO_MUTE,
  ALERT_AUDIO_UNMUTE,
} from "../actions/types";
import { Modal } from "antd";
import IconFont from "../components/iconfont";

const audio = new Audio("/audio/alarm.wav");
audio.loop = true;

let muted = false; // 是否处于报警处理页面，是的话应该处于静默状态

function play() {
  audio.play().catch(err => {
    console.log(err);
    Modal.info({
      title: "发生三级报警",
      content: "请尽快处理",
      okText: "确定",
      onOk: () => {
        play();
      },
      icon: (
        <IconFont style={{ color: "red", fontSize: 40 }} type="icon-alarm" />
      ),
    });
  });
}

/**
 * 静音一段时间
 */
export function muteForAWhile() {
  audio.pause();

  setTimeout(() => {
    play();
  }, 3000);
}

function* onListAlertLevel3Success(action) {
  const { key, payload = {} } = action;
  const { result = [] } = payload;

  if (key === "alerts.level3" && result.length > 0 && !muted) {
    // 仅在非报警处理页面下才会播放警报声,否则处于静默状态
    try {
      yield call(play);
    } catch (error) {
      console.log(error);
    }
  }
}

function* mute() {
  yield audio.pause();
  muted = true;
}

function unmute() {
  muted = false;
}

export default function* watchAlertVoice() {
  yield all([
    takeEvery(ALERT_AUDIO_MUTE, mute), // 静默
    takeEvery(ALERT_AUDIO_UNMUTE, unmute), // 取消静默
    takeEvery(ALERT_AUDIO_PLAY, play), // 开启
    takeEvery(OP_ALERT_LIST_ALERTS.SUCCESS, onListAlertLevel3Success),
  ]);
}

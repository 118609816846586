import { createSelector } from "reselect";
import { has, values, sortby, isEmpty, find } from "lodash";
import moment from "moment";

import { ChargePeriod } from "../constants";
import { ymdhm, formatValue, isInPeriod } from "../lib";
import {
  PILE_TIME_SUMMER_PEAK,
  PILE_TIME_SUMMER_VALLEY,
  PILE_TIME_OTHER_PEAK,
  PILE_TIME_OTHER_VALLEY,
} from "../config";
import i18n from "../i18n";

export const makeListChargeorderStatSelector = (statSelector, nsTreeSelector) =>
  createSelector(
    statSelector,
    nsTreeSelector,
    (state, nsTreeState) => {
      const records = state.result || [];
      const nsTreeResult = nsTreeState.result || [];
      let result = {};
      records.forEach(r => {
        const nsFound = nsTreeResult.find(
          ns => r.key.indexOf(ns.value) !== -1
        ) || {
          title: "",
        };
        if (nsFound.title) {
          if (!has(result, nsFound.title)) {
            result[nsFound.title] = {
              name: nsFound.title,
              value: r.total,
              key: nsFound.key,
              peak: r.peak,
              valley: r.valley,
              flat: r.flat,
              sharp: r.sharp,
            };
          } else {
            result[nsFound.title].value += r.total;
            result[nsFound.title].peak += r.peak;
            result[nsFound.title].valley += r.valley;
            result[nsFound.title].flat += r.flat;
            result[nsFound.title].sharp += r.sharp;
          }
        }
      });
      let intervalResult = [];
      if (!isEmpty(result))
        intervalResult = Object.keys(result).map(key => {
          const r = result[key];
          return {
            key: r.key,
            name: key,
            value: [
              r.value ? ((r.peak * 100) / r.value).toFixed(0) : 0,
              r.value ? ((r.valley * 100) / r.value).toFixed(0) : 0,
              r.value ? ((r.flat * 100) / r.value).toFixed(0) : 0,
              r.value ? ((r.sharp * 100) / r.value).toFixed(0) : 0,
            ],
          };
        });
      return {
        ...state,
        result: {
          total: sortby(values(result), "key"),
          interval: sortby(intervalResult, "key"),
        },
      };
    }
  );

// 根据 夏季和非夏季的峰谷平价格 计算时间段和电价
// 夏季 7月~9月
export const countPilePeriodAndPrice = (record = {}, price = {}) => {
  let ret = { period: ChargePeriod.normal, price: 0 };
  if (record.startAt && record.power && !isEmpty(price)) {
    const time = moment(record.startAt);
    const month = time.month() + 1; // month 从0开始
    const hour = time.hour();
    if (month >= 7 && month <= 9) {
      // 夏季
      if (isInPeriod(hour, PILE_TIME_SUMMER_PEAK)) {
        ret.period = ChargePeriod.height;
        ret.price = record.power * price.summerPeakPrice;
      } else if (isInPeriod(hour, PILE_TIME_SUMMER_VALLEY)) {
        ret.period = ChargePeriod.low;
        ret.price = record.power * price.summerValleyPrice;
      } else ret.price = record.power * price.summerFlatPrice;
    } else {
      // 非夏季
      if (isInPeriod(hour, PILE_TIME_OTHER_PEAK)) {
        ret.period = ChargePeriod.height;
        ret.price = record.power * price.otherPeakPrice;
      } else if (isInPeriod(hour, PILE_TIME_OTHER_VALLEY)) {
        ret.period = ChargePeriod.low;
        ret.price = record.power * price.otherValleyPrice;
      } else ret.price = record.power * price.otherFlatPrice;
    }
  }
  return ret;
};

export const makeSgccRecordsSelector = selector =>
  createSelector(
    selector,
    state => {
      const result = state.result || [];

      return {
        ...state,
        result: result.map(r => ({
          ...r,
          startAt: formatValue(r, "startAt", ymdhm),
          endAt: formatValue(r, "endAt", ymdhm),
          period: i18n.ChargePeriod[r.period] || "--",
        })),
      };
    }
  );

export const makeSgccXlsxSelector = priceSelector => selector =>
  createSelector(
    priceSelector,
    selector,
    (priceState, xlsxState) => {
      const price = priceState.result || {};
      const result = xlsxState.result || [];
      result.forEach(item => {
        item.SOCpower = parseFloat(
          ((item.endSOC - item.startSOC) * 2.14).toFixed(2)
        );
        item.delta = parseFloat((item.power - item.SOCpower).toFixed(2));
        item.month = moment(item.startAt).month() + 1;

        const countData = countPilePeriodAndPrice(item, price);
        item.period = countData.period;
        item.price = countData.price.toFixed(2);
      });
      return {
        ...xlsxState,
        result,
      };
    }
  );

export const makeSgccSummarySelector = selector =>
  createSelector(
    selector,
    state => {
      const result = state.result || [];
      const data = [
        {
          month: "一月",
          key: 1,
        },
        {
          month: "二月",
          key: 2,
        },
        {
          month: "三月",
          key: 3,
        },
        {
          month: "四月",
          key: 4,
        },
        {
          month: "五月",
          key: 5,
        },
        {
          month: "六月",
          key: 6,
        },
        {
          month: "七月",
          key: 7,
        },
        {
          month: "八月",
          key: 8,
        },
        {
          month: "九月",
          key: 9,
        },
        {
          month: "十月",
          key: 10,
        },
        {
          month: "十一月",
          key: 11,
        },
        {
          month: "十二月",
          key: 12,
        },
        {
          month: "合计",
          key: "total",
        },
      ];

      result.forEach(d => {
        const month = find(data, { key: d.month });
        const total = find(data, { key: "total" });
        const power = parseFloat(d.power.toFixed(2));
        const price = parseFloat(d.price.toFixed(2));
        month[`${d.department}.power`] = power;
        month[`${d.department}.price`] = price;
        month[`${d.department}.average`] = (price / power).toFixed(2);
        total[`${d.department}.power`] =
          (total[`${d.department}.power`] || 0) + power;
        total[`${d.department}.price`] =
          (total[`${d.department}.price`] || 0) + price;
      });

      return {
        ...state,
        result: data,
      };
    }
  );

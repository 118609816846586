export default {
  /**
   * 一些页面文字常量
   */
  TEMPERATURE_UNIT: "℃",
  RESISTANCE_UNIT: "kΩ",
  PRESSURE_UNIT: "kPa",
  SPEED_UNIT: "km/h",
  RSPEED_UNIT: "r/min",
  MILEAGE_UNIT: "km",
  PERCENT_UNIT: "%",
  VOLTAGE_UNIT: "V",
  CURRENT_UNIT: "A",
  POWER_UNIT: "ks·h",
  TORQUE_UNIT: "N·m",
  TIME_UNIT: "s",

  /**
   * 单位
   */
  Unit: {
    TEMPERATURE: "℃",
    RESISTANCE: "kΩ",
    PRESSURE: "kPa",
    SPEED: "km/h",
    RSPEED: "r/min",
    MILEAGE: "km",
    PERCENT: "%",
    VOLTAGE: "V",
    CURRENT: "A",
    POWER: "kW·h",
    TORQUE: "N·m",
    SECOND: "s",
  },

  // 页面菜单
  ProjectMenuType: {
    detail: "查看详情",
  },

  /**
   * 车辆充电状态: 停车充电，行驶充电，未充电，充电完成，异常
   */
  ChargeStatus: {
    PARK_CHARGING: "停车充电",
    MOVE_CHARGING: "行驶充电",
    UNCHARGED: "未充电",
    CHARGED: "充电完成",
    ABNORMAL: "异常",
  },

  /**
   * 车辆状态（发动机状态）: 启动，熄火，其他，异常
   */
  VehicleStatus: {
    ON: "启动",
    OFF: "熄火",
    OTHER: "其他",
    ABNORMAL: "异常",
  },

  /**
   * 车辆运营状态
   */
  VehicleState: {
    OFFSITE: "未接入",
    REPAIRING: "维修中",
    OFFLINE: "离线中",

    CHARGING: "充电中",
    RUNNING: "运营中",

    ALERT1: "一级警报",
    ALERT2: "二级警报",
    ALERT3: "三级警报",
  },

  /**
   * 车辆终端状态: 在线，未知（从未登录）,  离线, 异常
   */
  TerminalStatus: {
    ONLINE: "在线",
    OFFLINE: "离线",
    UNKNOWN: "未接入",
    ABNORMAL: "异常",
  },

  /**
   * 车辆报警状态: 无，一级报警，二级报警，三级报警
   */
  AlertStatus: {
    ALERT0: "无", // 无报警
    ALERT1: "一级警报", // 一级报警
    ALERT2: "二级警报", // 二级报警
    ALERT3: "三级警报", // 三级报警
  },

  /**
   * 空调模式: 关闭, 进风, 制热, 制冷
   */
  AirMode: {
    OFF: "关闭",
    WIND: "进风",
    HEATING: "制热",
    REFRIGERATION: "制冷",
    ABNORMAL: "异常",
  },

  MotorStatus: {
    CONSUMPTION: "耗能",
    GENERATION: "发电",
    OFF: "关",
    READY: "就绪",
    ABNORMAL: "异常",
  },

  AlertLevel: {
    3: "三级报警",
    2: "二级报警",
    1: "一级报警",
  },

  /**
   * 处理方式
   */
  AlertAction: {
    TICKET: "已启动紧急处置流程",
    IGNORE: "已处置",
  },

  /**
   * 报警类别
   */
  AlertType: {
    整车: "整车",
    电机: "电机",
    充电系统: "充电系统",
    电池: "电池",
    DCDC: "DCDC",
    油泵: "油泵",
    气泵: "气泵",
    ATS: "ATS",
    AC: "AC",
    ADAS: "ADAS",
  },

  AlertState: {
    OPEN: "未处理",
    CLOSED: "已关闭",
  },

  /**
   * 处置单处置方式
   */
  TicketAction: {
    STOPPED_IMMEDIATELY: "立即停驶", // 立即停驶
    DEPOT_REPAIR: "返厂维修", // 返厂维修
    NO_NEED_TO_DEAL: "无需处理", // 无需处理
    FEEDBACK_FALSE_REPORT: "反馈误报", //反馈误报
  },

  /**
   * 处置单处置方式提醒
   */
  TicketActionTip: {
    STOPPED_IMMEDIATELY: "车辆应立即靠边停车、疏散乘客，等待路救", // 立即停驶
    DEPOT_REPAIR: "车辆需要在当天营运结束后进维修车间检修", // 返厂维修
    NO_NEED_TO_DEAL: "车辆可以继续正常行驶", // 无需处理
    FEEDBACK_FALSE_REPORT: "报警有误，请在处置说明中反馈", // 无需处理
  },

  /**
   * 默认的处置说明
   */
  TicketActionContent: {
    STOPPED_IMMEDIATELY: "建议立即停驶", // 立即停驶
    DEPOT_REPAIR: "建议返厂维修", // 返厂维修
    NO_NEED_TO_DEAL: "该报警无需处理", // 无需处理
    FEEDBACK_FALSE_REPORT: "该报警为误报", // 无需处理
  },

  BreakStatus: {
    ON: "开",
    OFF: "关",
    ABNORMAL: "异常",
  },
  DoorStatus: {
    CLOSE: "关闭",
    OPEN: "开启",
    ABNORMAL: "异常",
  },

  /**
   * 充电弓
   */
  BowRiseState: {
    RISED: "升弓完成", // 升弓完成
    DROPPING: "降弓中", // 降弓中
    DROPPED: "降弓完成", // 降弓完成
  },
  BowAllowLeave: {
    [false]: "允许驶离",
    [true]: "禁止驶离",
  },
  BowSystemState: {
    STANDBY: "待机", // 待机
    START: "启动", // 启动
    CHARGING: "充电", // 充电
  },
  BowWifi: {
    DISCONNECTED: "wifi未连接", // wifi未连接
    CONNECTING: "wifi连接中", // wifi连接中
    CONNECTED: "wifi已连接", // wifi已连接
  },
  BowCharging: {
    [false]: "停止充电",
    [true]: "开启充电",
  },

  /**
   * 电池预警类型
   */
  BetteryWarningType: {
    SOC不平衡度: "SOC不平衡度",
    SOC虚高: "SOC虚高",
    SOC跳变: "SOC跳变",
    充高放低: "充高放低",
    容量短板: "容量短板",
    温度跳变: "温度跳变",
    防爆阀冲开: "防爆阀冲开",
    频繁欠压: "频繁欠压",
    频繁过压: "频繁过压",
    频繁过温: "频繁过温",
  },

  // 异常种类
  ExceptionType: {
    BATTERY: "电池异常",
    INSULATION: "绝缘异常",
    TYRE: "轮胎异常",
    AIR: "空调异常",
    DRIVING: "驾驶行为异常",
    DCDC: "低压异常",
  },
  ExceptionOption: {
    BATTERY: "BatteryExceptionType",
    INSULATION: "InsulationExceptionType",
    TYRE: "TyreExceptionType",
    AIR: "AirExceptionType",
    DRIVING: "DrivingExceptionType",
    DCDC: "DCDCExceptionType",
  },

  DCDCExceptionType: {
    BATTERY_VOLTAGE_LOW_OFF_LEVEL3: "熄火中低压三级预警",
    BATTERY_VOLTAGE_LOW_OFF_LEVEL2: "熄火中低压二级预警",
    BATTERY_VOLTAGE_LOW_ON_LEVEL3: "运行中低压三级预警",
    BATTERY_VOLTAGE_LOW_ON_LEVEL2: "运行中低压二级预警",
  },

  // 电池异常种类
  BatteryExceptionType: {
    TEMP_OVER_55: "电池高温报警(超过55℃)",
    TEMP_BETWEEN_45_55: "电池高温预警(45-55℃)",
    SOC_BELOW_20_PERCENT: "电池SoC告急(低于20%)",
    SOC_BETWEEN_20_30_PERCENT: "电池SoC预警(低于30%)",
    CELL_VOLTAGE_LOW: "电池单体电压低",
  },

  // 绝缘异常种类
  InsulationExceptionType: {
    RESISTANCE_BELOW_500: "绝缘报警(小于500欧)",
    RESISTANCE_BETWEEN_500_100000: "绝缘预警(500欧-100千欧)",
    INSULATE_NORMAL: "维保时绝缘报警",
  },

  // 轮胎异常种类
  TyreExceptionType: {
    PRESSURE_BELOW_900: "胎压过低",
    PRESSURE_OVER_1100: "胎压过高",
    TEMP_OVER_40: "胎温过高",
  },

  AirExceptionType: {
    AIR_SHOULD_OPEN: "应开空调未开",
    AIR_SHOULD_NOT_OPEN: "不应开空调而开空调",
    AIR_SHOULD_NOT_OPEN_SPECIALCASE: "特殊情况不应开空调",
  },

  DrivingExceptionType: {
    DRIVING_OVER_SPEED: "超速",
    DRIVING_BRAKES: "紧急制动",
    DRIVING_ACCELERATION: "大电流放电",
  },

  // 终端监控异常种类
  TerminalExceptionType: {
    TERMINAL_DISCONNECTED: "连接异常",
  },

  // 终端数据异常类型
  TerminalDataExceptionType: {
    AIR_EXCEPTION: "空调数据异常",
    BATTERY_EXCEPTION: "电池数据异常",
    MILEAGE_EXCEPTION: "里程数据异常",
    TYRE_EXCEPTION: "轮胎数据异常",
    LOCATION_EXCEPTION: "GPS数据异常",
  },

  TerminalDataExceptionOption: {
    AIR_EXCEPTION: "TerminalDataAirExceptionType",
    BATTERY_EXCEPTION: "TerminalDataBatteryExceptionType",
    MILEAGE_EXCEPTION: "TerminalDataMileageExceptionType",
    TYRE_EXCEPTION: "TerminalDataTyreExceptionType",
    LOCATION_EXCEPTION: "TerminalDataLocationExceptionType",
  },

  TerminalDataLocationExceptionType: {
    LOCATION_ERROR: "GPS超出范围",
  },

  TerminalDataAirExceptionType: {
    AIR_TEMP_ZERO: "三个温度均为0",
    AIR_TEMP_LOW: "温度出现-40°C",
    AIR_ERROR_REFRIGERATION: "室外温度低于10°C，空调状态为制冷",
    AIR_ERROR_HEATING: "室外温度高于30°C，空调状态为制热",
  },

  TerminalDataAirExceptionOption: {
    AIR_TEMP_ZERO: "AIR_TEMP_ZERO",
    AIR_TEMP_LOW: "AIR_TEMP_LOW",
    AIR_ERROR_REFRIGERATION: "AIR_ERROR_REFRIGERATION",
    AIR_ERROR_HEATING: "AIR_ERROR_HEATING",
  },

  TerminalDataBatteryExceptionType: {
    BATTERY_SOC_ZERO: "SoC等于0%",
    BATTERY_TEMP_HIGH: "电池温度出现205℃",
  },

  TerminalDataBatteryExceptionOption: {
    BATTERY_SOC_ZERO: "BATTERY_SOC_ZERO",
    BATTERY_TEMP_HIGH: "BATTERY_TEMP_HIGH",
  },

  TerminalDataMileageExceptionType: {
    MILEAGE_ZERO: "总里程为0",
    MILEAGE_DECREASE: "总里程减少",
    MILEAGE_HIGH: "总里程过高",
  },

  TerminalDataMileageExceptionOption: {
    MILEAGE_ZERO: "MILEAGE_ZERO",
    MILEAGE_DECREASE: "MILEAGE_DECREASE",
    MILEAGE_HIGH: "MILEAGE_HIGH",
  },

  TerminalDataTyreExceptionType: {
    TYRE_TEMP_HIGH: "轮胎温度等于205°C",
  },

  TerminalDataTyreExceptionOption: {
    TYRE_TEMP_HIGH: "TYRE_TEMP_HIGH",
  },

  TiresType: {
    lf: "左前轮", // 左前轮
    rf: "右前轮", // 右前轮
    lr1: "左后轮1", // 左后轮1
    lr2: "左后轮2", // 左后轮2
    rr1: "右后轮1", // 右后轮1
    rr2: "右后轮2", // 右后轮2
  },

  // 车辆面板数据类型
  VehicleBoardType: {
    current: "总电流",
    voltage: "总电压",
    maxNtc: "电池温度",
    motor_status: "电机状态",
    motor_temp: "电机温度",
    motor_speed: "电机转速",
    airMode: "空调状态",
    insideTemp: "车内温度",
    outsideTemp: "车外温度",
    aptv: "电门开度",
    brake: "制动开度",
    resistance: "整车绝缘",
    soc: "SOC",
  },

  // 车辆详情数据分类
  VehicleDataType: {
    BASIC: "整车数据",
    ADAS: "ADAS数据",
    TYRE: "轮胎数据",
    AC: "空调数据",
    BATTERY: "电池数据",
    MOTOR: "电机数据",
    CHARGING_BOW: "充电弓数据",
    DCDC: "DC-DC数据",
  },

  // 车辆详情整车数据分类
  VehicleDataBasic: {
    at: "采集时间",
    status: "车辆状态",
    chargeStatus: "充电状态",
    speed: "车速",
    mileage: "里程",
    shift: "档位",
    vin: "车辆vin",
    keyPosition: "钥匙位置",
    resistance: "整车绝缘",
    reportedAt: "上报时间",
    location: "GPS经纬度",
    aptv: "电门开度",
    brake: "制动开度",
    acTemp: "气泵散热器温度",
    handbrakeStatus: "手刹信号",
    frontDoorStatus: "前门状态",
    middleDoorStatus: "中门状态",
    pressure1: "气压1",
    pressure2: "气压2",
  },

  // 车辆详情轮胎数据分类
  VehicleDataTyre: {
    lftp: "左前轮胎压力",
    rftp: "右前轮胎压力",
    lr1tp: "左后1轮胎压力",
    lr2tp: "左后2轮胎压力",
    rr1tp: "右后1轮胎压力",
    rr2tp: "右后2轮胎压力",
    lftt: "左前轮胎温度",
    rftt: "右前轮胎温度",
    lr1tt: "左后1轮胎温度",
    lr2tt: "左后2轮胎温度",
    rr1tt: "右后1轮胎温度",
    rr2tt: "右后2轮胎温度",
  },

  // 车辆详情空调数据分类
  VehicleDataAC: {
    airMode: "空调状态",
    airTemp: "空调设定温度",
    insideTemp: "车内温度",
    outsideTemp: "环境温度",
  },

  // 车辆详情电池数据分类
  VehicleDataBattery: {
    soc: "SOC",
    voltage: "总电压",
    current: "总电流",
    instantPower: "瞬时电耗",
    maxNtc: "电池最高温",
    maxNtcNo: "最高温度电芯编号",
    minNtc: "电池最低温",
    minNtcNo: "最低温度电芯编号",
    maxVoltage: "电池最高电压",
    maxVoltageSingNo: "最高电压电芯编号",
    minVoltage: "电池最低电压",
    minVoltageSingNo: "最低电压电芯编号",
    totalCharge: "累计充电电能值",
    totalDischarge: "累计放电电能值",
    bpiRes: "电池高压正极绝缘电阻",
    bniRes: "电池高压负极绝缘电阻",
  },

  // 车辆详情电机数据分类
  VehicleDataMotor: {
    status: "电机状态",
    speed: "电机转速",
    temp: "电机温度",
    torque: "电机转矩",
    current: "电机控制器输入电流",
    voltage: "电机控制器输入电压",
    controlTemp: "电机控制器温度",
  },

  // 车辆详情充电弓数据分类
  VehicleDataChargingBow: {
    state: "弓升状态",
    allowLeave: "车辆驶离状态",
    systemState: "充电弓系统状态",
    wifi: "wifi链接状态",
    soc: "电池SOC",
    charging: "充电请求",
    voltage: "充电电压",
    current: "充电电流",
    capacity: "充电电量",
    duration: "充电时间",
  },

  // 车辆详情DC-DC数据分类
  VehicleDataDCDC: {
    dcStatus: "DC-DC状态",
    dcov: "DC-DC输出电压",
    dcoc: "DC-DC输出电流",
    dcTemp: "DC-DC散热器温度",
    batteryVoltage: "蓄电池电压值",
    acTemp: "DC-AC散热器温度",
  },

  // 报文命令翻译
  Command: {
    REALTIME_REPORT: "实时信息上报",
    VEHICLE_LOGIN: "车辆登入",
    REISSUE_REPORT: "补发信息上报",
    VEHICLE_LOGOUT: "车辆登出",
    PLATFORM_LOGIN: "平台登入",
    PLATFORM_LOGOUT: "平台登出",
    HEARTBEAT: "心跳",
    TIME: "终端校时",
  },

  /**
   * 开启的权限
   */
  UserRoles: {
    ADMIN: "超级管理员",
    PRODUCER_MONITOR: "厂商监控人员", // 厂商
    COMPANY_MONITOR: "运营公司监控人员", // 运营公司
    FLEET_MONITOR: "车队监控人员", // 车队管理员
    TERMINAL_OP: "终端操作人员", // 终端操作
    REPAIR_OP: "维修车间操作人员", // 维修车间操作
    CATL_MONITOR: "CATL监控人员",
    MAINTENANCE_MONITOR: "机务保证中心监控人员", // 机务保证中心监控人员
  },

  // 国家电网充电时段定义

  ChargePeriod: {
    height: "峰",
    low: "谷",
    normal: "平",
  },

  /**
   * 报警详情
   */
  AlertDetails: [
    // 整车 3级 共6种
    { code: "100a0a03", name: "整车高压互锁故障", level: 3, type: "整车" },
    { code: "100aa703", name: "高压绝缘严重故障", level: 3, type: "整车" },
    { code: "10100003", name: "整车控制器系统故障", level: 3, type: "整车" },
    { code: "10101003", name: "气压低故障", level: 3, type: "整车" },
    {
      code: "10100b03",
      name: "整车接触器或预充接触器粘连故障",
      level: 3,
      type: "整车",
    },
    { code: "10103e03", name: "ABS系统严重故障", level: 3, type: "整车" },
    // 整车 2级 共18种
    { code: "10c29202", name: "电机通讯故障", level: 2, type: "整车" },
    { code: "10c28902", name: "BMS通讯故障(A-CAN)", level: 2, type: "整车" },
    { code: "10d10002", name: "ACU通讯故障", level: 2, type: "整车" },
    { code: "10c11602", name: "ATS通讯故障", level: 2, type: "整车" },
    { code: "10100302", name: "刹车踏板信号无效故障", level: 2, type: "整车" },
    { code: "10100f02", name: "加速踏板信号无效故障", level: 2, type: "整车" },
    { code: "10100e02", name: "档位信号无效故障", level: 2, type: "整车" },
    { code: "100aa602", name: "高压绝缘一般故障", level: 2, type: "整车" },
    { code: "10102602", name: "低压电池电压低故障", level: 2, type: "整车" },
    { code: "10103402", name: "气压低报警", level: 2, type: "整车" },
    { code: "10103502", name: "气压传感器报警", level: 2, type: "整车" },
    { code: "10103702", name: "整车接触器驱动故障", level: 2, type: "整车" },
    { code: "10103802", name: "预充接触器驱动故障", level: 2, type: "整车" },
    { code: "10100c02", name: "整车接触器断路故障", level: 2, type: "整车" },
    { code: "10103602", name: "预充接触器断路故障", level: 2, type: "整车" },
    { code: "10100d02", name: "预充超时故障", level: 2, type: "整车" },
    { code: "10103d02", name: "ABS系统一般故障", level: 2, type: "整车" },
    { code: "10103f02", name: "ECAS系统一般故障", level: 2, type: "整车" },
    // 整车 1级 共4种
    { code: "10d10801", name: "仪表内部通讯故障", level: 1, type: "整车" },
    { code: "10103a01", name: "车身模块一般故障", level: 1, type: "整车" },
    { code: "10103b01", name: "胎压监测系统一般故障", level: 1, type: "整车" },
    { code: "10103c01", name: "集中润滑系统一般故障", level: 1, type: "整车" },

    // 电机 3级 共2种
    { code: "10140003", name: "电机控制器系统故障", level: 3, type: "电机" },
    { code: "10140103", name: "编码器故障", level: 3, type: "电机" },
    // 电机 2级 共8种
    { code: "10140202", name: "电机控制器过压故障", level: 2, type: "电机" },
    { code: "10140302", name: "欠压故障", level: 2, type: "电机" },
    { code: "10140402", name: "电机超速故障", level: 2, type: "电机" },
    { code: "10140602", name: "电机过温故障", level: 2, type: "电机" },
    { code: "10140802", name: "控制器过温故障", level: 2, type: "电机" },
    { code: "10140a02", name: "电机速度传感器故障", level: 2, type: "电机" },
    { code: "10140b02", name: "电机控制器过流故障", level: 2, type: "电机" },
    { code: "10d10902", name: "电机控制器通讯故障", level: 2, type: "电机" },
    // 电机 1级 共3种
    { code: "10140501", name: "电机超速报警", level: 1, type: "电机" },
    { code: "10140701", name: "电机过温报警", level: 1, type: "电机" },
    { code: "10140901", name: "控制器过温报警", level: 1, type: "电机" },

    // 充电系统 3级 共2种
    {
      code: "10130303",
      name: "充电继电器粘连故障",
      level: 3,
      type: "充电系统",
    },
    {
      code: "10130503",
      name: "充电预充继电器粘连故障",
      level: 3,
      type: "充电系统",
    },
    // 充电系统 2级 共4种
    { code: "10130002", name: "充电故障", level: 2, type: "充电系统" },
    { code: "10130202", name: "充电插座高温故障", level: 2, type: "充电系统" },
    {
      code: "10130402",
      name: "充电继电器开路故障",
      level: 2,
      type: "充电系统",
    },
    {
      code: "10130602",
      name: "充电预充继电器开路故障",
      level: 2,
      type: "充电系统",
    },
    // 充电系统 1级 共1种
    { code: "10130101", name: "充电插座高温报警", level: 1, type: "充电系统" },

    // 电池 3级 共4种
    { code: "10130703", name: "电池内部高压互锁故障", level: 3, type: "电池" },
    { code: "10130803", name: "主继电器粘连故障", level: 3, type: "电池" },
    { code: "10130c03", name: "单体过压故障", level: 3, type: "电池" },
    { code: "10131603", name: "电池高温故障", level: 3, type: "电池" },
    // 电池 2级 共9种
    { code: "10130902", name: "主继电器开路故障", level: 2, type: "电池" },
    { code: "10130a02", name: "BMS通讯故障(C-CAN)", level: 2, type: "电池" },
    { code: "10130e02", name: "单体欠压故障", level: 2, type: "电池" },
    { code: "10131002", name: "单体压差故障", level: 2, type: "电池" },
    { code: "10131202", name: "电池温差故障", level: 2, type: "电池" },
    { code: "10131402", name: "电池低温故障", level: 2, type: "电池" },
    { code: "10131c02", name: "均衡电路故障", level: 2, type: "电池" },
    { code: "10131d02", name: "电池热管理系统报警", level: 2, type: "电池" },
    { code: "10131e02", name: "电池支路断路报警", level: 2, type: "电池" },
    // 电池 1级 共11种
    { code: "10130b01", name: "单体过压报警", level: 1, type: "电池" },
    { code: "10130d01", name: "单体欠压报警", level: 1, type: "电池" },
    { code: "10130f01", name: "单体压差报警", level: 1, type: "电池" },
    { code: "10131101", name: "电池温差报警", level: 1, type: "电池" },
    { code: "10131301", name: "电池低温报警", level: 1, type: "电池" },
    { code: "10131501", name: "电池高温报警", level: 1, type: "电池" },
    { code: "10131701", name: "电池SOC低报警", level: 1, type: "电池" },
    { code: "10131801", name: "电池总电压低报警", level: 1, type: "电池" },
    { code: "10131901", name: "电池总电压高报警", level: 1, type: "电池" },
    {
      code: "10131a01",
      name: "电池放电电流超限制报警",
      level: 1,
      type: "电池",
    },
    {
      code: "10131b01",
      name: "电池回充电流超限制报警",
      level: 1,
      type: "电池",
    },

    // DCDC 2级 共8种
    { code: "10111002", name: "DCDC-严重故障", level: 2, type: "DCDC" },
    { code: "10122502", name: "输出欠压故障", level: 2, type: "DCDC" },
    { code: "10122602", name: "输出过压故障", level: 2, type: "DCDC" },
    { code: "10121d02", name: "输入欠压故障", level: 2, type: "DCDC" },
    { code: "10121c02", name: "输入过压故障", level: 2, type: "DCDC" },
    { code: "10d10502", name: "DCDC-通讯故障", level: 2, type: "DCDC" },
    { code: "10120d02", name: "过温故障", level: 2, type: "DCDC" },
    { code: "10120202", name: "输出电流过流", level: 2, type: "DCDC" },
    // DCDC 1级 共3种
    { code: "10110f01", name: "DCDC-一般故障", level: 1, type: "DCDC" },
    { code: "10123801", name: "输入欠压警告", level: 1, type: "DCDC" },
    { code: "10120e01", name: "过温报警", level: 1, type: "DCDC" },

    // 油泵 2级 共12种
    { code: "10111202", name: "油泵-严重故障", level: 2, type: "油泵" },
    { code: "10d10602", name: "油泵-通讯故障", level: 2, type: "油泵" },
    { code: "10122402", name: "油泵-高压输入欠压故障", level: 2, type: "油泵" },
    { code: "10121702", name: "油泵-控制器过温故障", level: 2, type: "油泵" },
    { code: "10121802", name: "油泵-电机过温故障", level: 2, type: "油泵" },
    { code: "10123102", name: "油泵-控制低压欠压故", level: 2, type: "油泵" },
    { code: "10122302", name: "油泵-高压输入过压故障", level: 2, type: "油泵" },
    { code: "10120602", name: "油泵-相电流过流故障", level: 2, type: "油泵" },
    { code: "10122e02", name: "油泵-缺相故障", level: 2, type: "油泵" },
    { code: "10123202", name: "油泵-失速", level: 2, type: "油泵" },
    { code: "10123302", name: "低压绕阻输入欠压", level: 2, type: "油泵" },
    { code: "10123402", name: "低压绕阻输出过流", level: 2, type: "油泵" },
    // 油泵 1级 共4种
    { code: "10111101", name: "油泵-一般故障", level: 1, type: "油泵" },
    { code: "10123001", name: "油泵-相电流过流报警", level: 1, type: "油泵" },
    { code: "10121101", name: "油泵-控制器过温报警", level: 1, type: "油泵" },
    { code: "10121201", name: "油泵-电机过温报警", level: 1, type: "油泵" },

    // 气泵 2级 共10种
    { code: "10111402", name: "气泵-严重故障", level: 2, type: "气泵" },
    { code: "10d10702", name: "气泵-通讯故障", level: 2, type: "气泵" },
    { code: "10122202", name: "气泵-高压输入欠压故障", level: 2, type: "气泵" },
    { code: "10121502", name: "气泵-控制器过温故障", level: 2, type: "气泵" },
    { code: "10121602", name: "气泵-电机过温故障", level: 2, type: "气泵" },
    { code: "10123602", name: "气泵-控制低压欠压故障", level: 2, type: "气泵" },
    { code: "10122102", name: "气泵-高压输入过压故障", level: 2, type: "气泵" },
    { code: "10120402", name: "气泵-相电流过流故障", level: 2, type: "气泵" },
    { code: "10122f02", name: "气泵-缺相故障", level: 2, type: "气泵" },
    { code: "10123702", name: "气泵-失速", level: 2, type: "气泵" },
    // 气泵 1级 共4种
    { code: "10111301", name: "气泵-一般故障", level: 1, type: "气泵" },
    { code: "10123501", name: "气泵-相电流过流报警", level: 1, type: "气泵" },
    { code: "10120f01", name: "气泵-控制器过温报警", level: 1, type: "气泵" },
    { code: "10121001", name: "气泵-电机过温报警", level: 1, type: "气泵" },

    // ATS
    { code: "10111602", name: "热管理系统严重故障", level: 2, type: "ATS" },
    { code: "10111501", name: "热管理系统一般故障", level: 1, type: "ATS" },

    // AC 1级 共7种
    { code: "101f0001", name: "空调系统一般故障", level: 1, type: "AC" },
    { code: "101f0101", name: "空调系统严重故障", level: 1, type: "AC" },
    { code: "101f0201", name: "车外温度传感器故障", level: 1, type: "AC" },
    { code: "101f0301", name: "车内温度传感器故障", level: 1, type: "AC" },
    { code: "101f0401", name: "蒸发器传感器故障", level: 1, type: "AC" },
    { code: "101f0501", name: "空调压力过压", level: 1, type: "AC" },
    { code: "101f0601", name: "空调压力欠压", level: 1, type: "AC" },

    // ADAS 3级 共7种
    { code: "ada1a403", name: "毫米波通讯故障", level: 3, type: "ADAS" },
    { code: "ada2a403", name: "超声波通讯故障", level: 3, type: "ADAS" },
    { code: "ada3a403", name: "视觉传感器通讯故障", level: 3, type: "ADAS" },
    { code: "ada3a503", name: "Mobileye不可用故障", level: 3, type: "ADAS" },
    { code: "ada1a503", name: "毫米波雷达不可用故障", level: 3, type: "ADAS" },
    { code: "ada2a503", name: "超声波雷达不可用故障", level: 3, type: "ADAS" },
    { code: "ad260303", name: "辅助控制器通讯故障", level: 3, type: "ADAS" },
    // ADAS 2级 共7种
    { code: "ada1a402", name: "毫米波通讯故障", level: 2, type: "ADAS" },
    { code: "ada2a402", name: "超声波通讯故障", level: 2, type: "ADAS" },
    { code: "ada3a402", name: "视觉传感器通讯故障", level: 2, type: "ADAS" },
    { code: "ada3a502", name: "Mobileye不可用故障", level: 2, type: "ADAS" },
    { code: "ada1a502", name: "毫米波雷达不可用故障", level: 2, type: "ADAS" },
    { code: "ada2a502", name: "超声波雷达不可用故障", level: 2, type: "ADAS" },
    { code: "ad260302", name: "辅助控制器通讯故障", level: 2, type: "ADAS" },
    // ADAS 1级 共7种
    { code: "ada1a401", name: "毫米波通讯故障", level: 1, type: "ADAS" },
    { code: "ada2a401", name: "超声波通讯故障", level: 1, type: "ADAS" },
    { code: "ada3a401", name: "视觉传感器通讯故障", level: 1, type: "ADAS" },
    { code: "ada3a501", name: "Mobileye不可用故障", level: 1, type: "ADAS" },
    { code: "ada1a501", name: "毫米波雷达不可用故障", level: 1, type: "ADAS" },
    { code: "ada2a501", name: "超声波雷达不可用故障", level: 1, type: "ADAS" },
    { code: "ad260301", name: "辅助控制器通讯故障", level: 1, type: "ADAS" },
  ],

  RecordState: {
    REALTIME_REPORT: "实时",
    REISSUE_REPORT: "补发",
  },
};

import { createSelector } from "reselect";
import { formatValue, getNsName, ymdhms } from "../lib";

export const makeWarningListSelector = warningSelector =>
  createSelector(
    warningSelector,
    state => {
      const records = state.result || [];
      const result = records.map(r => ({
        ...r,
        department: formatValue(r, "ns", getNsName),
        createdAt: formatValue(r, "createdAt", ymdhms),
        updatedAt: formatValue(r, "updatedAt", ymdhms),
      }));
      return { ...state, result };
    }
  );

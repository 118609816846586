/**
 * 报警统计 按照类型分类
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import ReactEcharts from "echarts-for-react";
import { sumBy } from "lodash";

export default class ChartByType extends PureComponent {
  get colors() {
    return ["#1890FF", "#13C2C2", "#2FC25B", "#FACC14", "#F04864", "#8543E0"];
  }

  getOption = (data = []) => {
    const total = sumBy(data, "value");
    return {
      color: this.colors,
      title: {
        text: "充电总量",
        subtext: `${total.toFixed(2)} kw·h`,
        left: "36%",
        top: "40%",
        textStyle: {
          fontSize: 25,
          color: "#454c5c",
          align: "center",
        },
        subtextStyle: {
          fontSize: 18,
          color: "#6c7a89",
          // align: "center",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function(params) {
          return `${params.name} : ${params.value.toFixed(2)} (${
            params.percent
          }%)`;
        },
      },
      legend: [
        {
          orient: "vertical",
          itemGap: 18,
          icon: "circle",
          itemHeight: 10,
          y: "center",
          right: "35%",
          textStyle: {
            fontSize: 16,
            color: "#595959",
          },
          data,
          formatter: function(name) {
            return name + "充电量";
          },
        },
        {
          orient: "vertical",
          itemGap: 18,
          icon: "none",
          y: "center",
          right: "29%",
          data,
          formatter: function(name) {
            const found = data.find(d => d.name === name);

            return ((found.value / total) * 100).toFixed(2) + "%";
          },
          textStyle: {
            color: "rgba(0, 0, 0, 0.427450980392157)",
            fontSize: 16,
          },
        },
        {
          orient: "vertical",
          itemGap: 18,
          icon: "none",
          y: "center",
          right: "19%",
          data,
          formatter: function(name) {
            const found = data.find(d => d.name === name);

            return found.value.toFixed(2) + " kw·h";
          },
          textStyle: {
            color: "rgba(0, 0, 0, 0.427450980392157)",
            fontSize: 16,
          },
        },
      ],
      series: [
        {
          name: "",
          type: "pie",
          radius: ["60%", "85%"],
          center: ["40%", "50%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: false,
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data,
        },
      ],
    };
  };

  render() {
    const { data = [] } = this.props;

    const option = this.getOption(data);

    return (
      <Row>
        <Col span={24}>
          <ReactEcharts option={option} />
        </Col>
      </Row>
    );
  }
}

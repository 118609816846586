import React from "react";

import { BASE_TITLE } from "../../config";

export default title => WrappedComponent => {
  class Hoc extends React.PureComponent {
    constructor(props) {
      super(props);
      window.document.title = `${title}-${BASE_TITLE}`;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return Hoc;
};

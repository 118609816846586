/**
 * redux 中间件 根据label 过滤api
 */
import { cloneDeep, set } from "lodash";
import { LABELS_FILTER } from "../../config";
import {
  CORE_VEHICLE_LIST_VEHICLES,
  OP_ALERT_LIST_ALERTS,
  OP_TICKET_LIST_TICKETS,
  OP_EXCEPTION_LIST_EXCEPTIONS,
  CORE_VEHICLE_GET_STATISTICS,
} from "../../actions/types";

const FILTER_LABELS_API = {
  [CORE_VEHICLE_LIST_VEHICLES.REQUEST]: "labels",
  [CORE_VEHICLE_GET_STATISTICS.REQUEST]: "labels",
  [OP_ALERT_LIST_ALERTS.REQUEST]: "vehicleLabels",
  [OP_TICKET_LIST_TICKETS.REQUEST]: "vehicleLabels",
  [OP_EXCEPTION_LIST_EXCEPTIONS.REQUEST]: "vehicleLabels",
};

const FilterTypes = Object.keys(FILTER_LABELS_API);

const labelsFilterMiddleware = () => next => action => {
  const { type } = action;

  if (LABELS_FILTER.length === 0) {
    return next(action);
  }

  if (FilterTypes.includes(type)) {
    const newPayload = cloneDeep(action.payload || {});

    set(
      newPayload,
      ["query", "filter", FILTER_LABELS_API[type]],
      LABELS_FILTER
    );

    const nextAction = {
      ...action,
      payload: newPayload,
    };

    return next(nextAction);
  }

  next(action);
};

export default labelsFilterMiddleware;

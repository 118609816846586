import React, { PureComponent } from "react";
import { Layout, Button } from "antd";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import withTitle from "../../components/withTitle";
import Nav from "../../components/nav";
import { Privileges } from "../../config/privileges";
import { privilegesSelector } from "../../selectors";
import {
  MENU_PILE_MONITOR,
  MENU_PILE_HISTORY,
  MENU_PILE_HABIT,
  MENU_PILE_SUMMARY,
} from "../../config";
import Monitor from "./monitor";
import History from "./history/index";
import Habit from "./habit";
import Summary from "./summary/index";
import PileMonitor from "./pile-monitor";
import PileHistory from "./pile-history";

const { Header, Content } = Layout;

const menu = [
  {
    title: "充电桩监控",
    path: "/monitor",
    key: "monitor",
    privilege: Privileges.PILE.MONITOR,
    switch: MENU_PILE_MONITOR,
  },
  {
    title: "充电历史记录",
    path: "/history",
    key: "history",
    privilege: Privileges.PILE.HISTORY,
    switch: MENU_PILE_HISTORY,
  },
  {
    title: "充电习惯分析",
    path: "/habit",
    key: "habit",
    privilege: Privileges.PILE.HABIT,
    switch: MENU_PILE_HABIT,
  },
  {
    title: "充电统计",
    path: "/summary",
    key: "summary",
    privilege: Privileges.PILE.SUMMARY,
    switch: MENU_PILE_SUMMARY,
  },
];

@withTitle("充电桩")
@connect(state => {
  const privileges = privilegesSelector(state) || [];

  return {
    privileges,
  };
})
export default class Pile extends PureComponent {
  onBack = () => {
    const { history } = this.props;
    history.go(-1);
  };

  render() {
    const { location } = this.props;
    const { state } = location;

    const { subPage, headerTitle } = state || {};

    const header = subPage ? (
      <Header style={{ background: "#fff", paddingLeft: 12 }}>
        <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
          <Button icon="left" type="link" onClick={this.onBack}>
            返回
          </Button>

          <div
            style={{
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            {headerTitle}
          </div>
        </div>
      </Header>
    ) : (
      <Header style={{ background: "#fff" }}>
        <Nav
          privileges={this.props.privileges}
          menu={menu}
          theme="light"
          mode="horizontal"
          prepath="/pile"
        />
      </Header>
    );

    return (
      <Layout>
        {header}
        <Content style={{ padding: 16 }}>
          <Switch>
            <Route path="/pile/monitor" component={Monitor} exact />
            <Route
              path="/pile/monitor/piles/:stationId"
              component={PileMonitor}
              exact
            />
            <Route path="/pile/history" component={History} />
            <Route path="/pile/habit" component={Habit} />
            <Route path="/pile/summary" component={Summary} />
            <Route path="/pile/:pileId/history" component={PileHistory} exact />
            <Redirect from="/pile" to="/pile/monitor" />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

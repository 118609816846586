import { makeApiTypes as mt } from "@36node/redux";

export const LOGIN = mt("LOGIN");
export const LOGOUT = mt("LOGOUT");
export const REFRESH = mt("REFRESH");

// op
export const OP_ALERT_LIST_ALERTS = mt("OP_ALERT_LIST_ALERTS");
export const OP_ALERT_GET_ALERT = mt("OP_ALERT_GET_ALERT");
export const OP_ALERT_UPDATE_ALERT = mt("OP_ALERT_UPDATE_ALERT");
export const OP_TICKET_CREATE_TICKET = mt("OP_TICKET_CREATE_TICKET");
export const OP_TICKET_GET_TICKET = mt("OP_TICKET_GET_TICKET");
export const OP_TICKET_LIST_TICKETS = mt("OP_TICKET_LIST_TICKETS");
export const OP_TICKET_LIST_STAGES = mt("OP_TICKET_LIST_STAGES");
export const OP_TICKET_CREATE_EVENT = mt("OP_TICKET_CREATE_EVENT");
export const OP_EXCEPTION_LIST_EXCEPTIONS = mt("OP_EXCEPTION_LIST_EXCEPTIONS");
export const OP_SUMMARY_GET_ALERT = mt("OP_SUMMARY_GET_ALERT");
export const OP_LIST_FAULTS = mt("OP_LIST_FAULTS");

// core
export const CORE_VEHICLE_GET_VEHICLE = mt("CORE_VEHICLE_GET_VEHICLE");
export const CORE_VEHICLE_LIST_VEHICLES = mt("CORE_VEHICLE_LIST_VEHICLES");
export const CORE_VEHICLE_CREATE_VEHICLE = mt("CORE_VEHICLE_CREATE_VEHICLE");
export const CORE_VEHICLE_UPDATE_VEHICLE = mt("CORE_VEHICLE_UPDATE_VEHICLE");
export const CORE_VEHICLE_DELETE_VEHICLE = mt("CORE_VEHICLE_DELETE_VEHICLE");
export const CORE_VEHICLE_GET_STATISTICS = mt("CORE_VEHICLE_GET_STATISTICS");
export const CORE_VEHICLE_GET_SOH_STATISTICS = mt(
  "CORE_VEHICLE_GET_SOH_STATISTICS"
);
export const CORE_VEHICLE_GET_AIR_STATISTICS = mt(
  "CORE_VEHICLE_GET_AIR_STATISTICS"
);
export const CORE_LINE_LIST_LINES = mt("CORE_LINE_LIST_LINES");
export const CORE_PRODUCER_LIST_PRODUCERS = mt("CORE_PRODUCER_LIST_PRODUCERS");
export const CORE_PARK_LIST_PARKS = mt("CORE_PARK_LIST_PARKS");
export const CORE_BANCI_LIST_BANCIS = mt("CORE_BANCI_LIST_BANCIS");
export const CORE_WARNING_LIST_WARNINGS = mt("CORE_WARNING_LIST_WARNINGS");
export const CORE_WARNING_LIST_LATESTWARNINGS = mt(
  "CORE_WARNING_LIST_LATESTWARNINGS"
);
export const CORE_GET_WEATHER = mt("CORE_GET_WEATHER");
export const CORE_GET_WEATHER_LAST = mt("CORE_GET_WEATHER_LAST");
export const CORE_PUSH_GET_SET = mt("CORE_PUSH_GET_SET");
export const CORE_PUSH_UPDATE_SET = mt("CORE_PUSH_UPDATE_SET");

export const CORE_LIST_OPTIONAL_VEHICLES = mt("CORE_LIST_OPTIONAL_VEHICLES");
export const CORE_DELETE_OPTIONAL_VEHICLES = mt(
  "CORE_DELETE_OPTIONAL_VEHICLES"
);

export const CORE_LIST_QSOH = mt("CORE_LIST_QSOH");

// chart
export const CHART_ANALYSIS_LIST_OVERALL = mt("CHART_ANALYSIS_LIST_OVERALL");
export const CHART_ANALYSIS_LIST_ALERT = mt("CHART_ANALYSIS_LIST_ALERT");
export const CHART_ANALYSIS_LIST_ALERTRATE = mt(
  "CHART_ANALYSIS_LIST_ALERTRATE"
);
export const CHART_ANALYSIS_LIST_DRILLDOWN = mt(
  "CHART_ANALYSIS_LIST_DRILLDOWN"
);
export const CHART_ALERT_HISTOGRAM = mt("CHART_ALERT_HISTOGRAM");
export const CHART_WARNING_LIST_WARNINGS = mt("CHART_WARNING_LIST_WARNINGS");
export const CHART_VEHICLE_LIST_MILEAGES = mt("CHART_VEHICLE_LIST_MILEAGES");
export const CHART_CHARGING_VEHICLE_HISTOGRAM = mt(
  "CHART_CHART_CHARGING_VEHICLE_HISTOGRAM"
);
export const CHART_POWER_LIST_POWERS = mt("CHART_POWER_LIST_POWERS");
export const CHART_POWER_LIST_POWERS_AGGS = mt("CHART_POWER_LIST_POWERS_AGGS");
export const CHART_POWER_GET_STATISTICS = mt("CHART_POWER_GET_STATISTICS");
export const CHART_POWER_GET_SCREENSTAT = mt("CHART_POWER_GET_SCREENSTAT");
export const CHART_EXCEPTION_LIST_STATISTICS = mt(
  "CHART_EXCEPTION_LIST_STATISTICS"
);
export const CHART_PLATFORM_TBOX_LIST_STATS = mt(
  "CHART_PLATFORM_TBOX_LIST_STATS"
);
export const CHART_DATAGRAM_LIST_STATS = mt("CHART_DATAGRAM_LIST_STATS");
export const CHART_DRIVER_POWER_STATS = mt("CHART_DRIVER_POWER_STATS");
export const LIST_DRIVER_POWERS = mt("LIST_DRIVER_POWERS");
export const LIST_DRIVER_POWERS_AGGS = mt("LIST_DRIVER_POWERS_AGGS");
export const CHART_CHARGEORDER_LIST_STATS = mt("CHART_CHARGEORDER_LIST_STATS");
export const CHART_SCREEN_CAOBAOLU_ENERGY = mt("CHART_SCREEN_CAOBAOLU_ENERGY");
export const CHART_SCREEN_CAOBAOLU_BATTERY = mt(
  "CHART_SCREEN_CAOBAOLU_BATTERY"
);
export const CHART_SCREEN_CAOBAOLU_SAVE = mt("CHART_SCREEN_CAOBAOLU_SAVE");
export const CHART_SCREEN_CAOBAOLU_MILEAGE = mt(
  "CHART_SCREEN_CAOBAOLU_MILEAGE"
);
export const CHART_SCREEN_CAOBAOLU_AIR = mt("CHART_SCREEN_CAOBAOLU_AIR");

// log
export const LOG_VEHICLE_LIST_RECORDS = mt("LOG_VEHICLE_LIST_RECORDS");

// auth namespace
export const AUTH_NS_LIST_NAMESPACES = mt("AUTH_NS_LIST_NAMESPACES");
export const AUTH_NS_CREATE_NAMESPACE = mt("AUTH_NS_CREATE_NAMESPACE");
export const AUTH_NS_DELETE_NAMESPACE = mt("AUTH_NS_DELETE_NAMESPACE");
export const AUTH_NS_UPDATE_NAMESPACE = mt("AUTH_NS_UPDATE_NAMESPACE");
export const AUTH_USER_LIST_USERS = mt("AUTH_USER_LIST_USERS");
export const AUTH_USER_CREATE_USER = mt("AUTH_USER_CREATE_USER");
export const AUTH_USER_UPDATE_USER = mt("AUTH_USER_UPDATE_USER");
export const AUTH_USER_DELETE_USER = mt("AUTH_USER_DELETE_USER");

// alert audio

export const ALERT_AUDIO_PLAY = "ALERT_AUDIO_PLAY";
export const ALERT_AUDIO_PAUSE = "ALERT_AUDIO_PAUSE";
export const ALERT_AUDIO_MUTE = "ALERT_AUDIO_MUTE";
export const ALERT_AUDIO_UNMUTE = "ALERT_AUDIO_UNMUTE";

// pile service
export const PILE_LIST_STATIONS = mt("PILE_LIST_STATIONS");
export const PILE_GET_STATION = mt("PILE_GET_STATION");
export const PILE_LIST_PILES = mt("PILE_LIST_PILES");
export const PILE_GET_PILE = mt("PILE_GET_PILE");
export const PILE_LIST_CHARGING_ORDERS = mt("PILE_LIST_CHARGING_ORDERS");
export const PILE_SGCC_LIST_RECORDS = mt("PILE_SGCC_LIST_RECORDS");
export const PILE_SGCC_LIST_FIELDS = mt("PILE_SGCC_LIST_FIELDS");
export const PILE_SGCC_CREATE_RECORD = mt("PILE_SGCC_CREATE_RECORD");
export const PILE_SGCC_SUMMARY = mt("PILE_SGCC_SUMMARY");
export const PILE_PRICE_GET_PRICE = mt("PILE_PRICE_GET_PRICE");
export const PILE_PRICE_UPDATE_PRICE = mt("PILE_PRICE_UPDATE_PRICE");

// weather
export const GET_WEATHER = mt("GET_WEATHER");

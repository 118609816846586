/**
 * 车辆充电状态: 停车充电，行驶充电，未充电，充电完成，异常
 */
export const ChargeStatus = {
  PARK_CHARGING: "PARK_CHARGING",
  MOVE_CHARGING: "MOVE_CHARGING",
  UNCHARGED: "UNCHARGED",
  CHARGED: "CHARGED",
  ABNORMAL: "ABNORMAL",
};

/**
 * 车辆状态（发动机状态）: 启动，熄火，其他，异常
 */
export const VehicleStatus = {
  ON: "ON",
  OFF: "OFF",
  OTHER: "OTHER",
  ABNORMAL: "ABNORMAL",
};

/**
 * 车辆运营状态: 未接入，维修中，离线，充电中，运营中
 */
export const VehicleState = {
  OFFSITE: "OFFSITE", // 从未收到过终端信息
  REPAIRING: "REPAIRING", // 维修中
  OFFLINE: "OFFLINE", // 超过十分钟未收到过终端上报的信息类报文

  CHARGING: "CHARGING", // 最近十分钟内收到过终端上报的信息类报文，显示充电/充电完成
  RUNNING: "RUNNING", // 最近十分钟内收到过终端上报的信息类报文，显示不在充电
};

/**
 * 车辆终端状态: 在线，未知（从未登录）,  离线, 异常
 */
export const TerminalStatus = {
  ONLINE: "ONLINE", // 最近十分钟内收到终端信息
  UNKNOWN: "UNKNOWN", // 从未收到过终端报文
  OFFLINE: "OFFLINE", // 离线时刻晚于等于最近一次路单的出发时刻
  ABNORMAL: "ABNORMAL", // 离线时刻早于最近一次路单的出发时刻
};

/**
 * 车辆报警状态: 无，一级报警，二级报警，三级报警
 */
export const AlertStatus = {
  ALERT0: "ALERT0", // 无报警
  ALERT1: "ALERT1", // 一级报警
  ALERT2: "ALERT2", // 二级报警
  ALERT3: "ALERT3", // 三级报警
};

export const AlertState = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
};
export const AlertAction = {
  IGNORE: "IGNORE",
  FEEDBACK: "FEEDBACK",
  TICKET: "TICKET",
};

export const TicketAction = {
  STOPPED_IMMEDIATELY: "STOPPED_IMMEDIATELY", // 立即停驶
  DEPOT_REPAIR: "DEPOT_REPAIR", // 返厂维修
  NO_NEED_TO_DEAL: "NO_NEED_TO_DEAL", // 无需处理
  FEEDBACK_FALSE_REPORT: "FEEDBACK_FALSE_REPORT", // 反馈误报
};

export const TicketEvent = {
  CLOSE: "CLOSE",
  REOPEN: "REOPEN",
  STAGE: "STAGE",
  COMMENT: "COMMENT",
  BIND_ALERT: "BIND_ALERT",
  CREATE: "CREATE", // 创建处置单
};

export const DataCategory = {
  BASIC: "basic",
  ADAS: "adasdata",
  BATTERY: "betterydata",
  TYRE: "tyredata",
  AC: "acdata",
  MOTOR: "motordata",
  DCDC: "dcdcdata",
  CHARGING_BOW: "chargingbowdata",
  TEN_SECONDS: "tenSeconds", // 十秒数据
};

/**
 * 根据车型判断在单车详情中需要显示那些数据
 */
export const VehicleModelDisplayData = {
  DEFAULT: Object.values(DataCategory),
  LOB: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  L9E: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  S0M: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  S0P: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
    DataCategory.TEN_SECONDS,
  ],
  S0Q: [
    DataCategory.BASIC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.CHARGING_BOW,
    DataCategory.DCDC,
  ],
  S2V: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  S2W: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  S2Y: [
    DataCategory.BASIC,
    DataCategory.ADAS,
    DataCategory.TYRE,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
    DataCategory.TEN_SECONDS,
  ],
  Z0A: [
    DataCategory.BASIC,
    DataCategory.ADAS,
    DataCategory.TYRE,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
    DataCategory.TEN_SECONDS,
  ],
  Z1D: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
    DataCategory.TEN_SECONDS,
  ],
  Z2C: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z2E: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z2F: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z2G: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z2H: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z5A: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z8B: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z9C: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  S8C: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
    DataCategory.TEN_SECONDS,
  ],
  Z9D: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  W1C: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  W1D: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  L9Z: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  V8B: [DataCategory.BASIC, DataCategory.BATTERY, DataCategory.MOTOR],
  Y0B: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Y9B: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z1C: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
  Z6A: [
    DataCategory.BASIC,
    DataCategory.AC,
    DataCategory.BATTERY,
    DataCategory.MOTOR,
    DataCategory.DCDC,
  ],
};

export const RecordCommands = {
  REALTIME_REPORT: "REALTIME_REPORT",
  VEHICLE_LOGIN: "VEHICLE_LOGIN",
  REISSUE_REPORT: "REISSUE_REPORT",
  VEHICLE_LOGOUT: "VEHICLE_LOGOUT",
  PLATFORM_LOGIN: "PLATFORM_LOGIN",
  PLATFORM_LOGOUT: "PLATFORM_LOGOUT",
  HEARTBEAT: "HEARTBEAT",
  TIME: "TIME",
};

/**
 * 开启的权限
 */
export const UserRoles = {
  ADMIN: "ADMIN",
  PRODUCER_MONITOR: "PRODUCER_MONITOR", // 厂商
  COMPANY_MONITOR: "COMPANY_MONITOR", // 运营公司
  FLEET_MONITOR: "FLEET_MONITOR", // 车队管理员
  TERMINAL_OP: "TERMINAL_OP", // 终端操作
  REPAIR_OP: "REPAIR_OP", // 维修车间操作
  CATL_MONITOR: "CATL_MONITOR", // CALT 监控人员
  MAINTENANCE_MONITOR: "MAINTENANCE_MONITOR", // 机务保证中心监控人员
};

export const BatteryWarningChartColors = [
  "#1890FF",
  "#2FC25B",
  "#FACC14",
  "#223273",
  "#8543e0",
  "#13C2C2",
  "#3436C7",
  "#F04864",
];

export const VehicleBoardStateColor = {
  RUNNING: "#40EC2E",
  CHARGING: "#40A9FF",
  OFFLINE: "#888888",
  ALERT1: "#FAAD14",
  ALERT2: "#FA541C",
  ALERT3: "#FF3848",
  REPAIRING: "#FF7A45",
  OFFSITE: "#CCCCCC",
};

export const AlertLevelI18N = {
  1: "一级报警",
  2: "二级报警",
  3: "三级报警",
};

export const PileStatus = {
  TAKEN_CHARGING: 3, //占用(充电中)
  TAKEN_NOT_CHARGING: 2, // 占用(未充电)
  TAKEN_APPOINTMENT: 4, //占用(预约锁定)
  FREE: 1, // 空闲
  FAULT: 255, // 故障
  OFFLINE: 0, // 离网
};

export const PileStatusI18N = {
  0: "离网",
  1: "空闲",
  2: "占用(未充电)",
  3: "占用(充电中)",
  4: "占用(预约锁定)",
  255: "故障",
};

export const PileParkStatusI18N = {
  0: "未知",
  10: "空闲",
  50: "已上锁",
};

export const PileLockStatusI18N = {
  0: "未知",
  10: "已解锁",
  50: "占用",
};

export const PileTypeI18N = {
  1: "直流设备",
  2: "交流设备",
  3: "交直流一体设备",
  4: "无线设备",
  5: "其他",
};

export const ZIndex = {
  componentModalBox: 2000,
  componentMapFont: {
    classBefore: 1,
    elementBefore: 2,
    svg: 3,
  },
  componentTrailMapPath: 100,
  componentBusMap: {
    oldM: 100,
    newM: 101,
    alertLevels: [
      1, // level0
      97, // level1
      98, // level2
      99, // level3
    ],
  },
  monitorAlert: 100,
  caobaoluScreen: 100,
  caobaoluScreenMap: 2000,
  wuzhongluScreenMid: 2000,
  wuzhongluScreenMap: 2000,
  caobaoluCircleGraph: {
    before: 1,
    after: 2,
    precent: 3,
  },
};

// 国家电网充电时段定义

export const ChargePeriod = {
  height: "height",
  low: "low",
  normal: "normal",
};

export const QSohStatusColors = {
  Health: "#48993e",
  SubHealth: "#FFD159",
  Sick: "#E4821C",
};

export const QSohStatusI18N = {
  Health: "健康",
  SubHealth: "亚健康",
  Sick: "需检修",
};

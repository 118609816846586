// import React, { Component } from "react";
import { Base as UIBase } from "react-amapui-wrapper";
import { ZIndex } from "../../constants";

class PathSimplifier extends UIBase {
  constructor(props) {
    super(props);
    this.instanceName = "_pathSimplifier";
  }

  initialInstance() {
    if (this[this.instanceName]) {
      return new Promise(resolve => {
        resolve(this[this.instanceName]);
      });
    } else {
      return new Promise(resolve => {
        this.amapui.load(["ui/misc/PathSimplifier"], PathSimplifier => {
          this[this.instanceName] = new PathSimplifier({
            zIndex: ZIndex.componentTrailMapPath,
            map: this.map, //所属的地图实例
            getPath: function(pathData, pathIndex) {
              //返回轨迹数据中的节点坐标信息，[AMap.LngLat, AMap.LngLat...] 或者 [[lng|number,lat|number],...]
              return pathData.path;
            },
            getHoverTitle: () => null,
            renderOptions: {
              //轨迹线的样式
              pathLineStyle: {
                strokeStyle: "#00AB37",
                lineWidth: 4,
                dirArrowStyle: true,
              },
              pathLineHoverStyle: {
                strokeStyle: "#009737",
              },
              pathLineSelectedStyle: {
                strokeStyle: "#008337",
              },
              startPointStyle: {
                fillStyle: "#00AB37",
              },
              endPointStyle: {
                fillStyle: "#00AB37",
              },
            },
          });

          const events = this.exposeInstance(this.props);
          events && this.bindEvents(events);

          if (this.props.initComplete) {
            this.props.initComplete(this[this.instanceName]);
          }

          // 加载轨迹纠偏插件
          resolve(this[this.instanceName]);
        });
      });
    }
  }

  componentWillUnmount() {
    delete this[this.instanceName];
  }
}

export default PathSimplifier;

import React from "react";
import { Row, Col, Descriptions, Icon } from "antd";
import styled from "styled-components";

import { ymdhms, getNsName } from "../../lib";
import i18n from "../../i18n";

export default function TicketEvent({
  action,
  createdAt,
  createdBy = {},
  from,
  to,
  content,
  border,
}) {
  return (
    <MyRow border={border} gutter={24}>
      <Col span={6}>
        <UserArea>
          <Icon type="user" />
          <div className="username">
            {createdBy.ns && getNsName(createdBy.ns[0])
              ? getNsName(createdBy.ns[0])
              : createdBy.name}
          </div>
        </UserArea>
      </Col>
      <Col span={18}>
        <Descriptions column={2} size="small">
          <Descriptions.Item label="处置方式">
            {i18n.TicketAction[action]}
          </Descriptions.Item>
          <Descriptions.Item label="处置时间">
            {ymdhms(createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label="处置人">{createdBy.name}</Descriptions.Item>
          <Descriptions.Item label="状态修改">
            {from && to && `${from.name} -> ${to.name}`}
          </Descriptions.Item>
          <Descriptions.Item label="处置结果说明" span={2}>
            {content}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </MyRow>
  );
}

const MyRow = styled(Row)`
  border-bottom: ${props => (props.border ? "1px solid #ebedf0" : "")};
  margin: 12px;
`;

const UserArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .anticon-user {
    font-size: 40px;
    color: #999;
  }

  .username {
    margin-top: 4px;
  }
`;

import {
  SAFE_URL,
  MENU_MONITOR,
  MENU_OP,
  MENU_EXCEPTION,
  MENU_EXCEPTION_BATTERY,
  MENU_EXCEPTION_INSULATION,
  MENU_EXCEPTION_TYRE,
  MENU_BATTERY,
  MENU_BATTERY_QSOH,
  MENU_BATTERY_SOH,
  MENU_BATTERY_WARNING,
  MENU_CHARGING,
  MENU_SAFE,
  MENU_ENERGY,
  MENU_SETTINGS,
  MENU_SETTINGS_ARCHIVE,
  MENU_SETTINGS_TERMINAL,
  MENU_SETTINGS_BANCI,
  MENU_SETTINGS_USER,
  MENU_SETTINGS_APP,
  MENU_SETTINGS_PILE,
  MENU_EXCEPTION_AIR,
  MENU_EXCEPTION_DRIVING,
  OVERVIEW_SWITCH,
  MENU_PILE,
  ENERGY_URL,
  MENU_EXCEPTION_DCDC,
} from "../../config/env";
import { Privileges } from "../../config/privileges";

const menu = [
  {
    title: "服务概览",
    icon: "icon-mileage",
    path: "/overview",
    key: "overview",
    switch: OVERVIEW_SWITCH,
    privilege: Privileges.OVERVIEW,
  },
  {
    title: "实时监控",
    icon: "location",
    color: "#44c242",
    path: "/monitor",
    key: "monitor",
    privilege: Privileges.MONITOR,
    switch: MENU_MONITOR,
  },
  {
    title: "报警处置",
    icon: "alarm",
    color: "#ff4e2f",
    path: "/op",
    key: "op",
    privilege: Privileges.OP,
    switch: MENU_OP,
  },
  {
    title: "充电管理",
    icon: "chargingpile",
    color: "#44c242",
    path: "/pile",
    key: "pile",
    privilege: Privileges.PILE,
    switch: MENU_PILE,
  },
  {
    title: "异常管理",
    icon: "archives",
    color: "#ffa941",
    path: "/exception",
    key: "exception",
    privilege: Privileges.EXCEPTION,
    switch: MENU_EXCEPTION,
    children: [
      {
        title: "电池异常",
        path: "/battery",
        key: "battery",
        privilege: Privileges.EXCEPTION.BATTERY,
        switch: MENU_EXCEPTION_BATTERY,
      },
      {
        title: "绝缘异常",
        path: "/insulation",
        key: "insulation",
        privilege: Privileges.EXCEPTION.INSULATION,
        switch: MENU_EXCEPTION_INSULATION,
      },
      {
        title: "低压异常",
        path: "/dcdc",
        key: "dcdc",
        privilege: Privileges.EXCEPTION.DCDC,
        switch: MENU_EXCEPTION_DCDC,
      },
      {
        title: "轮胎异常",
        path: "/tyre",
        key: "tyre",
        privilege: Privileges.EXCEPTION.TYRE,
        switch: MENU_EXCEPTION_TYRE,
      },
      {
        title: "空调异常",
        path: "/air",
        key: "air",
        privilege: Privileges.EXCEPTION.TYRE,
        switch: MENU_EXCEPTION_AIR,
      },
      {
        title: "驾驶行为异常",
        path: "/driving",
        key: "driving",
        privilege: Privileges.EXCEPTION.TYRE,
        switch: MENU_EXCEPTION_DRIVING,
      },
    ],
  },
  {
    title: "电池管理",
    icon: "battery-80-20",
    color: "#40A9FF",
    path: "/battery",
    key: "battery",
    privilege: Privileges.BATTERY,
    switch: MENU_BATTERY,
    children: [
      {
        path: "/qsoh",
        title: "SOH快速诊断",
        key: "qsoh",
        privilege: Privileges.BATTERY.QSOH,
        switch: MENU_BATTERY_QSOH,
      },
      {
        path: "/soh",
        title: "SOH深度分析",
        key: "soh",
        privilege: Privileges.BATTERY.SOH,
        switch: MENU_BATTERY_SOH,
      },
      {
        path: "/warning",
        title: "电池预警",
        key: "warning",
        privilege: Privileges.BATTERY.WARNING,
        switch: MENU_BATTERY_WARNING,
      },
    ],
  },
  {
    path: "/charging",
    title: "充电管理",
    icon: "chargingpile",
    iconSize: 22,
    color: "#fba640",
    key: "charging",
    privilege: Privileges.CHARGING,
    switch: MENU_CHARGING,
  },
  {
    url: SAFE_URL,
    path: "/safe",
    title: "辅助驾驶",
    icon: "safe",
    iconSize: 20,
    color: "#ff4e2f",
    key: "safe",
    privilege: Privileges.SAFE,
    switch: MENU_SAFE,
  },
  {
    url: ENERGY_URL !== "null" ? ENERGY_URL : undefined,
    path: "/energy",
    title: "能耗管理",
    icon: "energy",
    iconSize: 20,
    color: "#44c242",
    key: "energy",
    privilege: Privileges.ENERGY,
    switch: MENU_ENERGY,
  },
  {
    title: "系统设置",
    icon: "setting",
    color: "#e8e8e8",
    key: "settings",
    path: "/settings",
    privilege: Privileges.SETTINGS,
    switch: MENU_SETTINGS,
    children: [
      {
        path: "/archive",
        title: "车辆档案",
        key: "archive",
        privilege: Privileges.SETTINGS.ARCHIVE,
        switch: MENU_SETTINGS_ARCHIVE,
      },
      {
        path: "/terminal",
        title: "终端管理",
        key: "terminal",
        privilege: Privileges.SETTINGS.TERMINAL,
        switch: MENU_SETTINGS_TERMINAL,
      },
      {
        path: "/banci",
        title: "路单查询",
        key: "banci",
        privilege: Privileges.SETTINGS.BANCI,
        switch: MENU_SETTINGS_BANCI,
      },
      {
        path: "/user",
        title: "用户管理",
        key: "user",
        privilege: Privileges.SETTINGS.USER,
        switch: MENU_SETTINGS_USER,
      },
      {
        path: "/app",
        title: "APP设置",
        key: "app",
        privilege: Privileges.SETTINGS.APP,
        switch: MENU_SETTINGS_APP,
      },
      {
        path: "/pile-set",
        title: "充电设置",
        key: "pile-set",
        privilege: Privileges.SETTINGS.PILE,
        switch: MENU_SETTINGS_PILE,
      },
    ],
  },
];

export default menu;

import { get } from "lodash";
import { createSelector } from "reselect";

import { ROOT_NS } from "../config";
import { listToTree } from "../lib";

export const makeNsTreeSelector = (nsSelector, withRoot = false) =>
  createSelector(
    nsSelector,
    state => {
      const { result = [] } = state;
      const nodes = result
        .filter(ns => withRoot || ns.id !== ROOT_NS)
        .map(ns => {
          return {
            title: ns.name,
            parent: get(ns, "parent.id"),
            key: ns.id,
            value: ns.id,
            data: ns,
            type: "namespace",
          };
        });
      return { ...state, result: listToTree(nodes), nodes };
    }
  );

export const makeLinesByNsSelector = (linesSelector, nsIdSelector) =>
  createSelector(
    linesSelector,
    nsIdSelector,
    ({ result = [] } = {}, nsId) => {
      if (!nsId) return result;
      return result.filter(({ ns } = {}) => ns.id && ns.id.startsWith(nsId));
    }
  );

import {
  apiReducerRoot,
  assignReducerRoot,
  toggleReducerRoot,
  cronReducerRoot,
  progressReducerRoot,
  formReducerRoot,
} from "@36node/redux";
import { xlsxReducerRoot } from "@36node/redux-xlsx";

const reducers = {
  ...apiReducerRoot,
  ...assignReducerRoot,
  ...toggleReducerRoot,
  ...cronReducerRoot,
  ...progressReducerRoot,
  ...formReducerRoot,
  ...xlsxReducerRoot,
};

export default reducers;

import { get, keyBy, isEmpty } from "lodash";
import { createSelector } from "reselect";
import moment from "moment";

import {
  listToTree,
  formatValue,
  formatNil,
  getNsName,
  getNsCompany,
  ymdhms,
  ymd,
  delYear,
} from "../lib";
import { OFFLINE_THRESHOLD, ROOT_NS } from "../config";
import {
  ChargeStatus,
  VehicleState,
  VehicleStatus,
  TerminalStatus,
  AlertStatus,
} from "../constants";

// 计算车辆状态
export const getVehicleStatus = (vehicle = {}) => {
  const vehicleStatus = get(vehicle, "overall.status");
  if (vehicleStatus) return vehicleStatus;
  return VehicleStatus.ABNORMAL;
};

// 计算车辆运营状态
const getVehicleState = (vehicle = {}) => {
  // 未接入
  if (!vehicle.onsite) return VehicleState.OFFSITE;

  // 维修
  if (vehicle.repairing) return VehicleState.REPAIRING;

  // 离线
  if (
    new Date().getTime() - new Date(vehicle.reportedAt).getTime() >
    OFFLINE_THRESHOLD
  )
    return VehicleState.OFFLINE;

  // 充电
  const chargeStatus = get(vehicle, "overall.chargeStatus");
  if (chargeStatus && [ChargeStatus.PARK_CHARGING].includes(chargeStatus))
    return VehicleState.CHARGING;

  // 运营中
  return VehicleState.RUNNING;
};

const getAlertStatus = (vehicle = {}) => {
  // 报警
  const maxLevel = get(vehicle, "alert.maxLevel");
  if (maxLevel === 3) return AlertStatus.ALERT3;
  if (maxLevel === 2) return AlertStatus.ALERT2;
  if (maxLevel === 1) return AlertStatus.ALERT1;
  return AlertStatus.ALERT0;
};

const getState = (vehicle = {}) => {
  const vehicleState = getVehicleState(vehicle);
  const alertStatus = getAlertStatus(vehicle);

  // 如果车辆处于离线状态，且最后上报时间是在昨天，则忽略报警状态，直接返回为车辆离线
  if (
    vehicleState === VehicleState.OFFLINE &&
    moment(vehicle.reportedAt).isBefore(moment().startOf("day"))
  ) {
    return vehicleState;
  }

  return alertStatus !== AlertStatus.ALERT0 ? alertStatus : vehicleState;
};

const getLocString = loc =>
  loc && loc.lng && loc.lat && `${loc.lng.toFixed(2)}N,${loc.lat.toFixed(2)}E`;

export const makeVehicleSelector = selector =>
  createSelector(
    selector,
    vehilceState => {
      const vehicle = vehilceState.result || {};
      const result = {
        ...vehicle,
        department: formatValue(vehicle, "ns", getNsName),
        state: getState(vehicle),
        at: formatValue(vehicle, "at", ymdhms),
        reportedAt: formatValue(vehicle, "reportedAt", ymdhms),
        locString: getLocString(vehicle.location),
      };
      return { ...vehilceState, result };
    }
  );

export const makeVehicleListWithMonitorSelector = selector =>
  createSelector(
    selector,
    vehilcesState => {
      const vehicles = vehilcesState.result || {};
      const result = vehicles.map(v => {
        return {
          ...v,
          state: getState(v),
        };
      });
      return { ...vehilcesState, result };
    }
  );

export const makeVehicleListWithTableSelector = selector =>
  createSelector(
    selector,
    vehilcesState => {
      const vehicles = vehilcesState.result || {};
      const result = vehicles.map(v => ({
        ...v,
        department: formatValue(v, "ns", getNsName),
        company: formatValue(v, "ns", getNsCompany),
        at: formatValue(v, "at", ymdhms),
        reportedAt: formatValue(v, "reportedAt", ymdhms),
        locString:
          v.location &&
          `${v.location.lng.toFixed(2)}N,${v.location.lat.toFixed(2)}E`,
        // vin: v.id,
      }));
      return { ...vehilcesState, result };
    }
  );

export const makeVehicleTreeSelector = (
  listVehicleSelector,
  listLineSelector,
  listNsSelector
) =>
  createSelector(
    listVehicleSelector,
    listLineSelector,
    listNsSelector,
    (vehiclesState, linesState, namespacesState) => {
      const vehicles = vehiclesState.result || [];
      const lines = linesState.result || [];
      const namespaces = namespacesState.result || [];
      const nodes = [];

      namespaces
        .filter(ns => ns.id && ns.id.match(new RegExp(`${ROOT_NS}/c\\d`))) // TODO: hardcode 确保只有公司下的namespace，需要调整 namespace
        .forEach(ns => {
          nodes.push({
            parent: get(ns, "parent.id"),
            title: ns.name,
            key: ns.id,
            value: ns.id,
            data: ns,
            type: "namespace",
          });
        });
      lines.forEach(line => {
        nodes.push({
          parent: get(line, "ns.id", "notfound"),
          title: line.name,
          key: line.id,
          value: line.id,
          data: line,
          type: "line",
        });
      });
      vehicles.forEach(v => {
        nodes.push({
          parent: get(v, "line.id"),
          title: v.no,
          key: v.id,
          value: v.id,
          data: v,
          type: "vehicle",
        });
      });
      const result = listToTree(nodes);

      return { ...vehiclesState, result, nodes };
    }
  );

export const makeVehicleListSohSelector = selector =>
  createSelector(
    selector,
    state => {
      const result = state.result || [];
      return {
        ...state,
        result: result.map(r => ({
          ...r,
          battery: "LFP",
          plate: r.plate,
          vehicleNo: r.no,
          soh: r.soh,
          line: r.line,
          vehiclePlateAt: r.platedAt,
          warningDate: ymd(r.sohUpdatedAt),
        })),
      };
    }
  );

/**
 * 计算是否GPS定位
 * @param {*} record
 */
const calcIsLocation = record => {
  const state = record.terminalStatus;

  // 车辆不在线
  if (state !== TerminalStatus.ONLINE) {
    return "--";
  }

  const { location = {} } = record;

  const { lng = 0, lat = 0 } = location;

  if (lng !== 0 && lat !== 0) {
    return "已定位";
  }

  return "未定位";
};

/**
 * 计算离线时长
 * @param {object} record 记录
 */
const calcOffLineTime = record => {
  const { onsite, at } = record;
  if (!onsite || !at) return "从未登陆";

  const tenMinAgo = moment().subtract(10, "minute");

  if (moment(at).isBefore(tenMinAgo)) {
    return ymdhms(at);
  } else {
    return "--";
  }
};

export const makeVehicleListTerminalSelector = selector =>
  createSelector(
    selector,
    listState => {
      const result = listState.result || [];

      return {
        ...listState,
        result: result.map(r => ({
          ...r,
          location: calcIsLocation(r),
          at: calcOffLineTime(r),
          arriveAt: r.arriveAt ? ymdhms(r.arriveAt) : "从未更新",
        })),
      };
    }
  );

export const makeVehicleListArchiveSelector = selector =>
  createSelector(
    selector,
    state => {
      const result = state.result || [];

      return {
        ...state,
        result: result.map(r => ({
          ...r,
          updatedAt: formatValue(r, "updatedAt", ymdhms),
          platedAtStr: formatValue(r, "platedAt", ymd),
          lifeYearStr: formatNil(r, "lifeYear"),
          labels: formatValue(r, "labels", (val = []) => {
            return isEmpty(val.filter(Boolean)) ? "--" : val.join(",");
          }),
          repairWorkshop: formatValue(r, "repairWorkshop", val => val || "--"),
        })),
      };
    }
  );

export const makeVehicleXlsxSelector = (nsSelector, xlsxSelector) =>
  createSelector(
    nsSelector,
    xlsxSelector,
    (nsState, xlsxState) => {
      const vehicles = xlsxState.result || [];
      const namespaces = nsState.result || [];

      // 建立 名称到ns 的反向映射
      const M = keyBy(namespaces, it => getNsName(it));
      // 转换ns
      const result = vehicles.map(v => {
        let data = {
          ...v,
          ns: get(M[v.department], "id"),
          updatedAt: undefined,
          platedAt: v.platedAtStr,
          lifeYear: delYear(v.lifeYearStr),
        };

        if (data.labels && data.labels !== "--") {
          data.labels = data.labels.split(",");
        } else {
          data.labels = [];
        }

        if (!data.repairWorkshop || data.repairWorkshop === "--") {
          data.repairWorkshop = "";
        }

        delete data["department"];
        delete data["platedAtStr"];
        delete data["lifeYearStr"];

        return data;
      });

      return { ...xlsxState, result };
    }
  );

export const makeMileageStatisticsSelector = listMileagesSelector =>
  createSelector(
    listMileagesSelector,
    mileagesState => {
      const mileages = mileagesState.result || [];
      const result = mileages.filter(item => item.value > 0);
      let data = new Array(12).fill([]).map(() => new Array(31).fill(""));
      let mileageTotal = 0;
      if (result.length) {
        result.forEach(item => {
          const month = moment(item.at).format("MM") - 1;
          const day = moment(item.at).format("DD") - 1;
          data[month][day] = item.value.toFixed(0);
          mileageTotal += item.value;
        });
      }
      // 运营天数
      const runningDays = result.length;
      // 平均每日运行
      const average = (mileageTotal / runningDays).toFixed(2);
      // 200公里以上天数
      const over200Days = result.filter(item => item.value >= 200).length;
      // 50~200公里天数
      const over50Days = result.filter(
        item => (item.value >= 50) & (item.value < 200)
      ).length;
      // 0~50公里天数
      const over0Days = result.filter(
        item => (item.value >= 0) & (item.value < 50)
      ).length;
      // 未运营天数
      const stopDays =
        moment().diff(moment().startOf("year"), "days") - runningDays;
      // 节假日天数
      const holidays = 27;
      return {
        ...mileagesState,
        data,
        mileageTotal: mileageTotal.toFixed(2),
        runningDays,
        average,
        over200Days,
        over50Days,
        over0Days,
        stopDays,
        holidays,
      };
    }
  );

/**
 * @param {*} selector
 */
export const makeRecordsTableSelector = faultsSelector => selector =>
  createSelector(
    faultsSelector,
    selector,
    (faultsState, recordState) => {
      const faults = faultsState.result || [];

      const records = recordState.result || [];
      const result = records.map(r => {
        // 计算alertInfo
        const alarm = get(r, "body.alarm");
        const body = r.body || {};

        let alertInfo;
        if (alarm) {
          const {
            ressList = [],
            mortorList = [],
            engineList = [],
            otherList = [],
            _uasList = [],
          } = alarm;

          const alerts = [
            ...ressList,
            ...mortorList,
            ...engineList,
            ...otherList,
            ..._uasList,
          ];

          alertInfo =
            alerts.length > 0
              ? alerts
                  .map(a => {
                    const faultCode =
                      ((a.type << 24) >>> 0) + (a.code << 8) + a.level;
                    const fault = faults.find(f => f.code === faultCode);
                    return fault;
                  })
                  .filter(f => f && f.code)
                  .map(f => `${f.code.toString(16)}-${f.name}`)
                  .join(",")
              : "--";
        }
        return {
          ...body,
          ...r,
          reportedAt: ymdhms(r.recordedAt),
          at: ymdhms(r.at ? r.at : body.at),
          motors: get(r, "body.motor.motors", []),
          alertInfo,
          locString: getLocString(body.location),
        };
      });
      return { ...recordState, result };
    }
  );

/**
 * Records 的数据结构和 vehicle 不太一样，这个 selector 会把它统一了
 * @param {*} selector
 */
export const makeRecordListSelector = selector =>
  createSelector(
    selector,
    recordState => {
      const records = recordState.result || [];
      const result = records.map(r => {
        const body = r.body || {};
        return {
          ...body,
          ...r,
          reportedAt: ymdhms(r.recordedAt),
          at: ymdhms(r.at ? r.at : body.at),
          motors: get(r, "body.motor.motors", []),
          locString: getLocString(body.location),
        };
      });
      return { ...recordState, result };
    }
  );

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { select, put } from "redux-saga/effects";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Form, Button, Row, Col, Spin } from "antd";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { get, isNil, cloneDeep } from "lodash";
import {
  makeApiSelector,
  makeAssign,
  makeAssignSelector,
  withSaga,
  tapOnLatest,
} from "@36node/redux";
import { createForm } from "@36node/redux-antd";

import { log } from "../../actions/api";
import { LOG_VEHICLE_LIST_RECORDS } from "../../actions/types";
import { DateDetailRange, Vehicle } from "../../components/fields";
import {
  getColumn,
  genVehilcleColumns,
} from "../../components/vehicle/columns";
import { makeRecordListSelector } from "../../selectors";
import { ymdhms, getFormatDate } from "../../lib";
import { VehicleModelDisplayData, DataCategory } from "../../constants";

const listRecord = log.vehicle.makeListRecords("vehicle.replay", {
  query: {
    limit: 10000,
    sort: "at",
  },
});
const dataTypeAssign = makeAssign("vehicle.replay");

const listRecordSelector = makeRecordListSelector(
  makeApiSelector("vehicle.replay")
);
const dataTypeAssignSelector = makeAssignSelector("vehicle.replay");
const getVehicleSelector = makeApiSelector("vehicle.detail");

const tenSecondsKey = "tenSeconds";
const tenSecondsPrefix = "tenSeconds.datas";
const tenSecondsType = {
  "overall.speed": "speed",
  "overall.aptv": "accPedal",
  "overall.brake": "brake",
  "overall.current": "totalCurrent",
};

@withRouter
@withSaga(
  tapOnLatest(LOG_VEHICLE_LIST_RECORDS.SUCCESS, "vehicle.replay", function*(
    action
  ) {
    const resp = yield select(listRecordSelector);
    // 判断无法获取秒传数据
    if (!resp.total) {
      const request = resp.request;
      if (tenSecondsKey === get(request, "query._exist")) {
        // 获取key
        const selectData = get(request, "query.select");
        if (
          selectData[0] &&
          selectData[0].startsWith(`body.${tenSecondsPrefix}`)
        ) {
          const tskey = selectData[0].substring(
            selectData[0].lastIndexOf(".") + 1
          );
          const key = Object.keys(tenSecondsType).find(
            k => tenSecondsType[k] === tskey
          );
          let finalRequest = cloneDeep(request);
          finalRequest.query.select[0] = `body.${key}`;
          finalRequest.query._exist = "overall";
          yield put(listRecord(finalRequest));
        }
      }
    }
  })
)
@createForm("vehicle.replay")
@connect(state => ({
  vehicle: getVehicleSelector(state).result,
}))
class ReplayForm extends PureComponent {
  initTypeKey = "overall.soc";

  state = {
    initTime: [
      moment().subtract(70, "minutes"),
      moment().subtract(10, "minutes"),
    ],
  };

  get vehicleId() {
    return get(this.props.match, "params.vehicleId");
  }

  componentDidMount() {
    const { initTime } = this.state;

    this.fetchData(this.initTypeKey, initTime);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { dataType, startDate, endDate, startTime, endTime } = values;
        const { initTime } = this.state;

        let at_gt = getFormatDate(startDate, startTime) || initTime[0];
        let at_lt = getFormatDate(endDate, endTime) || initTime[1];

        this.fetchData(dataType, [at_gt, at_lt]);
      }
    });
  };

  handleReset = e => {
    const at_gt = moment().subtract(70, "minutes");
    const at_lt = moment().subtract(10, "minutes");
    this.setState({ initTime: [at_gt, at_lt] });
    this.fetchData(this.props.form.getFieldValue("dataType"), [at_gt, at_lt]);
  };

  getTreeData = () => {
    const { vehicle } = this.props;
    const columns = genVehilcleColumns(vehicle.modelBrief, [
      "vin",
      "at",
      "reportedAt",
      "adas",
    ]);

    const treeData = columns.map(s => {
      return {
        title: s.title,
        value: s.key || s.dataIndex,
        selectable: false,
        children: s.children
          ? s.children
              .filter(d => d.type === "number")
              .map(d => ({
                title: d.title,
                value: d.key || d.dataIndex,
                isLeaf: true,
              }))
          : undefined,
      };
    });
    return treeData;
  };

  get modelBrief() {
    return get(this.props, "vehicle.modelBrief");
  }

  /**
   * 是否有十秒数据
   */
  get hasTenSecond() {
    return (VehicleModelDisplayData[this.modelBrief] || []).includes(
      DataCategory.TEN_SECONDS
    );
  }

  fetchData = (key, at) => {
    if (!key) return;

    let finalKey = key;

    if (this.hasTenSecond) {
      if (key in tenSecondsType)
        finalKey = `${tenSecondsPrefix}.${tenSecondsType[key]}`;
    }

    let request = {
      vehicleId: this.vehicleId,
      query: { filter: {} },
    };
    request.query.filter.at_gt = at[0].toISOString();
    request.query.filter.at_lt = at[1].toISOString();

    const [select] = finalKey.split(/\.|\[/);
    if (select) {
      request.query.select = [`body.${finalKey}`, "body.at"];
      request.query._exist = select;
    }
    this.props.dispatch(listRecord(request));
    this.props.dispatch(dataTypeAssign(getColumn(key)));
  };

  render() {
    const { form } = this.props;
    const { initTime } = this.state;
    return (
      <StyledFilterForm layout="inline" onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={18}>
            <DateDetailRange form={form} initialValue={initTime} />
          </Col>
          <Col span={6}>
            <Vehicle.DataType
              {...formItemLayout}
              initialValue={this.initTypeKey}
              dataSource={this.getTreeData()}
              form={form}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right", marginTop: 10 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                筛选
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                重置
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledFilterForm>
    );
  }
}

const getFetchData = (request, fetchData, typeKey) => {
  let data = [];
  if (get(request, "query._exist") === tenSecondsKey) {
    fetchData.forEach(d => {
      let start = d.at;
      get(d, tenSecondsPrefix, []).forEach((c, index) => {
        if (!isNil(c)) {
          data.push({
            value: c[tenSecondsType[typeKey]],
            time: ymdhms(moment(start).add(index, "s")),
          });
        }
      });
    });
  } else {
    fetchData.forEach(d => {
      const value = get(d, typeKey);
      if (!isNil(value)) {
        data.push({
          value,
          time: d.at,
        });
      }
    });
  }
  return data;
};

@connect(state => {
  const column = dataTypeAssignSelector(state);
  const fetchedData = listRecordSelector(state).result;
  const loading = listRecordSelector(state).loading;
  const request = listRecordSelector(state).request;
  const typeKey = column.key || column.dataIndex;

  const data = getFetchData(request, fetchedData, typeKey);

  return {
    data,
    column,
    loading,
  };
})
class DataChart extends PureComponent {
  getOption = () => {
    const { column, data } = this.props;
    const xData = data.map(d => moment(d.time).format("HH:mm:ss"));

    const { formatter = val => val, title } = column;

    return {
      xAxis: {
        type: "category",
        data: xData,
      },
      grid: {
        right: 48,
        left: 64,
      },
      yAxis: {
        type: "value",
        axisLabel: { formatter: formatter },
      },
      tooltip: {
        trigger: "axis",
        formatter: function(params) {
          const param = params[0];
          const { value, axisValueLabel } = param;
          const formated = formatter(value);
          return `
            <div>
              <div>${axisValueLabel}</div>
              <div>${title}: ${formated}</div>
            </div>
          `;
        },
      },
      series: [
        {
          data: data.map(d => d.value),
          type: "line",
        },
      ],
    };
  };

  render() {
    const { loading, data = [] } = this.props;

    if (loading) return <Spin />;

    if (data.length === 0) {
      return <div className="no-data">暂无数据</div>;
    }

    return (
      <ReactEcharts
        style={{ width: "100%", height: "100%" }}
        option={this.getOption()}
      />
    );
  }
}

export default class Replay extends PureComponent {
  render() {
    return (
      <Container>
        <ReplayForm />
        <Body>
          <DataChart />
        </Body>
      </Container>
    );
  }
}

const Container = styled.div`
  height: 100%;
  overflow-x: scroll;
  background-color: #fff;
`;

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};

const StyledFilterForm = styled(Form)`
  margin-top: 12px !important;
  padding: 0px 30px !important;

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .pack-filter {
    display: flex;
    justify-content: center;

    .ant-btn {
      border: 0;
    }
  }
`;

const Body = styled.div`
  height: calc(100% - 112px);
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  .no-data {
    font-size: 40px;
    font-weight: 600;
    color: #aaa;
  }
`;

/**
 * 车辆仪表盘
 */

import React, { PureComponent } from "react";
import ReactEcharts from "echarts-for-react";
import { get } from "lodash";

export default class VehicleDashBoard extends PureComponent {
  render() {
    const { record = {} } = this.props;

    const vehicleSpeed = get(record, "overall.speed", 0).toFixed(0);
    const motorSpeed = get(record, "motor.motors[0].speed", 0).toFixed(0);
    const soc = (get(record, "overall.soc", 0) * 100).toFixed(0);
    const option = {
      tooltip: {
        formatter: "{a} <br/>{c} {b}",
      },
      series: [
        {
          name: "车辆时速",
          type: "gauge",
          z: 3,
          min: 0,
          max: 220,
          splitNumber: 11,
          radius: "100%",
          axisLine: {
            lineStyle: {
              width: 5,
            },
          },
          axisTick: {
            length: 10,
            lineStyle: {
              color: "auto",
            },
          },
          splitLine: {
            length: 15,
            lineStyle: {
              color: "auto",
            },
          },
          axisLabel: {
            backgroundColor: "auto",
            borderRadius: 1,
            color: "#eee",
            padding: 2,
            textShadowBlur: 1,
            textShadowOffsetX: 1,
            textShadowOffsetY: 1,
            textShadowColor: "#222",
          },
          title: {
            fontWeight: "bolder",
            fontSize: 14,
            fontStyle: "italic",
          },
          detail: {
            formatter: function(value) {
              value = (value + "").split(".");
              value.length < 2 && value.push("00");
              return (
                ("00" + value[0]).slice(-2) +
                "." +
                (value[1] + "00").slice(0, 2)
              );
            },
            borderRadius: 2,
            backgroundColor: "#444",
            borderColor: "#aaa",
            shadowBlur: 2,
            shadowColor: "#333",
            shadowOffsetX: 0,
            shadowOffsetY: 2,
            borderWidth: 1,
            textBorderColor: "#000",
            textBorderWidth: 1,
            textShadowBlur: 1,
            textShadowColor: "#fff",
            textShadowOffsetX: 0,
            textShadowOffsetY: 0,
            fontFamily: "Arial",
            width: 26,
            fontSize: 16,
            color: "#eee",
            rich: {},
          },
          data: [
            {
              value: vehicleSpeed,
              name: "km/h",
            },
          ],
        },
        {
          name: "电机转速",
          type: "gauge",
          center: ["16%", "55%"],
          radius: "65%",
          min: 0,
          max: 7000,
          endAngle: 45,
          splitNumber: 7,
          axisLabel: {
            formatter: val => {
              if (val <= 7000) {
                return Math.ceil(val / 1000);
              }
              return 7;
            },
          },
          axisLine: {
            lineStyle: {
              width: 5,
            },
          },
          axisTick: {
            length: 10,
            lineStyle: {
              color: "auto",
            },
          },
          splitLine: {
            length: 15,
            lineStyle: {
              color: "auto",
            },
          },
          pointer: {
            width: 5,
          },
          title: {
            fontSize: 14,
            offsetCenter: [0, "-30%"],
          },
          detail: {
            fontWeight: "bolder",
            fontSize: 18,
          },
          data: [
            {
              value: motorSpeed,
              name: "r/ min",
            },
          ],
        },
        {
          name: "电池SoC容量",
          type: "gauge",
          center: ["85%", "50%"],
          radius: "80%",
          tooltip: {
            formatter: "{a} {c}% ",
          },
          min: 0,
          max: 100,
          startAngle: 135,
          endAngle: 45,
          splitNumber: 2,
          axisLine: {
            lineStyle: {
              width: 8,
            },
          },
          axisTick: {
            splitNumber: 5,
            length: 10,
            lineStyle: {
              color: "auto",
            },
          },
          axisLabel: {
            formatter: function(v) {
              switch (v + "") {
                case "0":
                  return "0";
                case "50":
                  return "电池SoC";
                case "100":
                  return "100%";
                default:
                  break;
              }
            },
          },
          splitLine: {
            length: 15,
            lineStyle: {
              color: "auto",
            },
          },
          pointer: {
            width: 2,
          },
          title: {
            show: false,
          },
          detail: {
            formatter: function(value) {
              return value + "%";
            },
            offsetCenter: ["10%", "25%"],
            fontWeight: "bolder",
            fontSize: 18,
          },
          data: [
            {
              value: soc,
              name: "电池SoC",
            },
          ],
        },
      ],
    };

    return (
      <ReactEcharts
        option={option}
        lazyUpdate={true}
        style={{
          width: "100%",
          height: "200px",
        }}
      />
    );
  }
}

import {
  ALERT_AUDIO_PLAY,
  ALERT_AUDIO_MUTE,
  ALERT_AUDIO_UNMUTE,
} from "./types";

function play() {
  return {
    type: ALERT_AUDIO_PLAY,
  };
}

function mute() {
  return {
    type: ALERT_AUDIO_MUTE,
  };
}

function unmute() {
  return {
    type: ALERT_AUDIO_UNMUTE,
  };
}

const AlertAudioActions = {
  play,
  mute,
  unmute,
};

export default AlertAudioActions;

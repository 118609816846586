import React from "react";
import { get, isEmpty } from "lodash";
import { Descriptions } from "antd";
import styled from "styled-components";

const renderItem = (item, val, data) => {
  if (typeof item.formatter === "function") return item.formatter(val, data);
  return val || "--";
};

export default ({ columns, data, span = 8 }) =>
  columns.map(col => {
    const { children = [], title, key } = col;
    if (isEmpty(children)) return null;
    return (
      <StyledDesc column={24 / span} title={title} key={key || title}>
        {children
          .filter(i => !!i)
          .map(item => {
            return (
              <Descriptions.Item
                label={item.title}
                key={item.key || item.dataIndex}
              >
                {renderItem(item, get(data, item.dataIndex), data)}
              </Descriptions.Item>
            );
          })}
      </StyledDesc>
    );
  });

const StyledDesc = styled(Descriptions)`
  margin-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
`;

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { makeApiSelector } from "@36node/redux";
import styled from "styled-components";
import { Card, Form, DatePicker, Spin, Table } from "antd";
import moment from "moment";
import { isEqual } from "lodash";

import { pile } from "../../../actions/api";
import { makeSgccSummarySelector } from "../../../selectors";
import { withTable } from "../../../components/withTable";
import { toThousands } from "../../../lib";

const pageKey = "chargingSgcc.summary";

/**
 * actions
 */
const listSgccSummary = pile.sgcc.makeGetSgccSummary(pageKey, {
  query: {
    _group: ["month", "department"],
    startAt_gte: moment()
      .startOf("year")
      .toISOString(),
    startAt_lte: moment()
      .endOf("year")
      .toISOString(),
  },
});

const listDepartments = pile.sgcc.makeListSgccFields("sgcc.fields.department", {
  query: {
    name: "department",
  },
});

/**
 * selectors
 */

const listDepartmentsSelector = makeApiSelector("sgcc.fields.department");

const StyledFilterForm = styled(Form)`
  padding-right: 12px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

/**
 * components
 */
class SearchForm extends PureComponent {
  state = {
    year: moment(),
    open: false,
  };

  handlePanelChange = value => {
    this.setState({
      year: value,
      open: false,
    });
    if (this.props.onFetch) {
      this.props.onFetch({
        query: {
          _group: ["month", "department"],
          startAt_gte: value.startOf("year").toISOString(),
          startAt_lte: value.endOf("year").toISOString(),
        },
      });
    }
  };

  handleOpenChange = status => {
    if (status) {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  };

  clearValue = () => {
    this.setState({
      year: null,
    });
  };

  render() {
    const { year, open } = this.state;

    return (
      <Card style={{ marginBottom: 24 }}>
        <StyledFilterForm layout="inline">
          <Form.Item label="选择统计日期">
            <DatePicker
              value={year}
              open={open}
              mode="year"
              format="YYYY"
              onOpenChange={this.handleOpenChange}
              onPanelChange={this.handlePanelChange}
              onChange={this.clearValue}
            />
          </Form.Item>
        </StyledFilterForm>
      </Card>
    );
  }
}

@connect((state, props) => ({
  departments: listDepartmentsSelector(state).result || [],
  loading: listDepartmentsSelector(state).loading || false,
}))
export default class extends PureComponent {
  componentWillMount() {
    if (this.props.departments.length) {
      this.setTable();
    } else {
      this.props.dispatch(listDepartments());
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.departments, this.props.departments) &&
      this.props.departments.length
    ) {
      this.setTable();
    }
  }

  setTable = () => {
    this.Wrapper = withTable(pageKey, {
      SearchForm,
      title: `年度充电数据汇总`,
      columns: this.getColumns(),
      list: listSgccSummary,
      makeListSelector: makeSgccSummarySelector,
    })(props => (
      <Table
        {...props}
        pagination={false}
        scroll={{ x: "max-content" }}
        rowKey="month"
      />
    ));
  };

  getColumns = () => {
    const { departments } = this.props;
    const columns = [
      {
        title: "",
        dataIndex: `month`,
        key: `month`,
        width: 100,
        fixed: "left",
      },
    ];
    departments.forEach(name => {
      columns.push({
        title: name,
        children: [
          {
            title: "充电量",
            dataIndex: `${name}.power`,
            key: `${name}.power`,
            render: x => x && toThousands(x),
          },
          {
            title: "平均单价",
            dataIndex: `${name}.average`,
            key: `${name}.average`,
            render: x => x && `￥${toThousands(x)}`,
          },
          {
            title: "金额",
            dataIndex: `${name}.price`,
            key: `${name}.price`,
            render: x => x && `￥${toThousands(x)}`,
          },
        ],
      });
    });
    return columns;
  };

  render() {
    const Wrapper = this.Wrapper;

    if (Wrapper) {
      return <Wrapper />;
    }
    return (
      <div style={{ textAlign: "center", padding: "24px 0" }}>
        <Spin />
      </div>
    );
  }
}

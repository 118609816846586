import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Select, Button, Icon } from "antd";
import styled from "styled-components";
import moment from "moment";
import { get, last, range } from "lodash";
import { makeApiSelector } from "@36node/redux";
import { createForm } from "@36node/redux-antd";
import { makeXlsx } from "@36node/redux-xlsx";

import { chart } from "../../actions/api";
import MileageCalendar from "./mileage-calendar";
import { makeMileageStatisticsSelector } from "../../selectors";
import { SYSTEM_START_DATE } from "../../config";

const Option = Select.Option;

const listMileages = chart.vehicle.makeListMileages("vehicle.mileages", {
  query: {
    limit: 370,
    group: "at",
    filter: {
      at_gt: "",
      at_lt: "",
    },
  },
});
const listMileagesSelector = makeApiSelector("vehicle.mileages");
const mileageStatisticsSelector = makeMileageStatisticsSelector(
  listMileagesSelector
);

const getVehicleSelector = makeApiSelector("vehicle.detail");

const getColumns = () => {
  const columns = range(31).map(i => ({
    title: `${i + 1}`,
    dataIndex: `${i + 1}`,
  }));

  return [
    {
      title: "月份",
      dataIndex: "name",
    },
    ...columns,
  ];
};

const xlsxActions = makeXlsx("vehicle.mileages.export", {
  columns: getColumns(),
});

const Body = styled.div`
  padding: 0 24px;

  table,
  table tr th,
  table tr td {
    border: 1px solid #acacac;
    padding: 8px 12px;

    span.title {
      font-weight: 600;
    }

    span:not(.title) {
      padding-right: 24px;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
`;

const Rectangle = styled.div`
  width: 36px;
  height: 20px;
  background: ${props => props.color || "#ccc"};
  border-radius: 2px;
  margin: 0 4px;
`;

const StyledForm = styled(Form)`
  padding: 24px 24px 0 24px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    flex-basis: 300px;
  }

  .right {
    display: flex;
    flex-grow: 4;
    justify-content: flex-end;
    margin-bottom: 24px;

    .download {
      display: flex;
      align-items: center;

      .ant-btn {
        border: 0;
      }
    }

    .legend {
      display: flex;
      align-items: center;
      padding-right: 48px;
      .item {
        padding: 0 4px;
        display: flex;
        align-items: center;
      }
    }
  }
`;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const getYears = () => {
  const ret = [];

  for (let it = moment(SYSTEM_START_DATE).year(); it <= moment().year(); it++) {
    ret.push(it);
  }

  return ret;
};

const formatExportData = data => {
  return data.map((value, i) => {
    let ret = {};
    ret.name = `${i + 1}月`;
    value.forEach((day, j) => {
      if (day) ret[`${j + 1}`] = day;
    });
    return ret;
  });
};

@withRouter
@createForm()
@connect(state => ({
  vehicle: getVehicleSelector(state).result || {},
  mileageData: mileageStatisticsSelector(state) || {},
}))
class MileageForm extends PureComponent {
  state = {
    year: last(getYears()),
  };

  onChange = value => {
    const { match } = this.props;
    this.props.dispatch(
      listMileages({
        vehicleId: get(match, "params.vehicleId"),
        query: {
          filter: {
            at_gt: moment(`${value}-01-01`)
              .subtract(1, "days")
              .valueOf(),
            at_lt: moment(`${value}-12-31`)
              .add(1, "days")
              .valueOf(),
          },
        },
      })
    );
    this.setState({ year: value });
  };

  onDownload = () => {
    const { year } = this.state;
    const { vehicle = {}, mileageData = {} } = this.props;
    const name = `${year}年${vehicle.no || ""}里程日历`;

    if (mileageData.data)
      this.props.dispatch(
        xlsxActions.export({
          name,
          type: "xlsx",
          rows: formatExportData(mileageData.data),
        })
      );
  };

  render() {
    const { year } = this.state;
    const years = getYears();

    return (
      <StyledForm>
        <div className="left">
          <Form.Item {...formItemLayout} label="选择年份">
            <Select value={year} onChange={this.onChange}>
              {years.map(y => (
                <Option value={y} key={y}>
                  {y}年
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="right">
          <div className="legend">
            <div className="item">
              <Rectangle color="#7FC064" />
              200公里以上
            </div>
            <div className="item">
              <Rectangle color="#648FDE" />
              50-200公里
            </div>
            <div className="item">
              <Rectangle color="#C54E4F" />
              0-50公里
            </div>
            <div className="item">
              <Rectangle color="#CACACA" />
              无里程数据
            </div>
          </div>
          <div className="download">
            <Button onClick={this.onDownload}>
              <Icon type="download" />
              下载报表
            </Button>
          </div>
        </div>
      </StyledForm>
    );
  }
}

@connect(state => ({
  mileageData: mileageStatisticsSelector(state) || {},
}))
@withRouter
class MileageBody extends PureComponent {
  componentDidMount() {
    const { match } = this.props;

    const years = getYears();

    const at_gt = moment()
      .year(last(years))
      .startOf("year")
      .subtract(1, "day")
      .valueOf();
    const at_lt = moment()
      .year(last(years))
      .endOf("year")
      .add(1, "day")
      .valueOf();

    this.props.dispatch(
      listMileages({
        vehicleId: get(match, "params.vehicleId"),
        query: {
          filter: {
            at_gt,
            at_lt,
          },
        },
      })
    );
  }
  render() {
    const { mileageData } = this.props;
    return (
      <Body>
        <MileageCalendar data={mileageData.data} />
        <table border="1">
          <tbody>
            <tr>
              <td>
                <span className="title">运营总里程： </span>
                {mileageData.mileageTotal} km
              </td>
              <td>
                <span className="title">平均日运营里程： </span>
                {mileageData.average} km
              </td>
            </tr>
            <tr>
              <td>
                <span className="title">运营总天数： </span>
                {mileageData.runningDays} 天
              </td>
              <td>
                <span>
                  <span className="title">200公里以上: </span>
                  {mileageData.over200Days} 天
                </span>
                <span>
                  <span className="title">50-200公里: </span>
                  {mileageData.over50Days} 天
                </span>
                <span>
                  <span className="title">0-50公里: </span>
                  {mileageData.over0Days} 天
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="title">未运营总天数： </span>
                {mileageData.stopDays} 天
              </td>
              <td>
                <span className="title">节假日总天数：</span>
                {mileageData.holidays} 天
              </td>
            </tr>
          </tbody>
        </table>
      </Body>
    );
  }
}

export default class VehicleMileage extends PureComponent {
  render() {
    return (
      <Container>
        <MileageForm />
        <MileageBody />
      </Container>
    );
  }
}

/**
 * 报警统计 按照类型分类
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import ReactEcharts from "echarts-for-react";

export default class ChartByType extends PureComponent {
  get colors() {
    return ["#1890FF", "#13C2C2", "#2FC25B", "#FACC14", "#F04864", "#8543E0"];
  }

  getOption = (data = []) => {
    return {
      color: this.colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      // 峰谷平尖
      legend: {
        data: [
          "峰时段充电量占比",
          "谷时段充电量占比",
          "平时段充电量占比",
          "尖时段充电量占比",
        ],
        x: "right",
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: data.map(d => d.name),
        },
      ],
      yAxis: [
        {
          name: "充电量占比 (%)",
          type: "value",
        },
      ],
      series: [
        {
          name: "峰时段充电量占比",
          type: "bar",
          barGap: "70%",
          barWidth: 20,
          data: data.map(d => d.value && d.value.length === 4 && d.value[0]),
        },
        {
          name: "谷时段充电量占比",
          type: "bar",
          barWidth: 20,
          data: data.map(d => d.value && d.value.length === 4 && d.value[1]),
        },
        {
          name: "平时段充电量占比",
          type: "bar",
          barWidth: 20,
          data: data.map(d => d.value && d.value.length === 4 && d.value[2]),
        },
        {
          name: "尖时段充电量占比",
          type: "bar",
          barWidth: 20,
          data: data.map(d => d.value && d.value.length === 4 && d.value[3]),
        },
      ],
    };
  };

  render() {
    const { data = [] } = this.props;

    const option = this.getOption(data);

    return (
      <Row>
        <Col span={24}>
          <ReactEcharts option={option} />
        </Col>
      </Row>
    );
  }
}

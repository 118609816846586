import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { makeApiSelector } from "@36node/redux";
import { Card, Spin } from "antd";

import { genVehilcleColumns } from "../../components/vehicle/columns";
import { makeVehicleSelector } from "../../selectors";
import Specs from "../../components/specs";

const getVehicleSelector = makeVehicleSelector(
  makeApiSelector("vehicle.detail")
);

@connect(state => ({
  vehicle: getVehicleSelector(state).result,
  loading: getVehicleSelector(state).loading,
}))
export default class VehicleDetail extends PureComponent {
  render() {
    const { vehicle } = this.props;
    const columns = genVehilcleColumns(vehicle.modelBrief);

    return (
      <Spin spinning={this.props.loading}>
        <Card>
          <Specs columns={columns} data={vehicle} span={8} />
        </Card>
      </Spin>
    );
  }
}

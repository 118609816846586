import React from "react";
import { isEmpty } from "lodash";

import i18n from "../../i18n";
import { withPercent, withUnit } from "../../lib";
import { SAFE_URL } from "../../config/env";

/**
 * 统一处理 车辆的数据展示格式
 *
 * 产生车辆的通用列
 * @param {string} model 车型简称
 * @returns [] columns
 */
export const genVehilcleColumns = (model = "", exKeys = []) => {
  const types = VehicleModelsDataMap[model];
  let columns = DefaultColumns;
  if (types) columns = columns.filter(c => types.includes(c.key));

  const exclude = cols => {
    if (!cols || isEmpty(exKeys)) return cols;
    return cols
      .filter(c => !exKeys.includes(c.key || c.dataIndex))
      .map(c => ({
        ...c,
        children: exclude(c.children),
      }));
  };

  return exclude(columns);
};

export function getColumn(key, hidden = false, columns = DefaultColumns) {
  for (let col of columns) {
    const colKey = col.key || col.dataIndex;
    if (colKey === key) return col;
    const { children = [] } = col;
    const found = children.find(
      col => col.key === key || col.dataIndex === key
    );
    if (found) return { ...found, hidden };
  }
}

/**
 * 不同的车型拥有的数据类型不一样
 *
 * https://docs.qq.com/sheet/DZXNVZkRtVWN0Vkhi?_from=1&tab=BB08J2&c=A1A0A0
 */
export const VehicleModelsDataMap = {
  LOB: ["basic", "battery", "motor"],
  L9E: ["basic", "battery", "motor"],
  S0M: ["basic", "battery", "motor"],
  S0P: ["basic", "ac", "battery", "motor", "dcdc"],
  S0Q: ["basic", "battery", "motor", "chargingBow", "dcdc"],
  S2V: ["basic", "battery", "motor"],
  S2W: ["basic", "battery", "motor"],
  S2Y: ["basic", "adas", "tyre", "ac", "battery", "motor", "dcdc"],
  Z0A: ["basic", "adas", "tyre", "ac", "battery", "motor", "dcdc"],
  Z1D: ["basic", "ac", "battery", "motor", "dcdc"],
  Z2C: ["basic", "ac", "battery", "motor", "dcdc"],
  Z2E: ["basic", "ac", "battery", "motor", "dcdc"],
  Z2F: ["basic", "ac", "battery", "motor", "dcdc"],
  Z2G: ["basic", "ac", "battery", "motor", "dcdc"],
  Z2H: ["basic", "ac", "battery", "motor", "dcdc"],
  Z5A: ["basic", "ac", "battery", "motor", "dcdc"],
  Z8B: ["basic", "ac", "battery", "motor", "dcdc"],
  Z9C: ["basic", "ac", "battery", "motor", "dcdc"],
  S8C: ["basic", "ac", "battery", "motor", "dcdc"],
  Z9D: ["basic", "ac", "battery", "motor", "dcdc"],
  W1C: ["basic", "battery", "motor"],
  W1D: ["basic", "battery", "motor"],
  L9Z: ["basic", "battery", "motor"],
  V2B: ["basic", "tyre", "ac", "battery", "motor", "dcdc"],
  V8B: ["basic", "battery", "motor"],
  Y0B: ["basic", "ac", "battery", "motor", "dcdc"],
  Y9B: ["basic", "ac", "battery", "motor", "dcdc"],
  Z1C: ["basic", "ac", "battery", "motor", "dcdc"],
  Z6A: ["basic", "ac", "battery", "motor", "dcdc"],
};

const DefaultColumns = [
  {
    title: "采集时间",
    dataIndex: "at",
    fixed: "left",
    frozen: true,
  },
  { title: "车辆VIN", dataIndex: "vin" },
  {
    title: "上报时间",
    dataIndex: "reportedAt",
    hidden: true,
  },
  {
    title: "报文类型",
    dataIndex: "command",
    formatter: val => i18n.Command[val],
  },
  {
    title: i18n.VehicleDataType.BASIC,
    key: "basic",
    children: [
      {
        title: i18n.VehicleDataBasic.at,
        dataIndex: "at",
        key: "basic.at",
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.reportedAt,
        dataIndex: "reportedAt",
        key: "basic.reportedAt",
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.status,
        dataIndex: "overall.status",
        formatter: val => i18n.VehicleStatus[val],
      },
      {
        title: i18n.VehicleDataBasic.chargeStatus,
        dataIndex: "overall.chargeStatus",
        formatter: val => i18n.ChargeStatus[val],
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.speed,
        dataIndex: "overall.speed",
        type: "number",
        formatter: withUnit(i18n.Unit.SPEED),
      },
      {
        title: i18n.VehicleDataBasic.mileage,
        dataIndex: "overall.mileage",
        type: "number",
        formatter: withUnit(i18n.Unit.MILEAGE),
      },
      { title: i18n.VehicleDataBasic.shift, dataIndex: "overall.shift" },
      {
        title: i18n.VehicleDataBasic.keyPosition,
        dataIndex: "customExt.keyPosition",
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.resistance,
        dataIndex: "overall.resistance",
        type: "number",
        formatter: withUnit(i18n.Unit.RESISTANCE),
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.location,
        dataIndex: "locString",
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.aptv,
        type: "number",
        dataIndex: "overall.aptv",
        formatter: val => withPercent(val, 0),
      },
      {
        title: i18n.VehicleDataBasic.brake,
        type: "number",
        dataIndex: "overall.brake",
        formatter: val => withPercent(val, 0),
      },
      {
        title: i18n.VehicleDataBasic.handbrakeStatus,
        dataIndex: "customExt.handbrakeStatus",
        formatter: val => i18n.BreakStatus[val],
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.frontDoorStatus,
        dataIndex: "customExt.frontDoorStatus",
        formatter: val => i18n.DoorStatus[val],
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.middleDoorStatus,
        dataIndex: "customExt.middleDoorStatus",
        formatter: val => i18n.DoorStatus[val],
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.pressure1,
        dataIndex: "customExt.pressure1",
        formatter: withUnit(i18n.Unit.PRESSURE),
        hidden: true,
      },
      {
        title: i18n.VehicleDataBasic.pressure2,
        dataIndex: "customExt.pressure2",
        formatter: withUnit(i18n.Unit.PRESSURE),
        hidden: true,
      },
    ],
  },
  {
    title: i18n.VehicleDataType.ADAS,
    key: "adas",
    hidden: true,
    children: [
      {
        title: "数据详情",
        key: "adas.link",
        formatter: (val, vehicle) => (
          <a
            href={`${SAFE_URL}/earlywarning/list/${vehicle.vin}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            点击查看
          </a>
        ),
      },
    ],
  },
  {
    title: i18n.VehicleDataType.TYRE,
    key: "tyre",
    hidden: true,
    children: [
      {
        title: i18n.VehicleDataTyre.lftp,
        dataIndex: "customExt.lftp",
        type: "number",
        formatter: withUnit(i18n.Unit.PRESSURE),
      },
      {
        title: i18n.VehicleDataTyre.rftp,
        dataIndex: "customExt.rftp",
        type: "number",
        formatter: withUnit(i18n.Unit.PRESSURE),
      },
      {
        title: i18n.VehicleDataTyre.lr1tp,
        dataIndex: "customExt.lr1tp",
        type: "number",
        formatter: withUnit(i18n.Unit.PRESSURE),
      },
      {
        title: i18n.VehicleDataTyre.lr2tp,
        dataIndex: "customExt.lr2tp",
        type: "number",
        formatter: withUnit(i18n.Unit.PRESSURE),
      },
      {
        title: i18n.VehicleDataTyre.rr1tp,
        dataIndex: "customExt.rr1tp",
        type: "number",
        formatter: withUnit(i18n.Unit.PRESSURE),
      },
      {
        title: i18n.VehicleDataTyre.rr2tp,
        dataIndex: "customExt.rr2tp",
        type: "number",
        formatter: withUnit(i18n.Unit.PRESSURE),
      },
      {
        title: i18n.VehicleDataTyre.lftt,
        dataIndex: "customExt.lftt",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataTyre.rftt,
        dataIndex: "customExt.rftt",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataTyre.lr1tt,
        dataIndex: "customExt.lr1tt",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataTyre.lr2tt,
        dataIndex: "customExt.lr2tt",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataTyre.rr1tt,
        dataIndex: "customExt.rr1tt",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataTyre.rr2tt,
        type: "number",
        dataIndex: "customExt.rr2tt",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
    ],
  },
  {
    title: i18n.VehicleDataType.AC,
    key: "ac",
    hidden: true,
    children: [
      {
        title: i18n.VehicleDataAC.airMode,
        dataIndex: "customExt.airMode",
        formatter: val => i18n.AirMode[val],
      },
      {
        title: i18n.VehicleDataAC.airTemp,
        dataIndex: "customExt.airTemp",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataAC.insideTemp,
        type: "number",
        dataIndex: "customExt.insideTemp",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataAC.outsideTemp,
        type: "number",
        dataIndex: "customExt.outsideTemp",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
    ],
  },
  {
    title: i18n.VehicleDataType.BATTERY,
    key: "battery",
    children: [
      {
        title: i18n.VehicleDataBattery.soc,
        type: "number",
        dataIndex: "overall.soc",
        formatter: val => withPercent(val, 0),
      },
      {
        title: i18n.VehicleDataBattery.voltage,
        dataIndex: "overall.voltage",
        type: "number",
        formatter: withUnit(i18n.Unit.VOLTAGE),
      },
      {
        title: i18n.VehicleDataBattery.current,
        dataIndex: "overall.current",
        type: "number",
        formatter: withUnit(i18n.Unit.CURRENT),
      },
      {
        title: i18n.VehicleDataBattery.instantPower,
        type: "number",
        dataIndex: "customExt.instantPower",
        hidden: true,
        formatter: withUnit(i18n.Unit.POWER),
      },
      {
        title: i18n.VehicleDataBattery.maxNtc,
        type: "number",
        hidden: true,
        dataIndex: "extreme.maxNtc",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataBattery.maxNtcNo,
        hidden: true,
        dataIndex: "extreme.maxNtcNo",
      },
      {
        title: i18n.VehicleDataBattery.minNtc,
        hidden: true,
        dataIndex: "extreme.minNtc",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataBattery.minNtcNo,
        hidden: true,
        dataIndex: "extreme.minNtcNo",
      },
      {
        title: i18n.VehicleDataBattery.maxVoltage,
        hidden: true,
        dataIndex: "extreme.maxVoltage",
        type: "number",
        formatter: withUnit(i18n.Unit.VOLTAGE),
      },
      {
        title: i18n.VehicleDataBattery.maxVoltageSingNo,
        hidden: true,
        dataIndex: "extreme.maxVoltageSingNo",
      },
      {
        title: i18n.VehicleDataBattery.minVoltage,
        hidden: true,
        type: "number",
        dataIndex: "extreme.minVoltage",
        formatter: withUnit(i18n.Unit.VOLTAGE),
      },
      {
        title: i18n.VehicleDataBattery.minVoltageSingNo,
        hidden: true,
        dataIndex: "extreme.minVoltageSingNo",
      },
      {
        title: i18n.VehicleDataBattery.totalCharge,
        type: "number",
        dataIndex: "customExt.totalCharge",
        formatter: withUnit(i18n.Unit.POWER),
      },
      {
        title: i18n.VehicleDataBattery.totalDischarge,
        type: "number",
        dataIndex: "customExt.totalDischarge",
        formatter: withUnit(i18n.Unit.POWER),
      },
      {
        title: i18n.VehicleDataBattery.bpiRes,
        type: "number",
        hidden: true,
        dataIndex: "customExt.bpiRes",
        formatter: withUnit(i18n.Unit.RESISTANCE),
      },
      {
        title: i18n.VehicleDataBattery.bniRes,
        dataIndex: "customExt.bniRes",
        hidden: true,
        type: "number",
        formatter: withUnit(i18n.Unit.RESISTANCE),
      },
    ],
  },
  {
    title: i18n.VehicleDataType.MOTOR,
    key: "motor",
    hidden: true,
    children: [
      {
        title: i18n.VehicleDataMotor.status,
        dataIndex: "motors[0].status",
        formatter: val => i18n.MotorStatus[val],
      },
      {
        title: i18n.VehicleDataMotor.speed,
        dataIndex: "motors[0].speed",
        type: "number",
        formatter: withUnit(i18n.Unit.RSPEED),
      },
      {
        title: i18n.VehicleDataMotor.temp,
        dataIndex: "motors[0].temp",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataMotor.torque,
        type: "number",
        dataIndex: "motors[0].torque",
        formatter: withUnit(i18n.Unit.TORQUE),
      },
      {
        title: i18n.VehicleDataMotor.current,
        type: "number",
        dataIndex: "motors[0].current",
        formatter: withUnit(i18n.Unit.CURRENT),
      },
      {
        title: i18n.VehicleDataMotor.voltage,
        type: "number",
        dataIndex: "motors[0].voltage",
        formatter: withUnit(i18n.Unit.VOLTAGE),
      },
      {
        title: i18n.VehicleDataMotor.controlTemp,
        type: "number",
        dataIndex: "motors[0].controlTemp",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
    ],
  },
  {
    title: i18n.VehicleDataType.CHARGING_BOW,
    key: "chargingBow",
    hidden: true,
    children: [
      {
        title: i18n.VehicleDataChargingBow.state,
        dataIndex: "chargingBow[9].state",
        formatter: val => i18n.BowRiseState[val],
      },
      {
        title: i18n.VehicleDataChargingBow.allowLeave,
        dataIndex: "chargingBow[9].allowLeave",
        formatter: val => i18n.BowAllowLeave[val],
      },
      {
        title: i18n.VehicleDataChargingBow.systemState,
        dataIndex: "chargingBow[9].systemState",
        formatter: val => i18n.BowSystemState[val],
      },
      {
        title: i18n.VehicleDataChargingBow.wifi,
        dataIndex: "chargingBow[9].wifi",
        formatter: val => i18n.BowWifi[val],
      },
      {
        title: i18n.VehicleDataChargingBow.soc,
        dataIndex: "chargingBow[9].soc",
        type: "number",
        formatter: val => withPercent(val, 0),
      },
      {
        title: i18n.VehicleDataChargingBow.charging,
        dataIndex: "chargingBow[9].charging",
        formatter: val => i18n.BowCharging[val],
      },
      {
        title: i18n.VehicleDataChargingBow.voltage,
        type: "number",
        dataIndex: "chargingBow[9].voltage",
        formatter: withUnit(i18n.Unit.VOLTAGE),
      },
      {
        title: i18n.VehicleDataChargingBow.current,
        dataIndex: "chargingBow[9].current",
        type: "number",
        formatter: withUnit(i18n.Unit.CURRENT),
      },
      {
        title: i18n.VehicleDataChargingBow.capacity,
        type: "number",
        dataIndex: "chargingBow[9].capacity",
        formatter: withUnit(i18n.Unit.POWER),
      },
      {
        title: i18n.VehicleDataChargingBow.duration,
        dataIndex: "chargingBow[9].duration",
        formatter: withUnit(i18n.Unit.SECOND),
      },
    ],
  },
  {
    title: i18n.VehicleDataType.DCDC,
    key: "dcdc",
    hidden: true,
    children: [
      {
        title: i18n.VehicleDataDCDC.dcStatus,
        dataIndex: "overall.dcStatus",
      },
      {
        title: i18n.VehicleDataDCDC.dcov,
        type: "number",
        dataIndex: "customExt.dcov",
        formatter: withUnit(i18n.Unit.VOLTAGE),
      },
      {
        title: i18n.VehicleDataDCDC.dcoc,
        dataIndex: "customExt.dcoc",
        type: "number",
        formatter: withUnit(i18n.Unit.CURRENT),
      },
      {
        title: i18n.VehicleDataDCDC.dcTemp,
        dataIndex: "customExt.dcTemp",
        type: "number",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
      {
        title: i18n.VehicleDataDCDC.batteryVoltage,
        dataIndex: "customExt.batteryVoltage",
        type: "number",
        formatter: withUnit(i18n.Unit.VOLTAGE),
      },
      {
        title: i18n.VehicleDataDCDC.acTemp,
        type: "number",
        dataIndex: "customExt.acTemp",
        formatter: withUnit(i18n.Unit.TEMPERATURE),
      },
    ],
  },
];

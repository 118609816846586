import { createSelector } from "reselect";
import i18n from "../i18n";
import { formatValue, sliceVal, ymdhms } from "../lib";

export const makeTicketListSelector = ticketSelector =>
  createSelector(
    ticketSelector,
    state => {
      const records = state.result || [];
      const result = records.map(r => ({
        ...r,
        ticketId: formatValue(r, "id", sliceVal),
        handleAction: formatValue(
          r.events[r.events.length - 1],
          "action",
          i18n.TicketAction
        ),
        createdAt: formatValue(r, "createdAt", ymdhms),
        updatedAt: formatValue(r, "updatedAt", ymdhms),
      }));
      return { ...state, result };
    }
  );

/**
 * redux 中间件， 对于 REPAIR_OP 角色账号，在配置的api中添加 repairWorkshop filter字段
 */

import { cloneDeep, set, startsWith, isEmpty, get } from "lodash";

import { ROOT_NS, REPAIR_WORKSHOPS_NAMESPACES } from "../../config";

import * as types from "../../actions/types";
import { UserRoles } from "../../constants";
import { makeApiSelector } from "@36node/redux";

const FILTER_REPAIR_WORKSHOPS_API = [
  types.CORE_VEHICLE_LIST_VEHICLES,
  types.OP_ALERT_LIST_ALERTS,
  types.OP_TICKET_LIST_TICKETS,
  types.OP_EXCEPTION_LIST_EXCEPTIONS,
].map(t => t.REQUEST);

const selectSession = makeApiSelector("session");

const repairWorkshopMiddleware = store => next => action => {
  const { type } = action;
  const state = store.getState();
  const session = selectSession(state).result || {};

  if (!FILTER_REPAIR_WORKSHOPS_API.includes(type)) return next(action);

  const userNs = get(session, "user.ns");
  if (isEmpty(userNs)) {
    return next(action);
  }

  const { roles = [] } = session;
  const ns = roles.filter(
    r => startsWith(r.ns, ROOT_NS) && r.name === UserRoles.REPAIR_OP
  );

  if (isEmpty(ns)) {
    return next(action);
  }

  const found = REPAIR_WORKSHOPS_NAMESPACES.find(n => n.ns === userNs[0]);

  if (!found) {
    return next(action);
  }

  const newPayload = cloneDeep(action.payload || {});

  set(newPayload, ["query", "filter", "repairWorkshop"], found.name);

  const nextAction = {
    ...action,
    payload: newPayload,
  };

  return next(nextAction);
};

export default repairWorkshopMiddleware;

import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu } from "antd";
import { get, merge, intersection } from "lodash";

import IconFont from "../iconfont";
import { flattenPrivilege } from "../../config/privileges";

const defaultIconSize = 20;
const renderIcon = item =>
  item.icon && (
    <IconFont
      type={`icon-${item.icon}`}
      style={{
        color: item.color,
        fontSize: item.iconSize || defaultIconSize,
      }}
    />
  );

const renderMenu = (menu, prepath = "", background) => {
  if (!Array.isArray(menu)) return;
  return menu.map(item =>
    item.children ? (
      <Menu.SubMenu
        key={item.key}
        title={
          <span>
            {renderIcon(item)}
            <span>{item.title}</span>
          </span>
        }
      >
        {renderMenu(item.children, item.path, background)}
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={item.key} disabled={item.disabled}>
        {item.url ? (
          <a href={item.url}>
            {renderIcon(item)}
            <span>{item.title}</span>
          </a>
        ) : (
          <Link
            to={{
              pathname: prepath + item.path,
              state: { background },
            }}
          >
            {renderIcon(item)}
            <span>{item.title}</span>
          </Link>
        )}
      </Menu.Item>
    )
  );
};

const matchMenu = (menu = [], pathname, pre = "") => {
  const found = menu.find(item => pathname.startsWith(pre + item.path));
  if (!found) return;
  const foundNext = matchMenu(found.children, pathname, found.path);
  if (!foundNext) return { selectedKeys: [found.key] };
  return merge({ openKeys: [found.key] }, foundNext);
};

@withRouter
export default class Nav extends React.Component {
  state = {
    openKeys: [],
    selectedKeys: [],
  };

  static defaultProps = {
    theme: "dark",
    mode: "inline",
    menu: [],
    prepath: "",
  };

  static getDerivedStateFromProps(nextProps, preState) {
    const pathname = get(nextProps, "location.pathname");
    const collapsed = get(nextProps, "collapsed");
    const menu = get(nextProps, "menu");
    const prepath = get(nextProps, "prepath");
    if (pathname !== preState.pathname || collapsed !== preState.collapsed) {
      const { openKeys = [], selectedKeys = [] } =
        matchMenu(menu, pathname, prepath) || {};
      const openKeysWithCollapsed = collapsed ? [] : openKeys;
      return {
        openKeys: openKeysWithCollapsed,
        selectedKeys,
        pathname,
        collapsed,
      };
    }
    return null;
  }

  handleOpen = openKeys => {
    this.setState({ openKeys });
  };

  /**
   * 根据env 配置的开关过滤item
   */
  filterBySwitch(menu = []) {
    const filter = (items = []) => {
      return items
        .map(item => {
          if (item.switch === "off") {
            return undefined;
          }

          const newItem = { ...item };

          if (newItem.children) {
            newItem.children = filter(newItem.children);
          }

          return newItem;
        })
        .filter(Boolean);
    };

    return filter(menu);
  }

  filterByPrivileges(menu = []) {
    const { privileges = [] } = this.props;

    const filter = (items = []) => {
      return items
        .map(item => {
          if (!item.privilege) {
            return item;
          }

          if (typeof item.privilege === "string") {
            return privileges.includes(item.privilege) && item;
          }

          if (typeof item.privilege === "object") {
            const newItem = { ...item };
            const itemPrivilege = flattenPrivilege(item.privilege);
            if (item.children) {
              newItem.children = filter(newItem.children);
            }

            if (intersection(itemPrivilege, privileges).length > 0)
              return newItem;
          }
          return undefined;
        })
        .filter(Boolean);
    };

    return filter(menu);
  }

  render() {
    const { theme, mode, prepath, location } = this.props;
    const background = location.state && location.state.background;

    const finalMenu = this.filterByPrivileges(
      this.filterBySwitch(this.props.menu)
    );

    return (
      <Fragment>
        <Menu
          theme={theme}
          mode={mode}
          selectedKeys={this.state.selectedKeys}
          openKeys={this.state.openKeys}
          onOpenChange={this.handleOpen}
          style={{ lineHeight: "64px" }}
        >
          {renderMenu(finalMenu, prepath, background)}
        </Menu>
      </Fragment>
    );
  }
}

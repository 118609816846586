import React from "react";
import { ConfigProvider, Spin } from "antd";
import { hot } from "react-hot-loader/root";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { history, ProtectedRoute, withSession } from "@36node/redux-session";
import zhCN from "antd/es/locale/zh_CN";
import { isEmpty } from "lodash";

import MainLayout from "./containers/layouts/main";
import Vehicle, { VehicleSingle } from "./containers/vehicle";
import TicketEditor from "./containers/operator/ticket-editor";
import ArchiveEditor from "./containers/settings/archives/editor";
import DrillDown from "./containers/operator/analysis-drill-down";

import { OVERVIEW_SWITCH } from "./config";
import Pile from "./containers/pile";
import MyLoadable from "./hocs/loadable";

// 如果遇到调试不报错的情况，可以将下面的import 取消注释，并将Loadable的组件注释掉

// import Login from './containers/login';
// import Screen from "./containers/screen";
// import Monitor from "./containers/monitor";
// import Operator from "./containers/operator";
// import Exception from "./containers/exception";
// import Settings from "./containers/settings";
// import Energy from "./containers/energy";
// import Battery from "./containers/battery";
// import OverView from "./containers/overview";

const Login = MyLoadable({
  loader: () => import("./containers/login"),
});

const Screen = MyLoadable({
  loader: () => import("./containers/screen"),
});
const Monitor = MyLoadable({
  loader: () => import("./containers/monitor"),
});
const Operator = MyLoadable({
  loader: () => import("./containers/operator"),
});
const Exception = MyLoadable({
  loader: () => import("./containers/exception"),
});
const Settings = MyLoadable({
  loader: () => import("./containers/settings"),
});
const Energy = MyLoadable({
  loader: () => import("./containers/screen"),
});
const Battery = MyLoadable({
  loader: () => import("./containers/battery"),
});
const OverView = MyLoadable({
  loader: () => import("./containers/overview"),
});

/**
 * App
 */
const App = () => (
  <ConfigProvider locale={zhCN}>
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute redirect="/login" path="/screen" component={Screen} />
        <ProtectedRoute redirect="/login" component={Main} />
      </Switch>
    </Router>
  </ConfigProvider>
);

const Main = withSession("session")(props => {
  const location = useLocation();
  const background = location.state && location.state.background;

  const { result = {} } = props.session;

  if (isEmpty(result.user)) {
    return <Spin />;
  }

  return (
    <MainLayout>
      <Switch location={background || location}>
        {OVERVIEW_SWITCH === "on" && (
          <Route path="/overview" component={OverView} />
        )}

        <Route path="/monitor" component={Monitor} />
        <Route path="/op" component={Operator} />
        <Route path="/exception" component={Exception} />
        <Route path="/settings" component={Settings} />
        <Route path="/energy" component={Energy} />
        <Route path="/battery" component={Battery} />
        <Route path="/pile" component={Pile} />

        <Route path="/g/vehicle/:vehicleId" component={VehicleSingle} />

        {OVERVIEW_SWITCH === "on" ? (
          <Redirect from="/" to="/overview" />
        ) : (
          <Redirect from="/" to="/monitor" />
        )}
      </Switch>
      {background && (
        <Switch>
          <Route path="/g/ticket/create" component={TicketEditor} />
          <Route path="/g/ticket/:ticketId/edit" component={TicketEditor} />
          <Route path="/g/vehicle/:vehicleId" component={Vehicle} />
          <Route path="/g/archive/add" component={ArchiveEditor} />
          <Route path="/g/archive/:vin/update" component={ArchiveEditor} />
          <Route path="/g/alert/:group" component={DrillDown} />
        </Switch>
      )}
    </MainLayout>
  );
});

/**
 * 其他页面
 */

const HotApp = process.env.NODE_ENV === "development" ? hot(App) : App;
export default HotApp;

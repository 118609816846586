import React, { PureComponent } from "react";
import { Map } from "react-amap";
import { Wrapper } from "react-amapui-wrapper";
import PropTypes from "prop-types";

import { AMAP_KEY, AMAP_STYLE, AMAP_CENTER } from "../../config";
import PathSimplifier from "./path-simplifier";

const DefaultSpeed = 3.6 * 4;

const Deferred = () => {
  var deferred = {};
  deferred.promise = new Promise(function(resolve, reject) {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
};

export const MapState = {
  Unknown: "Unknown",
  Run: "Run",
  Stop: "Stop",
};

export default class TrailMap extends PureComponent {
  mapDeferred = Deferred();
  navg = null;

  static propTypes = {
    positions: PropTypes.array, // 车辆的位置信息
    curPos: PropTypes.number, // 车辆当前位置索引
    running: PropTypes.string, // 续航器是否在工作 初始值为null
  };

  static defaultProps = {
    positions: [],
    curPos: 0,
    running: MapState.Unknown,
  };

  state = {
    prePos: 0,
    mapState: MapState.Unknown,
  };

  componentDidUpdate() {
    const { running } = this.props;
    if (running !== MapState.Unknown) {
      if (running === MapState.Stop) {
        if (!this.navg) this.initData();
        else this.jumpTrail();
      } else this.moveTrail();
    } else this.resetTrail();
  }

  componentWillUnmount() {
    this.destroyNavg();
  }

  initComplete = pathSimplifier => {
    this.mapDeferred.resolve(pathSimplifier);
  };

  initData = () => {
    this.mapDeferred.promise
      .then(pathSimplifier => {
        const { positions } = this.props;
        pathSimplifier.setData([
          {
            name: "轨迹回放",
            path: positions,
          },
        ]);
        // 新建巡航器 初始速度为0
        this.navg = pathSimplifier.createPathNavigator(0, {
          speed: 0,
          loop: true,
        });
        this.navg.start();

        this.mapDeferred = Deferred();
        this.mapDeferred.resolve(pathSimplifier);
      })
      .catch(e => {
        console.log("trail map error", e);
        return null;
      });
  };

  moveTrail = () => {
    const { positions, curPos } = this.props;
    const { prePos, mapState } = this.state;
    if (curPos !== prePos && this.navg) {
      let distance = 0.0,
        tmpPrePos = null;
      let tmpPosArray = null;
      if (curPos > prePos) {
        tmpPosArray = positions.slice(prePos, curPos + 1);
        tmpPosArray.forEach(p => {
          let pos = new window.AMap.LngLat(p[0], p[1]);
          if (tmpPrePos) {
            distance += pos.distance(tmpPrePos);
          }
          tmpPrePos = pos;
        });
        // 计算速度 km/h
        const navgSpeed = distance * DefaultSpeed;
        this.navg.setSpeed(navgSpeed);
        this.navg.moveByDistance(distance);
      } else {
        // 往回跳转
        this.navg.setSpeed(0);
        this.navg.moveToPoint(curPos);
      }

      if (mapState !== MapState.Run) {
        this.setState({ prePos: curPos, mapState: MapState.Run });
      } else this.setState({ prePos: curPos });
    }
  };

  jumpTrail = () => {
    const { curPos } = this.props;
    const { prePos, mapState } = this.state;
    if (curPos !== prePos) {
      this.navg.setSpeed(0);
      this.navg.moveToPoint(curPos);
      this.setState({
        prePos: curPos,
      });
    }
    if (mapState !== MapState.Stop) {
      this.navg.setSpeed(0);
      this.setState({ mapState: MapState.Stop });
    }
  };

  resetTrail = () => {
    const { mapState } = this.state;
    if (mapState !== MapState.Unknown) {
      this.destroyNavg();
      this.setState({
        mapState: MapState.Unknown,
      });
    }
  };

  destroyNavg = () => {
    if (this.navg) {
      this.navg.destroy();
      this.navg = null;
    }
  };

  render() {
    return (
      <Map
        version={"1.4.15"}
        amapkey={AMAP_KEY}
        mapStyle={AMAP_STYLE}
        zoom={16}
        center={AMAP_CENTER}
        useAMapUI={true} // 使用自定义 ampui 组件
      >
        <Wrapper>
          <PathSimplifier
            eventSupport={true}
            instanceName={"PathSimplifier"}
            initComplete={this.initComplete}
          />
        </Wrapper>
      </Map>
    );
  }
}

import React from "react";
import styled from "styled-components";

import { LOGO } from "../../config";

// const Logo = styled.img.attrs({ src: svg })`
const Container = styled.div`
  height: 38px;
  margin: 16px;
  color: white;
`;

/** @component */

export default ({ collapsed }) => (
  <Container>
    <img src={LOGO} alt="logo" width="48" />
    {!collapsed && "新能源监控平台"}
  </Container>
);

import { uniq } from "lodash";
import { makeApiSelector } from "@36node/redux";
import { RolePrivilege } from "../config/privileges";

export const privilegesSelector = state => {
  const sessionSelector = makeApiSelector("session");
  const session = sessionSelector(state).result || {};
  const { roles = [] } = session;
  // const roles = [{ name: "FLEET_MONITOR" }];
  const privileges = [].concat(
    ...roles.map(r => r.name).map(r => RolePrivilege[r] || [])
  );
  return uniq(privileges);
};

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Button, Row, Col, Table, Popover, Checkbox } from "antd";
import styled from "styled-components";
import { get } from "lodash";
import moment from "moment";
import { makeApiSelector } from "@36node/redux";
import { createForm } from "@36node/redux-antd";

import { withTable } from "../../components/withTable";
import { DateDetailRange } from "../../components/fields";
import { log, op } from "../../actions/api";
import { RecordCommands } from "../../constants";
import { genVehilcleColumns } from "../../components/vehicle/columns";
import { makeRecordsTableSelector } from "../../selectors";
import { getFormatDate } from "../../lib";
import i18n from "../../i18n";

const listRecords = log.vehicle.makeListRecords("vehicle.historyRecords", {
  query: {
    limit: 10,
    offset: 0,
    sort: "-at",
    filter: {
      command: RecordCommands.REALTIME_REPORT,
    },
  },
});

const listFaults = op.fault.makeListFaults("vehicle.listFaults");

const faultsSelector = makeApiSelector("vehicle.listFaults");

@withRouter
@createForm("vehicle.history", { resetOnUnMount: false })
class SearchForm extends PureComponent {
  state = {
    initTime: [moment().subtract(1, "hours"), moment()],
  };
  get vehicleId() {
    return get(this.props.match, "params.vehicleId");
  }

  componentDidMount() {
    const { initTime } = this.state;
    this.fetchData(initTime[0], initTime[1]);
  }

  fetchData = (at_gt, at_lt, command) => {
    this.props.onFetch({
      vehicleId: this.vehicleId,
      query: {
        filter: {
          at_gt: at_gt.toISOString(),
          at_lt: at_lt.toISOString(),
          command,
        },
      },
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { startDate, endDate, startTime, endTime, showReissue } = values;
        const { initTime } = this.state;

        let at_gt = getFormatDate(startDate, startTime) || initTime[0];
        let at_lt = getFormatDate(endDate, endTime) || initTime[1];

        const command = showReissue
          ? [RecordCommands.REISSUE_REPORT, RecordCommands.REALTIME_REPORT]
          : [RecordCommands.REALTIME_REPORT];

        this.fetchData(at_gt, at_lt, command);
      }
    });
  };

  handleReset = e => {
    const at_gt = moment().subtract(1, "hours");
    const at_lt = moment();
    this.setState({ initTime: [at_gt, at_lt] });
    this.fetchData(at_gt, at_lt);
  };

  render() {
    const { form } = this.props;
    const { initTime } = this.state;
    const { getFieldDecorator } = form;

    return (
      <StyledFilterForm layout="inline" onSubmit={this.handleSubmit}>
        <Row gutter={24} type="flex" align="middle">
          <Col span={15}>
            <DateDetailRange form={form} initialValue={initTime} />
          </Col>
          <Col span={3}>
            {getFieldDecorator("showReissue", {
              initialValue: false,
            })(<Checkbox>显示补发</Checkbox>)}
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button
                type="primary"
                style={{ marginLeft: 48 }}
                htmlType="submit"
              >
                筛选
              </Button>
              <Button style={{ marginLeft: 10 }} onClick={this.handleReset}>
                重置
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledFilterForm>
    );
  }
}

@withTable("vehicle.historyRecords", {
  SearchForm,
  title: "历史数据",
  columns: [
    ...genVehilcleColumns("", [
      "vin",
      "command",
      "basic.at",
      "basic.reportedAt",
    ]),
    {
      title: "日志状态",
      key: "record-state",
      render: record => {
        const { command } = record;

        return i18n.RecordState[command];
      },
    },
    {
      title: "报警信息",
      key: "alertInfo",
      dataIndex: "alertInfo",
      render: (val, record) => {
        return (
          <Popover
            title="data: "
            content={<DataPopoverCard>{record.data}</DataPopoverCard>}
            trigger="click"
            placement="leftBottom"
          >
            <div style={{ cursor: "pointer" }}>{val}</div>
          </Popover>
        );
      },
    },
  ], // 第一个参数可以提供车型过滤
  list: listRecords,
  makeListSelector: makeRecordsTableSelector(faultsSelector),
  fetchOnMount: false,
})
class HistoryTable extends PureComponent {
  render() {
    return <Table scroll={{ x: "max-content" }} {...this.props} />;
  }
}

@connect()
export default class VehicleHistory extends PureComponent {
  componentDidMount() {
    this.props.dispatch(listFaults());
  }

  render() {
    return (
      <Container>
        <HistoryTable />
      </Container>
    );
  }
}

const DataPopoverCard = styled.div`
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  word-wrap: break-word;
`;

const Container = styled.div`
  height: 100%;
  overflow: scroll;
  background-color: #fff;
`;

const StyledFilterForm = styled(Form)`
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  padding: 0px 30px !important;

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .pack-filter {
    display: flex;
    justify-content: center;

    .ant-btn {
      border: 0;
    }
  }
`;

import { createApiMaker } from "@36node/redux";

import * as types from "./types";
import * as sdk from "../sdk";
import * as schema from "../schemas";

export const auth = {
  session: {
    makeLogin: createApiMaker(types.LOGIN, sdk.auth.session.createSession),
    makeLogout: createApiMaker(types.LOGOUT, sdk.auth.session.deleteSession),
    makeRefresh: createApiMaker(types.REFRESH, sdk.auth.session.getSession),
  },

  namespace: {
    makeListNamespaces: createApiMaker(
      types.AUTH_NS_LIST_NAMESPACES,
      sdk.auth.namespace.listNamespaces,
      [schema.namespacesSchema]
    ),
    makeCreateNamespace: createApiMaker(
      types.AUTH_NS_CREATE_NAMESPACE,
      sdk.auth.namespace.createNamespace,
      schema.namespacesSchema
    ),
    makeDeleteNamespace: createApiMaker(
      types.AUTH_NS_DELETE_NAMESPACE,
      sdk.auth.namespace.deleteNamespace
    ),
    makeUpdateNamespace: createApiMaker(
      types.AUTH_NS_UPDATE_NAMESPACE,
      sdk.auth.namespace.updateNamespace,
      schema.namespacesSchema
    ),
  },
  user: {
    makeListUsers: createApiMaker(
      types.AUTH_USER_LIST_USERS,
      sdk.auth.user.listUsers,
      [schema.userSchema]
    ),
    makeCreateUser: createApiMaker(
      types.AUTH_USER_CREATE_USER,
      sdk.auth.user.createUser,
      schema.userSchema
    ),
    makeUpdateUser: createApiMaker(
      types.AUTH_USER_UPDATE_USER,
      sdk.auth.user.updateUser,
      schema.userSchema
    ),
    makeDeleteUser: createApiMaker(
      types.AUTH_USER_DELETE_USER,
      sdk.auth.user.deleteUser
    ),
  },
};

export const op = {
  alert: {
    makeGetAlert: createApiMaker(
      types.OP_ALERT_GET_ALERT,
      sdk.op.alert.getAlert,
      schema.alertSchema
    ),
    makeListAlerts: createApiMaker(
      types.OP_ALERT_LIST_ALERTS,
      sdk.op.alert.listAlerts,
      [schema.alertSchema]
    ),
    makeUpdateAlert: createApiMaker(
      types.OP_ALERT_UPDATE_ALERT,
      sdk.op.alert.updateAlert,
      schema.alertSchema
    ),
  },
  fault: {
    makeListFaults: createApiMaker(
      types.OP_LIST_FAULTS,
      sdk.op.fault.listFaults
    ),
  },
  ticket: {
    makeCreateTicket: createApiMaker(
      types.OP_TICKET_CREATE_TICKET,
      sdk.op.ticket.creatTicket,
      schema.ticketSchema
    ),
    makeGetTicket: createApiMaker(
      types.OP_TICKET_GET_TICKET,
      sdk.op.ticket.getTicket,
      schema.ticketSchema
    ),
    makelistTickets: createApiMaker(
      types.OP_TICKET_LIST_TICKETS,
      sdk.op.ticket.listTickets,
      [schema.ticketSchema]
    ),
    makeListStages: createApiMaker(
      types.OP_TICKET_LIST_STAGES,
      sdk.op.ticket.listStages,
      [schema.ticketStageSchema]
    ),
    makeCreateEvent: createApiMaker(
      types.OP_TICKET_CREATE_EVENT,
      sdk.op.ticket.createEvent,
      schema.ticketSchema
    ),
  },
  exception: {
    makeListExceptions: createApiMaker(
      types.OP_EXCEPTION_LIST_EXCEPTIONS,
      sdk.op.exception.listExceptions,
      [schema.exceptionSchema]
    ),
  },
  Summary: {
    makeGetAlertSummary: createApiMaker(
      types.OP_SUMMARY_GET_ALERT,
      sdk.op.summary.getAlertSummary
    ),
  },
};

export const core = {
  vehicle: {
    makeGetVehicle: createApiMaker(
      types.CORE_VEHICLE_GET_VEHICLE,
      sdk.core.vehicle.getVehicle,
      schema.vehicleSchema
    ),
    makeListVehicles: createApiMaker(
      types.CORE_VEHICLE_LIST_VEHICLES,
      sdk.core.vehicle.listVehicles,
      [schema.vehicleSchema]
    ),
    makeCreateVehicle: createApiMaker(
      types.CORE_VEHICLE_CREATE_VEHICLE,
      sdk.core.vehicle.createVehicle,
      schema.vehicleSchema
    ),
    makeUpdateVehicle: createApiMaker(
      types.CORE_VEHICLE_UPDATE_VEHICLE,
      sdk.core.vehicle.updateVehicle,
      schema.vehicleSchema
    ),
    makeDeleteVehicle: createApiMaker(
      types.CORE_VEHICLE_DELETE_VEHICLE,
      sdk.core.vehicle.deleteVehicle,
      schema.vehicleSchema
    ),
    makeGetStatistics: createApiMaker(
      types.CORE_VEHICLE_GET_STATISTICS,
      sdk.core.vehicle.getStatistics
    ),
    makeGetSohStatistics: createApiMaker(
      types.CORE_VEHICLE_GET_SOH_STATISTICS,
      sdk.core.vehicle.getVehicleSohStatistics
    ),
    makeGetAirStatistics: createApiMaker(
      types.CORE_VEHICLE_GET_AIR_STATISTICS,
      sdk.core.vehicle.getVehicleAirStatistics
    ),
  },
  line: {
    makeListLines: createApiMaker(
      types.CORE_LINE_LIST_LINES,
      sdk.core.line.listLines,
      [schema.lineSchema]
    ),
  },
  producer: {
    makeListProducers: createApiMaker(
      types.CORE_PRODUCER_LIST_PRODUCERS,
      sdk.core.producer.listProducers,
      [schema.producerSchema]
    ),
  },
  park: {
    makeListParks: createApiMaker(
      types.CORE_PARK_LIST_PARKS,
      sdk.core.park.listParks,
      [schema.parkSchema]
    ),
  },
  banci: {
    makeListBancis: createApiMaker(
      types.CORE_BANCI_LIST_BANCIS,
      sdk.core.banci.listBancis,
      [schema.banciSchema]
    ),
  },
  warning: {
    makeListWarnings: createApiMaker(
      types.CORE_WARNING_LIST_WARNINGS,
      sdk.core.warning.listWarnings,
      [schema.warningSchema]
    ),
    makeListLatestWarnings: createApiMaker(
      types.CORE_WARNING_LIST_LATESTWARNINGS,
      sdk.core.warning.listLatestWarnings,
      [schema.sohSchema]
    ),
  },
  weather: {
    makeGetWeather: createApiMaker(
      types.CORE_GET_WEATHER,
      sdk.core.weather.getWeather
    ),
    makeGetWeatherLast24Hours: createApiMaker(
      types.CORE_GET_WEATHER_LAST,
      sdk.core.weather.getWeatherLast24Hours
    ),
  },
  push: {
    makeGetPushSet: createApiMaker(
      types.CORE_PUSH_GET_SET,
      sdk.core.push.getPushSet
    ),
    makeUpdatePushSet: createApiMaker(
      types.CORE_PUSH_UPDATE_SET,
      sdk.core.push.updatePushSet
    ),
  },
  optionalVehicle: {
    makeListOptionalVehicles: createApiMaker(
      types.CORE_LIST_OPTIONAL_VEHICLES,
      sdk.core.optionalVehicle.listOptionalVehicles,
      [schema.optionalVehicleSchema]
    ),
    makeDeleteOptionalVehicle: createApiMaker(
      types.CORE_DELETE_OPTIONAL_VEHICLES,
      sdk.core.optionalVehicle.deleteOptionalVehicle
    ),
  },
  qsoh: {
    makeListQSoh: createApiMaker(
      types.CORE_LIST_QSOH,
      sdk.core.qsoh.listQSohs,
      [schema.QSohSchema]
    ),
  },
};

export const chart = {
  analysis: {
    makeListOverall: createApiMaker(
      types.CHART_ANALYSIS_LIST_OVERALL,
      sdk.chart.analysis.listOverallStatistics
    ),
    makeListAlert: createApiMaker(
      types.CHART_ANALYSIS_LIST_ALERT,
      sdk.chart.analysis.listAlertStatistics
    ),
    makeListAlertrate: createApiMaker(
      types.CHART_ANALYSIS_LIST_ALERTRATE,
      sdk.chart.analysis.listAlertRateStatistics
    ),
    makeListAlertDrillDown: createApiMaker(
      types.CHART_ANALYSIS_LIST_DRILLDOWN,
      sdk.chart.analysis.listAlertDrillDownStatistics
    ),
  },
  alert: {
    makeAlertHistogram: createApiMaker(
      types.CHART_ALERT_HISTOGRAM,
      sdk.chart.alert.listAlertHistogram
    ),
  },
  warning: {
    makeListWarnings: createApiMaker(
      types.CHART_WARNING_LIST_WARNINGS,
      sdk.chart.warning.listWarningsStatistics
    ),
  },
  vehicle: {
    makeListMileages: createApiMaker(
      types.CHART_VEHICLE_LIST_MILEAGES,
      sdk.chart.vehicle.listMileages,
      [schema.mileageSchema]
    ),
    makeVehicleChargingHistogram: createApiMaker(
      types.CHART_CHARGING_VEHICLE_HISTOGRAM,
      sdk.chart.vehicle.listVehicleChargingHistogram
    ),
  },
  power: {
    makeListPower: createApiMaker(
      types.CHART_POWER_LIST_POWERS,
      sdk.chart.power.listPower,
      [schema.powerSchema]
    ),
    makeListPowerAggs: createApiMaker(
      types.CHART_POWER_LIST_POWERS_AGGS,
      sdk.chart.power.listPowerAggregations,
      [schema.powerAggSchema]
    ),
    makeGetPowerStatistics: createApiMaker(
      types.CHART_POWER_GET_STATISTICS,
      sdk.chart.power.listPowerStatistics
    ),
    makeGetPowerScreenStat: createApiMaker(
      types.CHART_POWER_GET_SCREENSTAT,
      sdk.chart.power.listPowerScreenStatistics
    ),
  },
  exception: {
    makeListExceptionStatistics: createApiMaker(
      types.CHART_EXCEPTION_LIST_STATISTICS,
      sdk.chart.exception.listExceptionStatistics
    ),
  },
  platform: {
    makeListPlatformTboxStat: createApiMaker(
      types.CHART_PLATFORM_TBOX_LIST_STATS,
      sdk.chart.vehicle.listPlatformStat
    ),
  },
  datagram: {
    makeListDatagramStat: createApiMaker(
      types.CHART_DATAGRAM_LIST_STATS,
      sdk.chart.vehicle.listDatagramStat
    ),
  },
  banci: {
    makeDriverPowersStat: createApiMaker(
      types.CHART_DRIVER_POWER_STATS,
      sdk.chart.banci.listDriverPowerStatistics
    ),
    makeListDriverPower: createApiMaker(
      types.LIST_DRIVER_POWERS,
      sdk.chart.banci.listDirverPower,
      [schema.driverPowerSchema]
    ),
    makeListDriverPowerAggs: createApiMaker(
      types.LIST_DRIVER_POWERS_AGGS,
      sdk.chart.banci.listDriverPowerAggregations,
      [schema.driverPowerAggSchema]
    ),
  },
  chargeorder: {
    makeDriverPowersStat: createApiMaker(
      types.CHART_CHARGEORDER_LIST_STATS,
      sdk.chart.chargeorder.listChargeOrderStatistics
    ),
  },
  screen: {
    makeGetCaobaoluEnergyData: createApiMaker(
      types.CHART_SCREEN_CAOBAOLU_ENERGY,
      sdk.chart.screen.getCaobaoluEnergy
    ),
    makeGetCaobaoluBatteryData: createApiMaker(
      types.CHART_SCREEN_CAOBAOLU_BATTERY,
      sdk.chart.screen.getCaobaoluBattery
    ),
    makeGetCaobaoluSaveData: createApiMaker(
      types.CHART_SCREEN_CAOBAOLU_SAVE,
      sdk.chart.screen.getCaobaoluSave
    ),
    makeGetCaobaoluMileageData: createApiMaker(
      types.CHART_SCREEN_CAOBAOLU_MILEAGE,
      sdk.chart.screen.getCaobaoluMileage
    ),
    makeGetCaobaoluAirData: createApiMaker(
      types.CHART_SCREEN_CAOBAOLU_AIR,
      sdk.chart.screen.getCaobaoluAir
    ),
  },
};

export const log = {
  vehicle: {
    makeListRecords: createApiMaker(
      types.LOG_VEHICLE_LIST_RECORDS,
      sdk.log.vehicle.listRecords,
      [schema.recordSchema]
    ),
  },
};

export const pile = {
  station: {
    makeListStation: createApiMaker(
      types.PILE_LIST_STATIONS,
      sdk.pile.station.listStations,
      [schema.stationSchema]
    ),
    makeGetStation: createApiMaker(
      types.PILE_GET_STATION,
      sdk.pile.station.getStation,
      schema.stationSchema
    ),
  },
  pile: {
    makeListPiles: createApiMaker(
      types.PILE_LIST_PILES,
      sdk.pile.pile.listPiles,
      [schema.pileSchema]
    ),
    makeGetPile: createApiMaker(
      types.PILE_GET_PILE,
      sdk.pile.pile.getPile,
      schema.pileSchema
    ),
  },
  chargingOrder: {
    makeListChargingOrder: createApiMaker(
      types.PILE_LIST_CHARGING_ORDERS,
      sdk.pile.chargingOrder.listChargingOrders,
      [schema.chargingOrderSchema]
    ),
  },
  sgcc: {
    makeListSgccRecords: createApiMaker(
      types.PILE_SGCC_LIST_RECORDS,
      sdk.pile.sgcc.listSgccRecords,
      [schema.sgccSchema]
    ),
    makeListSgccFields: createApiMaker(
      types.PILE_SGCC_LIST_FIELDS,
      sdk.pile.sgcc.listSgccField
    ),
    makeCreateSgccRecord: createApiMaker(
      types.PILE_SGCC_CREATE_RECORD,
      sdk.pile.sgcc.createSgccRecord,
      schema.sgccSchema
    ),
    makeGetSgccSummary: createApiMaker(
      types.PILE_SGCC_SUMMARY,
      sdk.pile.sgcc.getSgccSummary
    ),
  },
  price: {
    makeGetPrice: createApiMaker(
      types.PILE_PRICE_GET_PRICE,
      sdk.pile.price.getPrice
    ),
    makeUpdatePrice: createApiMaker(
      types.PILE_PRICE_UPDATE_PRICE,
      sdk.pile.price.updatePrice
    ),
  },
};

export const weather = {
  getWeather: createApiMaker(types.GET_WEATHER, sdk.weather.weather.getWeather),
};

/**
 * lib 目录下主要方通用的函数
 */

import { get, keyBy, uniq, isNil, sortBy, max } from "lodash";
import moment from "moment";
import Nzh from "nzh";

import { ROOT_NS } from "../config";
import { ChargeStatus, VehicleState } from "../constants";
import { RolePrivilege } from "../config/privileges";

/**
 * A delay promise
 * @param {Number} ms delay miliseconds
 */
export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * add rounding function to Number class
 * @param {Number} number 需要定义精度的数
 * @param {Number} precision 精度，例如 0.1
 */
export function round(number, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(number * multiplier) / multiplier;
}

/**
 * Format time
 * @param {Date} date
 */
const formatDate = (date, format) =>
  date ? moment(date).format(format) : "--";
export const ymdhms = date => formatDate(date, "YYYY-MM-DD HH:mm:ss");
export const ymdhm = date => formatDate(date, "YYYY-MM-DD HH:mm");
export const ymd = date => formatDate(date, "YYYY-MM-DD");
export const mdhm = date => formatDate(date, "MM-DD HH:mm");

/**
 * 数组变成 tree，保序
 *
 * nodes 中的每一个元素 数据结构
 *   key: 当前key
 *   parent: 父亲的key
 *   title: name
 *   value: value
 *   data: 原始数据
 *   type: 类别
 */
export function listToTree(nodes = []) {
  const roots = [];
  const M = keyBy(nodes, "key");
  nodes.forEach(node => {
    const parent = M[node.parent];
    if (parent) {
      parent.children = parent.children || [];
      parent.children.push(node);
    } else {
      roots.push(node);
    }
  });
  return roots;
}

export const getNsName = (ns = {}) => {
  if (!ns.name || ns.id === ROOT_NS) return "";
  return getNsName(ns.parent) + ns.name;
};

export const getNsPath = (ns = {}) => {
  if (!ns.name || ns.id === ROOT_NS) return [];

  return [...getNsPath(ns.parent), ns.name];
};

export const getNsCompany = (ns = {}) => {
  if (!ns.parent || ns.parent.id === ROOT_NS) return ns.name;
  return getNsCompany(ns.parent);
};

// 通过ticket获取所有event的stage
export function getStageByTicket(ticket) {
  let result = [];
  const events = ticket.events;

  for (let i = 0; i < events.length; i++) {
    let data = {
      action: events[i].action,
      updatedAt: events[i].updatedAt,
      createdBy: get(events[i], "createdBy.name"),
      content: events[i].content,
    };
    if (i < events.length - 1) {
      data.stage = events[i + 1].from.name;
    } else {
      data.stage = ticket.stage.name;
    }
    result.push(data);
  }
  return result;
}

const StageOrder = {
  待维修: 0,
  维修中: 1,
  已完成: 2,
};
export function stageCompare(s1, s2) {
  return StageOrder[s1.name] - StageOrder[s2.name];
}
export function getStageByName(stages = [], name) {
  const stage = stages.find(s => s.name === name) || {};
  return stage.id;
}

// 获取本年、本月、本周、本日
export function getDates(unit) {
  const method = unit === "day" ? "subtract" : "add";
  const value = unit === "day" ? -1 : 0;
  const start = moment()
    [method](Math.abs(value), `${unit}s`)
    .startOf(unit)
    .startOf("day")
    .format("YYYY-MM-DD");
  const end = moment()
    [method](Math.abs(value), `${unit}s`)
    .endOf(unit)
    .endOf("day")
    .format("YYYY-MM-DD");
  return [start, end];
}
/**
 * 计算车辆运营状态
 * @param {*} vehicle
 */
export const calcVehicleRunState = (vehicle = {}) => {
  const chargeStatus = get(vehicle, "overall.chargeStatus");
  const reportedAt = get(vehicle, "reportedAt");
  const tenMinAgo = moment().subtract(10, "minute");

  if (moment(reportedAt).isBefore(tenMinAgo)) return VehicleState.STOP;
  if (
    chargeStatus &&
    [ChargeStatus.CHARGED, ChargeStatus.PARK_CHARGING].includes(chargeStatus)
  )
    return VehicleState.CHARGING;
  return VehicleState.RUNNING;
};

export const destoryObj = obj => {
  if (obj) {
    obj.destroy();
    obj = null;
  }
};

export const withCentigrade = val => val + "℃";
export const withOhm = val => val + "KΩ";
export const withKpa = val => val + "kPa";
export const withKwh = val => val + "kW∙h";
export const withKw = val => val + "kW";
export const withHour = val => val + "h";
export const withKm = val => val + "km";
export const withKmh = val => val + "km/h";
export const withYear = val => val + "年";
export const withAirMode = val => (val === "OFF" ? "关" : "开");
export const withSecond = val => val + "s";
export const withVoltage = val => val + "V";

export const delYear = val =>
  typeof val === "string" ? val.replace("年", "").trim() : "--";

/**
 * 根据传入的一组 角色 获取 privileges
 * @param {*} roles 角色
 */
export function getPrivilegesByRoles(roles = []) {
  const privileges = [].concat(...roles.map(r => RolePrivilege[r] || []));
  return uniq(privileges);
}

export const withPercent = (val, precision = 0) =>
  !isNil(val) ? `${(Number(val) * 100).toFixed(precision)}%` : "--";
export const withUnit = unit => val =>
  val !== undefined ? `${val}${unit}` : "--";

export const sliceVal = val => val.slice(-8);

export const formatValue = (record, key, render) => {
  const data = get(record, key);
  if (!isNil(data) && data !== "--") {
    if (render) {
      if (typeof render === "function") return render(data);
      return render[data];
    }
    return data;
  }
  return "--";
};

export const formatNil = (record, key) => {
  const data = get(record, key);
  if (!isNil(data)) return data;
  return "--";
};

// 千分位数字
export function toThousands(num) {
  return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
}

/**
 * 排序相关
 */
const nzh = Nzh.cn;

/**
 * 试图从字符串中解析数字，包括中文数字，阿拉伯数字
 * @param {string} str
 */
function tryFindNumber(str) {
  // 试图找出阿拉伯数字
  let tryNumber = parseInt(str.replace(/[^0-9]/gi, ""), 10);
  // 没有
  if (isNaN(tryNumber)) {
    tryNumber = nzh.decodeS(str) || nzh.decodeB(str);
  }
  // 没有找到数字，则返回最大整数
  return tryNumber || Number.MAX_SAFE_INTEGER;
}

/**
 * 按照数组中的中文顺序排序
 * @param {[object]} collection
 * @param {string} attribute 集合项的属性
 */
export function sortNzhBy(collection, attribute) {
  return sortBy(collection, c => {
    return tryFindNumber(c[attribute]);
  });
}

/**
 * 按照数组中的中文排序
 * @param {[string]} array
 */
export function sortNzh(array) {
  return sortBy(array, a => {
    return tryFindNumber(a);
  });
}

export function getPileStatusCount(stats = [], status) {
  const found = stats.find(s => s.status === status);
  if (!found) return 0;
  return found.count;
}

export function getPileStatusUpdateAt(stats = []) {
  return max(stats.map(s => s.updatedAt));
}

export function getAbsValue(data) {
  // 获取绝对值
  if (!isNil(data)) {
    if (data >= 0) return data;
    return -1 * data;
  }
  return 0;
}

export function getFormatDate(d, t) {
  if (d && t) {
    const dateString = `${d.format("YYYY-MM-DD")} ${t.format("HH:mm:ss")}`;
    return moment(dateString, "YYYY-MM-DD HH:mm:ss");
  }
  return "";
}

export const getPilePriceDesc = value =>
  value.map(d => `${d[0]}:00-${d[0]}:00`).join("，");

// 判断输入的时间是否在时间段内
export const isInPeriod = (hour, period) => {
  let isIn = false;
  period.forEach(v => {
    let start = v[0];
    let end = start > v[1] ? v[1] + 24 : v[1];
    if (hour >= start && hour <= end) return true;
  });
  return isIn;
};

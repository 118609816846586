import { schema } from "normalizr";

export const namespacesSchema = new schema.Entity("namespaces");
namespacesSchema.define({ parent: namespacesSchema });
export const lineSchema = new schema.Entity("lines", {
  ns: namespacesSchema,
});
export const vehicleSchema = new schema.Entity("vehicles", {
  ns: namespacesSchema,
  line: lineSchema,
});
export const userSchema = new schema.Entity("users", {
  ns: [namespacesSchema],
});
export const alertSchema = new schema.Entity("alerts", {
  actionBy: userSchema,
  ns: namespacesSchema,
  vehicleLine: lineSchema,
});
export const producerSchema = new schema.Entity(
  "producers",
  {},
  {
    idAttribute: "name",
  }
);
export const parkSchema = new schema.Entity(
  "parks",
  {},
  { idAttribute: "name" }
);
export const ticketStageSchema = new schema.Entity("ticketStages");
export const ticketEventSchema = new schema.Entity("ticketEvents", {
  createdBy: userSchema,
  from: ticketStageSchema,
  to: ticketStageSchema,
});
export const ticketSchema = new schema.Entity("tickets", {
  updatedBy: userSchema,
  events: [ticketEventSchema],
  stage: ticketStageSchema,
  ns: namespacesSchema,
});

export const banciSchema = new schema.Entity("bancis", {
  vehicle: vehicleSchema,
  line: lineSchema,
  ns: namespacesSchema,
});

export const recordSchema = new schema.Entity("records", {});
export const mileageSchema = new schema.Entity(
  "mileages",
  {},
  {
    idAttribute: "at",
  }
);

export const warningSchema = new schema.Entity("warnings", {
  line: lineSchema,
  ns: namespacesSchema,
});
export const powerSchema = new schema.Entity("powers", {
  line: lineSchema,
  ns: namespacesSchema,
});

export const powerAggSchema = new schema.Entity("powerAggs", {
  line: lineSchema,
  ns: namespacesSchema,
});

export const driverPowerSchema = new schema.Entity("driverPowers", {
  line: lineSchema,
  ns: namespacesSchema,
});

export const driverPowerAggSchema = new schema.Entity("driverPowerAggs", {
  ns: [namespacesSchema],
});

export const sohSchema = new schema.Entity("soh");
export const exceptionSchema = new schema.Entity("exceptions", {
  ns: namespacesSchema,
  vehicleLine: lineSchema,
});

export const stationSchema = new schema.Entity("stations");
export const pileSchema = new schema.Entity("piles", {
  station: stationSchema,
});
export const chargingOrderSchema = new schema.Entity("chargingOrders", {
  station: stationSchema,
  pile: pileSchema,
  vehicle: vehicleSchema,
  vehicleLine: lineSchema,
});

export const optionalVehicleSchema = new schema.Entity("optionalVehicle", {
  ns: namespacesSchema,
  optionalNs: namespacesSchema,
  line: lineSchema,
  optionalLine: lineSchema,
});

export const sgccSchema = new schema.Entity("sgccs");

export const QSohSchema = new schema.Entity("qsohs", {
  ns: namespacesSchema,
  line: lineSchema,
});

/**
 * 单个充电桩的充电历史
 */
import React, { PureComponent, Fragment } from "react";
import { createSelector } from "reselect";
import { get, values } from "lodash";
import { Table } from "antd";
import { put } from "redux-saga/effects";
import moment from "moment";
import { connect } from "react-redux";

import { withSaga, tapOnLatest, makeApiSelector } from "@36node/redux";
import { pile, core } from "../../actions/api";
import { withTable } from "../../components/withTable";
import { PILE_LIST_CHARGING_ORDERS } from "../../actions/types";
import { PileTypeI18N } from "../../constants";
import { ymdhms, formatValue, getNsName, withKwh } from "../../lib";
import styled from "styled-components";

/**
 * constants
 */
const pageKey = "chargingOrders.all";

const defaultStartAt_gt = moment()
  .subtract(1, "week")
  .startOf("day");
const defaultStartAt_lt = moment();

const columns = [
  {
    title: "订单编号",
    dataIndex: "id",
    key: "id",
    hidden: true,
  },
  {
    title: "VIN码",
    dataIndex: "vehicle.id",
    key: "vehicle",
  },
  {
    title: "自编号",
    dataIndex: "vehicleNo",
    key: "vehicleNo",
  },
  {
    title: "部门",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "线路",
    dataIndex: "vehicleLine.name",
    key: "line",
  },
  {
    title: "充电桩编号",
    dataIndex: "pile.id",
    key: "pile.id",
  },
  {
    title: "充电站",
    dataIndex: "station.name",
    key: "station",
    hidden: true,
  },
  {
    title: "充电桩型号",
    dataIndex: "pile.equipmentModel",
    key: "pile.equipmentModel",
    hidden: true,
  },
  {
    title: "充电桩类型",
    dataIndex: "pile.type",
    key: "type",
    hidden: true,
  },
  {
    title: "充电桩厂家",
    dataIndex: "pile.manufacturerName",
    key: "manufacturerName",
    hidden: true,
  },
  {
    title: "充电开始时间",
    dataIndex: "startAt",
    key: "startAt",
    sorter: true,
  },
  {
    title: "充电结束时间",
    dataIndex: "endAt",
    key: "endAt",
    sorter: true,
    defaultSortOrder: "descend",
  },
  {
    title: "充电时长",
    dataIndex: "duration",
    key: "duration",
    sorter: true,
  },
  {
    title: "充电电量",
    dataIndex: "power",
    key: "power",
    sorter: true,
  },
];

/**
 * actions
 */

const listChargingOrders = pile.chargingOrder.makeListChargingOrder(pageKey, {
  query: {
    populate: ["pile", "station"],
    sort: "-endAt",
    filter: {
      startAt: {
        $gte: defaultStartAt_gt.toISOString(),
        $lte: defaultStartAt_lt.toISOString(),
      },
    },
  },
});
const listVehicles = core.vehicle.makeListVehicles("chargingOrders.vehicles");
const listStations = pile.station.makeListStation("chargingOrders.stations");
const getPile = pile.pile.makeGetPile("chargingOrders.getPile");

/**
 * selectors
 */
const getPileSelector = makeApiSelector("chargingOrders.getPile");
const makeListChargingOrders = selector =>
  createSelector(
    selector,
    listState => {
      const result = (listState.result || []).map(r => {
        const pile = r.pile || {};
        const vehicle = r.vehicle || {};
        const { startAt, endAt } = r;

        return {
          ...r,
          startAt: ymdhms(r.startAt),
          endAt: ymdhms(r.endAt),
          power: withKwh(r.power),
          department: formatValue(vehicle, "ns", getNsName),
          pile: {
            ...pile,
            type: PileTypeI18N[pile.type],
          },
          duration: moment.utc(moment(endAt).diff(startAt)).format("HH:mm:ss"),
        };
      });

      return {
        ...listState,
        result,
      };
    }
  );

/**
 * styles
 */
const InfoArea = styled.div`
  padding: 24px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

/**
 * components
 */

@withTable(pageKey, {
  title: "充电桩记录详情",
  columns,
  list: listChargingOrders,
  makeListSelector: makeListChargingOrders,
  fetchOnMount: false,
})
class OrdersTable extends PureComponent {
  componentDidMount() {
    this.props.dispatch(
      listChargingOrders({ query: { filter: { pile: this.pileId } } })
    );
  }
  get pileId() {
    return this.props.pileId;
  }
  render() {
    return <Table {...this.props} />;
  }
}

@withSaga(
  tapOnLatest(PILE_LIST_CHARGING_ORDERS.SUCCESS, pageKey, function*(action) {
    const orders = values(get(action, "payload.entities.chargingOrders", {}));

    const vehicleIds = orders.map(o => o.vehicle);

    yield put(listVehicles({ query: { filter: { id: vehicleIds } } }));
    yield put(listStations({ query: { limit: 10000 } }));
  })
)
@connect(state => {
  const pile = getPileSelector(state).result || {};

  return {
    pile,
  };
})
export default class History extends PureComponent {
  get pileId() {
    return this.props.match.params.pileId;
  }

  get pile() {
    return this.props.pile;
  }

  componentDidMount() {
    this.props.dispatch(getPile({ pileId: this.pileId }));
  }

  render() {
    return (
      <Fragment>
        <InfoArea>
          <div>充电桩编号：{this.pile.id || "--"}</div>
          <div>充电桩名称：{this.pile.name || "--"}</div>
          <div>所在充电所：{get(this.pile, "station.name", "--")}</div>
        </InfoArea>
        <OrdersTable pileId={this.pileId} />
      </Fragment>
    );
  }
}
